import { Loader, Plus } from '@/app/icons';
import animalAvatar from '@/app/icons/svg/animalAvatar.svg';
import { LINK_RACY } from '@/app/navigation';
import { theme } from '@/app/theme';
import { TAnimalDto } from '@/entities/animal-controller';
import { ANIMAL_SEX, ANIMAL_STATUS, useGetSpeciesSexQuery } from '@/entities/species-controller';
import { Typography, Upload } from '@/shared/components';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { RcFile } from 'antd/es/upload';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { NurseryAnimalCard } from '@/features/nursery';
import { EmptySection } from './EmptySection';

const List = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  .custom-image,
  .add-btn {
    width: calc((100% - 24px) / 3);
  }
`;

const Add = styled.div`
  .ant-upload {
    width: 100%;
    height: 100%;
    display: block;
  }

  cursor: pointer;
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.palette.neutral[5]};
  position: relative;
  aspect-ratio: 1/1;

  .add-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary[1]};

    svg {
      path {
        stroke: ${({ theme }) => theme.palette.primary[1]};
      }
    }
  }
`;

const PetCardsList = ({
  data,
  handleOpenModal,
  canEdit,
  onUploadHandler,
  addIcon,
  isLoading,
}: {
  data: TAnimalDto[];
  handleOpenModal: () => void;
  canEdit: boolean;
  onUploadHandler?: (file: RcFile[]) => void;
  addIcon?: ReactNode;
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();

  const onAddCallback = canEdit ? handleOpenModal : undefined;

  if (!data.length) {
    return <EmptySection onAddButtonClick={onAddCallback} />;
  }

  return (
    <List>
      {data.map(({ images, id, fullName, nursery }) => (
        <NurseryAnimalCard
          key={id}
          image={{
            src: images.length ? images[0]?.url : animalAvatar,
            onClick: () => navigate(`${LINK_RACY}/${id}`),
            bottomInfo: { title: fullName, subTitle: nursery.nameEn },
          }}
        />
      ))}
      {(!!onAddCallback || !!onUploadHandler) && (
        <Add className="add-btn" onClick={onAddCallback}>
          <Upload multiple openFileDialogOnClick={!!onUploadHandler} onUploadHandler={onUploadHandler}>
            <div className="add-content">
              {isLoading ? <Loader /> : addIcon || <Plus color={theme.palette.neutral[5]} width={48} height={48} />}
            </div>
          </Upload>
        </Add>
      )}
    </List>
  );
};

export const PetsListSection = ({
  data,
  onOpenModal,
  speciesID,
  canEdit,
}: {
  data: TAnimalDto[];
  onOpenModal: ({ animalSex, status }: { animalSex: ANIMAL_SEX; status: ANIMAL_STATUS }) => void;
  speciesID: number;
  canEdit: boolean;
}) => {
  const { data: sections = [], isFetching } = useGetSpeciesSexQuery(queries.equal('species.id', speciesID));

  const { neuter, tribalMale, tribalFemale } = useMemo(
    () =>
      data.reduce(
        (acc: { neuter: TAnimalDto[]; tribalFemale: TAnimalDto[]; tribalMale: TAnimalDto[] }, item) => {
          if (item.speciesSex.status.name === ANIMAL_STATUS.NEUTER) {
            acc.neuter.push(item);
          } else {
            item.speciesSex.sex === ANIMAL_SEX.FEMALE ? acc.tribalFemale.push(item) : acc.tribalMale.push(item);
          }
          return acc;
        },
        { neuter: [], tribalFemale: [], tribalMale: [] },
      ),
    [data],
  );

  const animalData = (status: ANIMAL_STATUS, sex: ANIMAL_SEX) =>
    status === ANIMAL_STATUS.NEUTER ? neuter : sex === ANIMAL_SEX.FEMALE ? tribalFemale : tribalMale;

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Flex vertical gap={32}>
      {sections.map(({ id, status: { name }, sex: animalSex, category }) => (
        <Flex vertical gap={16} key={id}>
          <Typography type="18B">{category}</Typography>
          <PetCardsList
            canEdit={canEdit}
            data={animalData(name, animalSex)}
            handleOpenModal={() => onOpenModal({ animalSex, status: name })}
          />
        </Flex>
      ))}
    </Flex>
  );
};
