import { commonFont } from './commonFont';

export default {
  '12SB': {
    ...commonFont,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
  },
  '14SB': {
    ...commonFont,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  '18SB': {
    ...commonFont,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '34px',
  },
};
