import { Button } from '@/shared/components';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';

const FORGOT_PASSWORD_TEXT = 'Забыли пароль?';

export const ForgetPasswordButton = () => {
  const dispatch = useTypedDispatch();

  return (
    <Button type="link" onClick={() => dispatch(setGlobalModal(MODALS.PASSWORD_RESET))}>
      {FORGOT_PASSWORD_TEXT}
    </Button>
  );
};
