import { NotificationPopup, Typography } from '@/shared/components';
import { CONGRATS } from '@/features/modals/Notification/constants';

const TITLE = 'Добавить медиа файл';
const TEXT = 'Ваши медиа файлы успешно опубликованы.';

type TProps = {
  onClose: () => void;
};

export const PostCreateModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Typography type="14SB">{CONGRATS}</Typography>
    <p>{TEXT}</p>
  </NotificationPopup>
);
