import { SPECIES } from '@/entities/species-controller';
import { TColorTypeDto, useGetColorsQuery, useGetColorsTypesQuery } from '@/entities/color-controller';
import { queries } from '@/shared/utils';
import { Body2, Button, Input, LabelWrapper, Radio, RadioGroup, Select } from '@/shared/ui';
import { Collapse, CollapseProps, Flex, RadioChangeEvent } from 'antd';

import { useModal } from '@/shared/hooks';
import { useFormContext } from 'react-hook-form';
import { useColorsSchema } from '@/features/animal/species-colors';
import { z } from 'zod';

import styles from './styles.module.scss';

const useGetColors = (id: number) => {
  const query = queries.equal('colorType.id', id);
  return useGetColorsQuery(query);
};

type TSelectedValue = { value: number; code: string; name: string };

const ColorsRadioGroup = ({
  id,
  setValue,
  selectedValue,
}: {
  id: number;
  setValue: (field: string, value: TSelectedValue) => void;
  selectedValue?: TSelectedValue;
}) => {
  const { data: colors = [] } = useGetColors(id);
  const onChangeHandler = (e: RadioChangeEvent) => {
    const item = colors.find(({ id }) => id === e.target.value);
    if (!item) return;
    const { id: itemId, code, name } = item;
    setValue(String(id), { value: itemId, code, name });
  };

  return (
    <RadioGroup
      controllerProps={{ name: String(id) }}
      value={selectedValue?.value}
      onChange={onChangeHandler}
      vertical
      options={colors}
    />
  );
};

export const SpeciesColors = ({ speciesId }: { speciesId: SPECIES }) => {
  const speciesQuery = queries.equal('species.id', speciesId);
  const { data: types = [] } = useGetColorsTypesQuery(speciesQuery);
  const schema = useColorsSchema(SPECIES.CATS);
  const { setValue, watch } = useFormContext<z.infer<typeof schema>>();
  const { isOpen, close, open } = useModal();

  const { data = [], isLoading } = useGetColors(types[0]?.id);

  if (types.length === 1) {
    return <Select controllerProps={{ name: String(types[0].id) }} labelProps={{ label: 'Окрас' }} options={data} />;
  }

  const getSelectedValue = (typeId: number): TSelectedValue => watch(String(typeId)) as TSelectedValue;

  const onChangeHandler = (field: string, value: TSelectedValue) => {
    setValue(field, value);
  };

  const onClearField = (field: string) => setValue(field, undefined);

  const getCodes = (types: TColorTypeDto[]): string =>
    types
      .reduce((arr: string[], { id }) => {
        const item = getSelectedValue(id);
        if (item) {
          arr.push(item.code);
        }

        return arr;
      }, [])
      .join(', ');

  const items: CollapseProps['items'] = types.slice(1).map(({ name, id }) => ({
    label: (
      <Flex vertical gap={8}>
        <Body2 className="font-semibold header">{name}</Body2>
        {getSelectedValue(id) && (
          <Flex className="selected" onClick={(e) => e.stopPropagation()}>
            <Radio onClick={() => onClearField(String(id))} checked>
              {getSelectedValue(id).name}
            </Radio>
          </Flex>
        )}
      </Flex>
    ),
    showArrow: false,
    forceRender: true,
    children: <ColorsRadioGroup id={id} setValue={onChangeHandler} selectedValue={getSelectedValue(id)} />,
  }));

  return (
    <LabelWrapper label="Окрас">
      <Flex vertical gap={24} className="w-full">
        <Flex gap={16} justify="space-between" className={styles['selector-block']}>
          <Select controllerProps={{ name: String(types[0]?.id) }} options={data} loading={isLoading} />
          <Input controllerProps={{ name: '' }} disabled value={getCodes(types.slice(1))} />
        </Flex>
        {!isOpen && (
          <Flex vertical gap={16} className="ml-lg">
            {types.slice(1).map(({ id }) =>
              getSelectedValue(id) ? (
                <Radio key={id} onClick={() => onClearField(String(id))} checked>
                  {getSelectedValue(id).name}
                </Radio>
              ) : null,
            )}
          </Flex>
        )}
        {isOpen && (
          <Flex vertical className="p-xxs rounded-xxs border border-neutral6">
            <Collapse className={styles['custom-collapse']} items={items} ghost />
          </Flex>
        )}
        <Button type={isOpen ? 'default' : 'primary'} onClick={isOpen ? close : open} className="w-full">
          {isOpen ? 'Выбрать' : 'Дополнить'}
        </Button>
      </Flex>
    </LabelWrapper>
  );
};
