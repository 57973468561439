import { Flex, InputRef } from 'antd';
import { ClearIcon, Loader, SearchIcon } from '@/app/icons';
import { useDebounce, useModal, useOutsideClick } from '@/shared/hooks';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { TCommonPageResponse } from '@/entities';
import styled from 'styled-components';
import { Caption2, Input } from '@/shared/components';
import { theme } from '@/app/theme';
import { useLocation } from 'react-router';

const DropDown = styled(Flex)`
  max-height: 50vh;
  overflow-y: auto;
`;

const DropDownOption = styled.div`
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral[7]};
  }
`;

const SearchWrapper = styled(Flex)<{ $isOpen: boolean }>`
  position: relative;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.palette.neutral[9]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.neutral[6]};

  border-color: ${(props) => props.$isOpen && theme.palette.primary[1]};

  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-outlined:focus-within {
    padding: 0;
    border: none;
    box-shadow: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const FilterItem = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${(props) => props.$isActive && theme.palette.neutral[7]};
`;

const ContentWrapper = styled(Flex)`
  position: absolute;
  right: -1px;
  left: -1px;
  z-index: 16;
  padding: 16px 12px;
  top: 40px;
  background-color: ${({ theme }) => theme.palette.neutral[9]};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary[1]};
  border-top: unset;
`;

type TProps<R, T, K> = {
  fetchData: (args: { search: string; page: number; filter?: K | null }) => Promise<R>;
  optionRender: (option: T) => JSX.Element | null;
  filters: { label: string; key: K }[];
  activeKey?: K | null;
  onChangeFilter?: (key: K | null) => void;
  loading: boolean;
  onSelect: (value: T) => void;
};

export const Search = <
  K extends string,
  T extends { id: string; text: string },
  R extends TCommonPageResponse & { content: T[] },
>({
  fetchData,
  optionRender,
  filters,
  activeKey = null,
  onChangeFilter,
  loading,
  onSelect,
}: TProps<R, T, K>) => {
  const [options, setOptions] = useState<T[]>([]);
  const [active, setActive] = useState<K | null>(activeKey);
  const [value, setValue] = useState<string>('');
  const { pathname } = useLocation();

  const { isOpen: isSearchOpen, open: openSearch, close: closeSearch } = useModal();

  const searchRef = useOutsideClick(() => {
    closeSearch();
    setActive(null);
  });
  const inputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    setValue('');
    setActive(null);
  }, [pathname]);

  const searchCallback = useCallback(
    async ({ search = value, page = 1, filter = active }: { search?: string; page?: number; filter?: K | null }) => {
      try {
        if (!search) {
          return;
        }
        const { content } = await fetchData({ search, page, filter });
        setOptions(content);
      } catch (e) {
        console.error(e);
      }
    },
    [value, active, fetchData],
  );

  const handleSearch = useDebounce((search: string) => searchCallback({ search }), 500);

  const handleFilterChange = (key: K | null) => {
    setActive(key);
    searchCallback({ filter: key });
    onChangeFilter && onChangeFilter(key);
  };

  const handleFocusSearch = () => {
    openSearch();
    inputRef.current && inputRef.current.focus();
  };

  const onSelectHandler = (option: T) => {
    closeSearch();
    onSelect(option);
  };

  useEffect(() => {
    if (value) {
      handleSearch(value);
    }
    if (!value) {
      setActive(active);
      setOptions([]);
    }
  }, [value, active]);

  return (
    <SearchWrapper
      className="search-component"
      vertical
      ref={searchRef}
      gap={12}
      $isOpen={isSearchOpen}
      onClick={handleFocusSearch}
    >
      <Input
        ref={inputRef}
        value={value}
        autoFocus={isSearchOpen}
        allowClear={{
          clearIcon: loading ? <Loader /> : <ClearIcon />,
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        suffix={!value && <SearchIcon />}
        placeholder="Что будем искать?"
      />
      {isSearchOpen && (
        <ContentWrapper vertical gap={16}>
          {filters && (
            <Flex justify="space-between">
              {filters.map(({ label, key }) => (
                <FilterItem $isActive={key === active} key={key} onClick={() => handleFilterChange(key)}>
                  <Caption2 weight="SB">{label}</Caption2>
                </FilterItem>
              ))}
            </Flex>
          )}
          {!!options.length && (
            <DropDown vertical>
              {options.map((option) => (
                <DropDownOption
                  onClick={(event: MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                    onSelectHandler(option);
                  }}
                  key={option.id}
                >
                  {optionRender(option)}
                </DropDownOption>
              ))}
            </DropDown>
          )}
        </ContentWrapper>
      )}
    </SearchWrapper>
  );
};
