import { Button, TButtonProps } from '@/shared/components';
import { usePostFollowAccountMutation, usePutUnfollowAccountMutation } from '@/entities/user-controller';
import { SubscribeIcon } from '@/app/icons';
import { useAuth } from '@/shared/hooks/useAuth';

type TProps = {
  accountId: string;
  subscribed: boolean;
  btnProps?: Omit<TButtonProps, 'children'>;
};

export const SubscribeButton = ({ accountId, subscribed, btnProps }: TProps) => {
  const [follow, { isLoading: isFollowing }] = usePostFollowAccountMutation();
  const [unFollow, { isLoading: isUnfollowing }] = usePutUnfollowAccountMutation();

  const { handleAuth } = useAuth();

  const onFollowHandler = async () => {
    try {
      subscribed ? await unFollow(accountId).unwrap() : await follow(accountId).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      loading={isFollowing || isUnfollowing}
      icon={<SubscribeIcon />}
      {...btnProps}
      onClick={() => handleAuth(onFollowHandler)}
    >
      {subscribed ? 'Вы подписаны' : 'Подписаться'}
    </Button>
  );
};
