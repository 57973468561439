import { HOME_PATHS } from '@/app/navigation';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { TLoginRequest, useEmailSignInMutation, useLazyCheckEmailQuery } from '@/entities/auth-controller';
import { Button, Input, InputPassword } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { useTokens } from '@/shared/hooks';
import { EMAIL_SIGN_IN_VALIDATION_MESSAGE, emailRule, passwordRule } from '@/shared/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const SIGN_IN = 'Войти';
const EMAIL = 'EMAIL';
const PASSWORD = 'Пароль';
const INVALID_EMAIL_OR_PASSWORD = 'Введен неверный логин или пароль. Пожалуйста, попробуйте еще раз.';

const FORM_FIELDS = {
  email: 'email',
  password: 'password',
} as const;

const validation = z.object({
  [FORM_FIELDS.email]: emailRule,
  [FORM_FIELDS.password]: passwordRule,
});

type TProps = {
  showBtn?: boolean;
};

export const EmailSignIn = forwardRef(({ showBtn = true }: TProps, ref) => {
  const formMethods = useForm<TLoginRequest>({
    resolver: zodResolver(validation),
  });
  const { updateTokens } = useTokens();
  const { handleSubmit, setError, clearErrors } = formMethods;
  const navigate = useNavigate();

  const formRef = useRef<HTMLFormElement>(null);

  const [signIn, { isLoading }] = useEmailSignInMutation();
  const [checkEmail] = useLazyCheckEmailQuery();

  const dispatch = useTypedDispatch();

  const openRegistrationModal = () => {
    dispatch(setGlobalModal(MODALS.REGISTRATION));
  };

  const onCheckEmail = async (email: string) => {
    try {
      const isEmailExist = await checkEmail(email).unwrap();
      if (!isEmailExist) {
        setError(FORM_FIELDS.email, { message: EMAIL_SIGN_IN_VALIDATION_MESSAGE });
        openRegistrationModal();
      } else {
        clearErrors(FORM_FIELDS.email);
      }
      return isEmailExist;
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (data: TLoginRequest) => {
    try {
      const isEmailExist = await onCheckEmail(data.email);
      if (!isEmailExist) {
        return;
      }
      const { token: accessToken, refreshToken } = await signIn(data).unwrap();
      updateTokens(accessToken, refreshToken);
      navigate(HOME_PATHS.home);
    } catch (e) {
      setError(FORM_FIELDS.password, { message: INVALID_EMAIL_OR_PASSWORD });
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSubmit)(),
  }));

  return (
    <FormProvider {...formMethods}>
      <ColumnWrapper>
        <form ref={formRef} style={{ display: 'contents' }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Input {...field} errorMsg={error?.message} labelProps={{ label: EMAIL }} />
            )}
            name={FORM_FIELDS.email}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <InputPassword {...field} errorMsg={error?.message} showTips={false} labelProps={{ label: PASSWORD }} />
            )}
            name={FORM_FIELDS.password}
          />
          {showBtn && (
            <Button size="large" htmlType="submit" loading={isLoading} fullWidth>
              {SIGN_IN}
            </Button>
          )}
        </form>
      </ColumnWrapper>
    </FormProvider>
  );
});
