import { NotificationPopup } from '@/shared/components';

const TITLE = 'Пароль обновлен';
const TEXT = 'Ваш пароль успешно обновлен. Теперь Вы можете использовать новый пароль для входа.';

type TProps = {
  onClose: () => void;
};

export const PasswordResetModal = ({ onClose }: TProps) => (
  <NotificationPopup title={TITLE} open onCancel={onClose}>
    {TEXT}
  </NotificationPopup>
);
