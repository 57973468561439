import { useGetUserAccountsQuery } from '@/entities/user-controller';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';
import { Body2, Headline4, Image } from '@/shared/components';
import { theme } from '@/app/theme';
import styled, { css } from 'styled-components';
import { useHistory } from '@/shared/hooks';
import { ACCOUNT_LINKS, ACCOUNT_TABS } from '@/app/navigation';

const TITLE = 'Аккаунты пользователя';

type TProps = {
  userId: string;
  accountId: string;
};

const Account = styled(Flex)<{ $active: boolean }>`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral[3]};
  padding: 12px;
  cursor: pointer;

  ${({ $active }) =>
    $active &&
    css`
      cursor: unset;
      background-color: ${({ theme }) => theme.palette.common[1]};
    `}
`;

export const AccountsList = ({ userId, accountId }: TProps) => {
  const { data = [] } = useGetUserAccountsQuery(userId);

  const navigate = useHistory();

  if (!data) {
    return <Loader />;
  }

  const handleSwitchAccount = async (accountId: string) => {
    navigate(`${ACCOUNT_LINKS.account}/${accountId}/${ACCOUNT_TABS.posts}`);
  };

  return (
    <Flex vertical gap={16}>
      <Headline4 styles={{ color: theme.palette.neutral[7] }}>{TITLE}</Headline4>
      <Flex vertical gap={8}>
        {data.map(({ avatar, name, id }) => (
          <Account key={id} gap={8} $active={accountId === id} onClick={() => handleSwitchAccount(id)}>
            <Image scale={false} rounded width={24} src={avatar?.url} />
            <Body2 weight="SB" styles={{ color: theme.palette.neutral[9] }}>
              {name}
            </Body2>
          </Account>
        ))}
      </Flex>
    </Flex>
  );
};
