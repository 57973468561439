import { commonFont } from './commonFont';

export default {
  button1: {
    ...commonFont,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
  },
  button2: {
    ...commonFont,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '16px',
  },
  button3: {
    ...commonFont,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '16px',
  },
};
