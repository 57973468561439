module.exports = {
  menu: 'rgba(251, 252, 254, 0.1)',
  'menu-text': 'rgba(255, 255, 255, 1)',
  primary1: '#3772FF',
  primary2: '#7F5FFF',
  primary3: '#EF466F',
  primary4: '#4CDB35',
  secondary1: '#4BC9F0',
  secondary2: '#E4D7CF',
  secondary3: '#FFD166',
  secondary4: '#CDB4DB',
  neutral1: '#1A191F',
  neutral2: '#212029',
  neutral3: '#333240',
  neutral4: '#8A91A9',
  neutral5: '#ACB2C5',
  neutral6: '#E5E7EF',
  neutral7: '#F4F5F9',
  neutral8: '#FBFCFE',
  neutral9: '#FFFFFF',
  neutral10: '#777E90',
};
