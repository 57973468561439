import { useGetSpeciesSexStatusQuery } from '@/entities/species-controller';
import { Select, TSelectProps } from '@/shared/ui';
import { FieldValues } from 'react-hook-form';

export const ANIMAL_STATUS_FORM_FIELD = 'status';

export const AnimalStatusSelect = <R extends FieldValues>({ ...props }: TSelectProps<R>) => {
  const { data: sexStatusOptions, isFetching: isStatusFetching } = useGetSpeciesSexStatusQuery();

  return <Select labelProps={{ label: 'Статус' }} options={sexStatusOptions} loading={isStatusFetching} {...props} />;
};
