import { Radio as AtnRadio, RadioGroupProps } from 'antd';
import { Radio } from '@/shared/components';

type TProps = Omit<RadioGroupProps, 'options'> & {
  options: { value: string | number | boolean; label: string }[];
};

export const RadioGroup = ({ options, ...props }: TProps) => (
  <AtnRadio.Group {...props}>
    {options.map(({ value, label }) => (
      <Radio key={label} value={value}>
        {label}
      </Radio>
    ))}
  </AtnRadio.Group>
);
