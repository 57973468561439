import { Button as AntButton, ButtonProps } from 'antd';

import './styles.scss';
import cn from 'classnames';

export type TButtonProps = Omit<ButtonProps, 'size'> & {
  size?: ButtonProps['size'] | 'x-small' | 'medium' | 'unset';
};

export const Button = ({ className, type = 'primary', size = 'small', ...props }: TButtonProps) => {
  const isSmall = size === 'small';
  const isXSmall = size === 'x-small';
  const isMedium = size === 'medium';

  return (
    <AntButton
      className={cn(
        'custom-button w-fit h-auto',
        { 'px-xs py-mxs': isSmall },
        { 'px-mxs py-xxs': isXSmall },
        { 'py-xs text-button1': isMedium },
        className,
      )}
      type={type}
      {...props}
    />
  );
};
