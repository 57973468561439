import { Tabs, TTabsProps } from '@/shared/components';
import { ACCOUNT_LINKS, ACCOUNT_TABS } from '@/app/navigation';
import { useGetAccountQuery } from '@/entities/user-controller';
import { useHistory } from '@/shared/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from '@/app/icons';
import { useEffect, useMemo } from 'react';
import { typedObjectEntries } from '@/shared/utils';

export const AccountTabs = () => {
  const { accountId } = useParams();
  const { pathname } = useLocation();
  const navigate = useHistory();
  const { data: account } = useGetAccountQuery(accountId as string);

  const tabs = useMemo(() => typedObjectEntries(ACCOUNT_TABS).map(([key]) => key), []);
  const activeKey = useMemo(() => tabs.find((tab) => pathname.includes(tab)), [pathname, tabs]);

  useEffect(() => {
    if (!activeKey) {
      navigate(`${ACCOUNT_LINKS.account}/${accountId}/${ACCOUNT_TABS.posts}`);
    }
  }, [accountId, navigate, activeKey]);

  if (!account) {
    return <Loader />;
  }

  const isBreeder = account.nursery;

  const onTabClick = (key: string) => {
    const path = `${ACCOUNT_LINKS.account}/${account.id}/${key}`;
    navigate(path);
  };

  const items: TTabsProps['items'] = [
    {
      key: ACCOUNT_TABS.posts,
      label: 'Публикации',
    },
    {
      key: ACCOUNT_TABS.nursery,
      label: 'Питомник',
      isHidden: !isBreeder,
    },
    {
      key: ACCOUNT_TABS.showroom,
      label: 'Шоурум',
    },
  ];

  return <Tabs onTabClick={onTabClick} activeKey={activeKey} items={items} />;
};
