import { Flex } from 'antd';
import { PublishMarketplaceButton, ShareButton } from '@/features/buttons';
import { TAnimalDto } from '@/entities/animal-controller';
import { Headline3 } from '@/shared/ui';

type TProps = {
  animal: TAnimalDto;
};

export const AnimalHeader = ({ animal }: TProps) => {
  const {
    canEdit,
    nursery: { nameEn },
  } = animal;
  return (
    <Flex vertical gap={16}>
      <Headline3>{nameEn}</Headline3>
      <Flex gap={16}>
        <ShareButton shareProps={{ text: 'Racy card', title: 'Checkout racy card' }} />
        {canEdit && <PublishMarketplaceButton animal={animal} />}
      </Flex>
    </Flex>
  );
};
