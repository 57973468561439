import { Body2, Hairline2 } from '@/shared/ui';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import cn from 'classnames';
import { useMedia } from '@/shared/hooks';

export type TLabelProps = {
  label?: string | ReactNode;
  isHorizontal?: boolean;
};

const labelClassName = 'label w-full';

export const Label = ({ label, isHorizontal = true }: TLabelProps) => {
  const { isDesktop } = useMedia();

  if (typeof label !== 'string') {
    return label;
  }

  return isDesktop && isHorizontal ? (
    <Body2 className={cn(`${labelClassName} font-semibold`)}>{label}</Body2>
  ) : (
    <Hairline2 className={cn(`${labelClassName} text-neutral5 font-bold uppercase`)}>{label}</Hairline2>
  );
};

export const LabelWrapper = ({ children, isHorizontal = true, label }: TLabelProps & { children: ReactNode }) => {
  const { isDesktop } = useMedia();

  const isVertical = !isDesktop || !isHorizontal;

  return (
    <Flex
      vertical={isVertical}
      justify={isVertical ? undefined : 'space-between'}
      gap={isVertical ? 8 : 12}
      className={cn({ 'label-wrapper': label }, 'w-full')}
    >
      {label && <Label label={label} isHorizontal={!isVertical} />}
      {children}
    </Flex>
  );
};
