import { useParams } from 'react-router-dom';
import { useGetForeignUserQuery } from '@/entities/user-controller';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';
import { Caption2, Divider, Headline4, CustomAvatar } from '@/shared/components';
import { theme } from '@/app/theme';
import { AccountSidebarDetails, AccountsList } from '@/features/account';

export const AccountSidebar = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { data: user } = useGetForeignUserQuery(accountId as string);

  if (!user) {
    return <Loader />;
  }

  const { avatar, name, surname, description, id: userId } = user;

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={6} align="center">
        <CustomAvatar size="100px" src={avatar?.url} name={name} />
        <Headline4 styles={{ color: theme.palette.neutral[8] }}>
          {name} {surname}
        </Headline4>
        <Caption2 styles={{ color: theme.palette.neutral[10] }}>{description}</Caption2>
      </Flex>
      <AccountSidebarDetails user={user} />
      <Divider style={{ backgroundColor: theme.palette.neutral[3] }} />
      <AccountsList accountId={accountId as string} userId={userId} />
    </Flex>
  );
};
