import { HeartEmpty, HeartFill } from '@/app/icons';
import { theme } from '@/app/theme';
import { useDeleteLikeMutation, usePostLikeMutation } from '@/entities/post-controller';
import { useDeleteCommentLikeMutation, usePostCommentLikeMutation } from '@/entities/comments-controller';
import { useAuth } from '@/shared/hooks/useAuth';

type TProps = {
  likedByCurrent: boolean;
  postId?: string;
  commentId?: string;
  width?: number;
};

export const LikeButton = ({ likedByCurrent, postId, commentId, width = 24 }: TProps) => {
  // post API
  const [likePost] = usePostLikeMutation();
  const [dislikePost] = useDeleteLikeMutation();
  // comment API
  const [likeComment] = usePostCommentLikeMutation();
  const [dislikeComment] = useDeleteCommentLikeMutation();

  const { handleAuth } = useAuth();

  const likeHandler = async () => {
    try {
      if (postId) {
        likedByCurrent ? await dislikePost(postId).unwrap() : await likePost(postId).unwrap();
      } else if (commentId) {
        likedByCurrent ? await dislikeComment(commentId).unwrap() : await likeComment(commentId).unwrap();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <button style={{ display: 'contents', cursor: 'pointer' }} type="button" onClick={() => handleAuth(likeHandler)}>
      {likedByCurrent ? (
        <HeartFill width={width} height={width} color={theme.palette.primary[3]} />
      ) : (
        <HeartEmpty width={width} height={width} />
      )}
    </button>
  );
};
