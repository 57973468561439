import { device } from '@/app/theme/device';
import { Button as AntButton, ButtonProps } from 'antd';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const StyledBtn = styled(AntButton)<{ $fullWidth: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  padding: 8px 12px;
  width: fit-content;
  height: fit-content;
  ${({ theme }) => theme.typography.button2}
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  &:disabled {
    background-color: ${({ theme }) => theme.palette.neutral[5]};
    border-color: unset;
    color: ${({ theme }) => theme.palette.neutral[8]};
    opacity: 50%;
  }

  &.ant-btn-lg {
    padding: 16px 24px;
    ${({ theme }) => theme.typography.button1};

    @media screen and ${device.tablet} {
      padding: 8px 12px;
      ${({ theme }) => theme.typography.button3}
    }

    @media screen and ${device.mobile} {
      padding: 12px 16px;
      ${({ theme }) => theme.typography.button2}
    }
  }

  &.ant-btn-link {
    padding: 0;
    ${({ theme }) => theme.typography['14R']};

    @media screen and (${device.tablet}) {
      ${({ theme }) => theme.typography['12R']};
    }
  }
`;

export type TButtonProps = ButtonProps & {
  children: ReactNode;
  fullWidth?: boolean;
};

export const Button = ({ type = 'primary', fullWidth = false, ...props }: TButtonProps) => (
  <StyledBtn $fullWidth={fullWidth} type={type} {...props} />
);
