import { NotificationPopup } from '@/shared/components';
import { Body2 } from '@/shared/ui';

type TProps = {
  onClose: () => void;
};

const TITLE = 'Изменения сохранены';
const SUCCESS_EDITED = 'Информация об аккаунте успешно обновлены';

export const EditAccountNotificationModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Body2>{SUCCESS_EDITED}</Body2>
  </NotificationPopup>
);
