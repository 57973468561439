import { theme } from '@/app/theme';
import { device } from '@/app/theme/device';
import { ReactNode } from 'react';
import styled, { css, CSSProp } from 'styled-components';

const TypographyTypes = {
  ...theme.typography,
} as const;
const TypographyWeight = {
  ...theme.fontWeight,
} as const;

export type TCommonTextProps = {
  onClick?: () => void;
  children: ReactNode;
  uppercase?: boolean;
  weight?: keyof typeof TypographyWeight;
  styles?: CSSProp;
  className?: string;
};

export const Typography = styled.div<
  TCommonTextProps & {
    type: keyof typeof TypographyTypes;
  }
>`
  ${({ weight }) => weight && theme.fontWeight[weight]}
  ${({ type, theme }) => theme.typography[type]}
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${({ styles }) => styles}
`;

const StyledHeadLine1 = styled(Typography)<TCommonTextProps>`
  @media ${device.tablet} {
    ${({ theme }) => theme.typography['18']}
  }
  @media ${device.mobile} {
    ${({ theme }) => theme.typography['20B']}
  }
`;
const StyledHeadLine4 = styled(Typography)<TCommonTextProps>`
  @media ${device.tablet} {
    ${({ theme }) => theme.typography['14']};
  }
  @media ${device.mobile} {
    ${({ theme }) => theme.typography['16B']};
  }
`;

const StyledBody2 = styled(Typography)<TCommonTextProps>`
  @media ${device.tablet} {
    ${({ theme }) => theme.typography['12']};
  }
  @media ${device.mobile} {
    ${({ theme }) => theme.typography['12']};
  }
`;

const StyledCaption2 = styled(Typography)<TCommonTextProps>`
  @media ${device.tablet} {
    ${({ theme }) => theme.typography['11']};
  }
`;

export const Headline1 = ({ children, ...props }: TCommonTextProps) => (
  <StyledHeadLine1 type="26" weight="B" {...props}>
    {children}
  </StyledHeadLine1>
);

export const Headline3 = ({ children, ...props }: TCommonTextProps) => (
  <StyledHeadLine1 type="20B" weight="B" {...props}>
    {children}
  </StyledHeadLine1>
);

export const Headline4 = ({ children, ...props }: TCommonTextProps) => (
  <StyledHeadLine4 type="18" weight="B" {...props}>
    {children}
  </StyledHeadLine4>
);
export const Body2 = ({ children, ...props }: TCommonTextProps) => (
  <StyledBody2 type="14" weight="R" {...props}>
    {children}
  </StyledBody2>
);

export const Caption2 = ({ children, ...props }: TCommonTextProps) => (
  <StyledCaption2 type="12" weight="R" {...props}>
    {children}
  </StyledCaption2>
);
