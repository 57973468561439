import { useCallback } from 'react';
import { MODALS, selectCurrentAccountId, setGlobalModal, useTypedDispatch, useTypedSelector } from '@/app/store';

export const useAuth = () => {
  const currentAccountId = useTypedSelector(selectCurrentAccountId);

  const dispatch = useTypedDispatch();

  const handleAuth = useCallback(
    (callback: () => void) => {
      if (currentAccountId) {
        callback();
      } else {
        dispatch(setGlobalModal(MODALS.AUTH));
      }
    },
    [dispatch, currentAccountId],
  );

  return { handleAuth };
};
