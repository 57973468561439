export const fontWeight = {
  R: {
    fontWeight: '400',
  },
  M: {
    fontWeight: '500',
  },
  SB: {
    fontWeight: '600',
  },
  B: {
    fontWeight: '700',
  },
};
