import { Body2, Card, List } from '@/shared/ui';
import { useNavigate } from 'react-router-dom';
import { useGetMainCategoriesQuery } from '@/entities/good-category-controller';
import { MARKETPLACE_PATHS } from '@/app/navigation';

export const CatalogList = () => {
  const navigate = useNavigate();
  const { data: catalogs = [] } = useGetMainCategoriesQuery();

  const data = catalogs
    .map((item) => {
      return {
        id: item.id,
        title: item.name,
        src: item.fileImageId.url,
        alt: item.fileImageId.fileName,
        onClick: () => navigate(`${MARKETPLACE_PATHS.catalog}/${item.id}`),
      };
    })
    .splice(0, 2);

  return (
    <List
      grid={{ gutter: 20, column: 2 }}
      dataSource={data}
      customItem={({ title, src, alt, onClick }) => (
        <Card
          onClick={onClick}
          hoverable
          cover={
            <div className="bg-neutral6 rounded-t-xxs h-[120px] text-center">
              <img className="w-full h-full object-contain" src={src} alt={alt} />
            </div>
          }
          metaProps={{ title: <Body2 className="font-semibold">{title}</Body2>, className: 'text-center bg-neutral9' }}
        />
      )}
    />
  );
};
