import { Collapse as AntCollapse, CollapseProps } from 'antd';
import { CollapseIcon } from '@/app/icons/CollapseIcon';
import styled, { css } from 'styled-components';
import { size } from '@/app/theme/device';

type TProps = CollapseProps;

const Icon = styled(CollapseIcon)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      transform: rotate(180deg);
    `};
`;

const CollapseWrapper = styled.div`
  .ant-collapse-item {
    padding: 32px 0;

    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral[6]};

    &:first-child {
      padding-top: 0;
    }

    .ant-collapse-header {
      padding: 0;
      align-items: center;

      &-text {
        ${({ theme }) => theme.typography['18B']}
      }
    }

    .ant-collapse-content-box {
      padding: 16px 0;
    }
  }

  @media screen and (max-width: ${size.tabletMaxWidth}) {
    .ant-collapse-item {
      padding: 16px 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }
`;

export const Collapse = ({ ...props }: TProps) => (
  <CollapseWrapper>
    <AntCollapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => <Icon $active={!!isActive} />}
      ghost
      {...props}
    />
  </CollapseWrapper>
);
