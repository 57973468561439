import { MarketplaceGoodsList } from '@/features/marketplace';
import { useParams } from 'react-router-dom';
import { queries } from '@/shared/utils';

export const PetsGoodsList = () => {
  const { catalogId } = useParams();

  if (!catalogId) {
    return null;
  }

  const query = queries.equal('category.id', catalogId);

  return <MarketplaceGoodsList query={query} />;
};
