import { SVGProps } from 'react';

export const PetIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.40869 16.75C4.16869 19.64 6.34869 22 9.24869 22H14.0387C17.2987 22 19.5387 19.37 18.9987 16.15C18.4287 12.77 15.1687 10 11.7387 10C8.01869 10 4.71869 13.04 4.40869 16.75Z"
        stroke="#212029"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4688 7.5C11.8495 7.5 12.9688 6.38071 12.9688 5C12.9688 3.61929 11.8495 2.5 10.4688 2.5C9.08804 2.5 7.96875 3.61929 7.96875 5C7.96875 6.38071 9.08804 7.5 10.4688 7.5Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2969 8.69922C18.4014 8.69922 19.2969 7.80379 19.2969 6.69922C19.2969 5.59465 18.4014 4.69922 17.2969 4.69922C16.1923 4.69922 15.2969 5.59465 15.2969 6.69922C15.2969 7.80379 16.1923 8.69922 17.2969 8.69922Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.6992C21.8284 12.6992 22.5 12.0276 22.5 11.1992C22.5 10.3708 21.8284 9.69922 21 9.69922C20.1716 9.69922 19.5 10.3708 19.5 11.1992C19.5 12.0276 20.1716 12.6992 21 12.6992Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.96875 10.6992C5.07332 10.6992 5.96875 9.80379 5.96875 8.69922C5.96875 7.59465 5.07332 6.69922 3.96875 6.69922C2.86418 6.69922 1.96875 7.59465 1.96875 8.69922C1.96875 9.80379 2.86418 10.6992 3.96875 10.6992Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
