import { SVGProps } from 'react';

export const MobileloginIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.7334 12.1837L11.8667 10.0503L9.7334 7.91699"
      stroke="#FBFCFE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33301 10.0498H11.808"
      stroke="#FBFCFE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3.33301C13.6833 3.33301 16.6667 5.83301 16.6667 9.99967C16.6667 14.1663 13.6833 16.6663 10 16.6663"
      stroke="#FBFCFE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
