import { baseApi } from '@/app/core';
import { convertToSelectOption } from '@/shared/utils';
import { TSelectOption } from '@/shared/common';

export type TCountryDto = {
  id: number;
  name: string;
  nameEn: number;
};

const COUNTRIES_PATH = 'countries';

type TResponse = TCountryDto & TSelectOption;

const countryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<TResponse[], void>({
      query: () => COUNTRIES_PATH,
      transformResponse: convertToSelectOption,
    }),
  }),
});

export const { useGetCountriesQuery, useLazyGetCountriesQuery } = countryApi;
