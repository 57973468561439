import { Star } from '@/app/icons';
import { Rate as AntRate, RateProps } from 'antd';
import styled from 'styled-components';

const StyledStar = styled(Star)<{ filled: boolean }>`
  fill: ${({ filled, theme }) => (filled ? theme.palette.secondary[3] : theme.palette.neutral[6])};
`;

type TProps = RateProps;

export const Rate = ({ value = 0, ...props }: TProps) => {
  return <AntRate character={({ index = 0 }) => <StyledStar filled={index < value} />} {...props} />;
};
