import { ReactComponent as DatePickerIcon } from '@/app/icons/svg/date-picker-icon.svg';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import { DatePicker as AntDatePicker, DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

import styled from 'styled-components';

type TProps = DatePickerProps & {
  labelProps?: TLabelProps;
  errorMsg?: string;
  hasError?: boolean;
  onChange?: (date: Dayjs | null) => void;
};

const StyledDatePicker = styled(AntDatePicker)<DatePickerProps>`
  width: 100%;

  &.ant-picker-status-error.ant-picker {
    .ant-picker-input {
      .ant-picker-suffix {
        svg path {
          stroke: ${({ theme }) => theme.palette.primary[3]};
        }
      }
    }
  }
`;

export const DatePicker = ({ labelProps, errorMsg, hasError, value, onChange, ...props }: TProps) => {
  const status = errorMsg || hasError ? 'error' : undefined;

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const formatDateWithoutTime = (date: Dayjs | null) => date?.format('YYYY-MM-DD') || null;

  const handleChange = (date: Dayjs | null) => {
    const formattedDate = formatDateWithoutTime(date);
    if (onChange) {
      onChange(formattedDate as Dayjs | null);
    }
  };

  return (
    <LabelWrapper {...labelProps}>
      <div>
        <StyledDatePicker
          format="DD.MM.YYYY"
          size="large"
          placeholder=""
          status={status}
          suffixIcon={<DatePickerIcon />}
          locale={locale}
          value={value ? dayjs(value as Dayjs) : null}
          onChange={handleChange}
          {...props}
        />
        {errorMsg && <ErrorMessage message={errorMsg} />}
      </div>
    </LabelWrapper>
  );
};
