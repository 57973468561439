import { TextArea } from '@/shared/components';
import { Flex } from 'antd';
import { Loader, SendComment } from '@/app/icons';
import { usePostCommentMutation } from '@/entities/comments-controller';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const FORM_FIELDS = {
  comment: 'comment',
} as const;

const validation = z.object({
  [FORM_FIELDS.comment]: z.string().nonempty(),
});

type TSchema = z.infer<typeof validation>;

type TProps = {
  postId: string;
};

export const AddComment = ({ postId }: TProps) => {
  const [sendComment, { isLoading }] = usePostCommentMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<TSchema>({
    defaultValues: {
      [FORM_FIELDS.comment]: '',
    },
    resolver: zodResolver(validation),
  });

  const onSendComment = async (data: TSchema) => {
    try {
      await sendComment({ postId, text: data.comment });
      reset();
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Controller
      control={control}
      render={({ field }) => (
        <Flex align="start">
          <TextArea
            {...field}
            isBlank
            autoSize={{ maxRows: 3 }}
            maxLength={250}
            placeholder="Добавьте комментарий..."
          />
          <SendComment cursor="pointer" onClick={handleSubmit(onSendComment)} />
        </Flex>
      )}
      name={FORM_FIELDS.comment}
    />
  );
};
