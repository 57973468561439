import { useMedia, useModal } from '@/shared/hooks';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { PlusButtonIcon } from '@/app/icons';
import { FlexPage } from '@/shared/containers';
import { Button } from '@/shared/ui';
import { AddShowroomProductModal, ShowroomGoodsList } from '@/features/show-room';
import { useParams } from 'react-router-dom';

export const ShowRoomList = () => {
  const { accountId } = useParams();
  const { isOpen, open, close } = useModal();
  const { data: currentUser } = useGetCurrentUserQuery();
  const nursery = currentUser?.nurseries[0];
  const { isDesktop } = useMedia();

  return (
    <FlexPage>
      {!accountId && (
        <Button
          size={isDesktop ? 'medium' : 'small'}
          className="w-full"
          onClick={open}
          icon={<PlusButtonIcon width={16} height={16} />}
        >
          Выставить на продажу
        </Button>
      )}
      {!accountId && nursery && isOpen && (
        <AddShowroomProductModal speciesId={nursery.species.id} open={isOpen} onCancel={close} />
      )}
      <ShowroomGoodsList />
    </FlexPage>
  );
};
