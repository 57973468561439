import ReactGA4 from 'react-ga4';

export const AnalyticInit = () => {
  const GoogleId = process.env.REACT_APP_GOOGLE_ANALYTICS;

  if (GoogleId) {
    ReactGA4.initialize(GoogleId);
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname,
    });
  }
};
