import { Flex } from 'antd';
import { Body2, TUploadFile, Upload } from '@/shared/components';
import { ProfileAvatar } from '@/features/profile/ProfileAvatar';
import { Caption2, LabelWrapper } from '@/shared/ui';
import styles from './styles.module.scss';
import { Plus } from '@/app/icons';
import { TAccountDto } from '@/entities/user-controller';
import { useFormContext, useWatch } from 'react-hook-form';

export const AccountUploadPhoto = ({ accountData }: { accountData?: TAccountDto }) => {
  const { setValue } = useFormContext();

  const handleAvatarChange = (uploadedFiles: TUploadFile[]) => {
    const file = uploadedFiles[0];
    setValue('avatar', { id: file.id, url: file.url || '' });
  };

  const avatar = useWatch({
    name: 'avatar',
  });

  return (
    <LabelWrapper label="Фотография:">
      <Flex className="w-full">
        {!avatar?.url ? (
          <Upload handleChange={handleAvatarChange}>
            <Flex className={styles['upload-photo-placeholder']}>
              <Flex vertical align="center" className="plus-icon">
                <Plus />
                <Caption2>Добавить</Caption2>
              </Flex>
            </Flex>
          </Upload>
        ) : (
          <ProfileAvatar
            url={avatar.url}
            name={accountData?.name || ''}
            onChange={(file) => handleAvatarChange([file])}
          />
        )}
      </Flex>
    </LabelWrapper>
  );
};
