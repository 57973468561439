import { Select, TSelectProps } from '@/shared/components';
import { useCallback, useEffect } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { useDebounce } from '@/shared/hooks';

type TProps<ValueType, OptionType extends DefaultOptionType> = TSelectProps<ValueType, OptionType> & {
  fetchData: (search: string, page: number) => void;
};

export const AsyncSelect = <ValueType, OptionType extends DefaultOptionType>({
  fetchData,
  ...props
}: TProps<ValueType, OptionType>) => {
  const searchCallback = useCallback(
    async (search: string) => {
      await fetchData(search, 1);
    },
    [fetchData],
  );

  const handleSearch = useDebounce(searchCallback, 500);

  useEffect(() => {
    searchCallback('');
  }, [searchCallback]);

  return <Select showSearch filterOption={false} onSearch={handleSearch} {...props} />;
};
