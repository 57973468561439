import { TAnimalDto } from '@/entities/animal-controller';
import { EditAnimalInformationModal } from '@/features/animal';
import { Button, ReadableField, Typography } from '@/shared/components';
import { useModal } from '@/shared/hooks';
import { convertDateToString } from '@/shared/utils';
import styled from 'styled-components';

const DESCRIPTION = 'Описание';
const NURSERY = 'Питомник';
const NAME = 'Имя';
const SHORT_NAME = 'Домашняя кличка';
const BIRTH_COUNTRY = 'Страна рождения';
const LIVE_COUNTRY = 'Страна проживания';
const SYSTEM_REGISTRATION = 'Система регистрации';
const TRIBAL_CLASS = 'Племенной класс';
const DATE_BIRTH = 'Дата рождения';
const DEATH_DAY = 'Дата смерти';
const EDIT_INFORMATION = 'Редактировать информацию';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(4)};
`;

type TProps = {
  data: TAnimalDto;
};

export const AnimalInformation = ({ data }: TProps) => {
  const { open, close, isOpen } = useModal();
  const {
    nursery,
    fullName,
    birthCountry,
    liveCountry,
    dateBirth,
    dateDead,
    id,
    description,
    shortName,
    animalRegistrationSystem,
    tribalTypes,
    canEdit,
  } = data;

  return (
    <Wrapper>
      {description && (
        <>
          <Typography type="14SB">{DESCRIPTION}:</Typography>
          <Typography type="14R">{description}</Typography>
        </>
      )}
      <ReadableField hideFieldWithoutValue isHorizontal label={NURSERY}>
        {nursery.nameEn}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={NAME}>
        {fullName}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={SHORT_NAME}>
        {shortName}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={BIRTH_COUNTRY}>
        {birthCountry?.name}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={LIVE_COUNTRY}>
        {liveCountry?.name}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={SYSTEM_REGISTRATION}>
        {animalRegistrationSystem?.abbreviation}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={TRIBAL_CLASS}>
        {tribalTypes?.map(({ name }) => name).join(', ')}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={DATE_BIRTH}>
        {dateBirth && convertDateToString(dateBirth)}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={DEATH_DAY}>
        {dateDead && convertDateToString(dateDead)}
      </ReadableField>
      {canEdit && <Button onClick={open}>{EDIT_INFORMATION}</Button>}
      {isOpen && (
        <EditAnimalInformationModal
          onCancel={close}
          defaultValues={{
            tribalTypes: tribalTypes?.map(({ id }) => id),
            liveCountryId: liveCountry?.id,
            birthCountryId: birthCountry?.id,
            description,
            shortName,
            dateDead,
          }}
          animalId={id}
        />
      )}
    </Wrapper>
  );
};
