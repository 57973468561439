import styled from 'styled-components';
import { Close } from '@/app/icons';

const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  color: ${({ theme }) => theme.palette.neutral[2]};
  padding: ${({ theme }) => theme.spacing(2, 3)};
  background: ${({ theme }) => theme.palette.neutral[6]};
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const Label = styled.p`
  ${({ theme }) => theme.typography['14SB']};
  line-height: 16px;
`;

type TProps = {
  onClose?: () => void;
  label: string;
};

export const MultiBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacing(2)};
  row-gap: 8px;
`;

export const Badge = ({ label, onClose }: TProps) => {
  return (
    <StyledBadge>
      <Label>{label}</Label>
      {onClose && <CloseIcon onClick={onClose} />}
    </StyledBadge>
  );
};
