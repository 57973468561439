import { Select, TSelectProps } from '@/shared/ui';
import { FieldValues } from 'react-hook-form';
import { useDebounce } from '@/shared/hooks';
import { useCallback, useEffect } from 'react';

type TProps<R extends FieldValues> = TSelectProps<R> & {
  fetchData: (search: string, page: number) => void;
};

export const AsyncSelect = <R extends FieldValues>({ fetchData, ...props }: TProps<R>) => {
  const searchCallback = useCallback(
    (search: string) => {
      fetchData(search, 1);
    },
    [fetchData],
  );

  const handleSearch = useDebounce(searchCallback, 500);

  useEffect(() => {
    searchCallback('');
  }, [searchCallback]);

  return <Select filterOption={false} onSearch={handleSearch} {...props} />;
};
