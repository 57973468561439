import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    html,
    body {      
      height: 100dvh;
      overflow: hidden;
      margin: 0; 
      padding: 0;
      font-family: 'Montserrat', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background: ${({ theme }) => theme.palette.neutral[9]};
    }
  
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    & * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    & button {
        color: currentColor;
        border: none; 
        cursor: pointer;
        background: transparent;
    }

    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    
    //ant design
    .ant-input-lg,
    .ant-select-selection-item, 
    .ant-select-item-option-content, 
    .ant-select-selection-search-input, 
    .ant-select-selection-placeholder, 
    .ant-picker-input input {
      font-weight: 600 !important;
    }
`;
