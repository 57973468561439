export const neutral = {
  1: '#1A191F',
  2: '#212029',
  '2alpha90': 'rgba(33, 32, 41, 0.9)',
  3: '#333240',
  4: '#8A91A9',
  5: '#ACB2C5',
  6: '#E5E7EF',
  7: '#F4F5F9',
  '7alpha20': 'rgba(251, 252, 254, 0.2)',
  8: '#FBFCFE',
  '8alpha10': 'rgba(251, 252, 254, 0.1)',
  9: '#FFFFFF',
  10: '#777E90',
};
