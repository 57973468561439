import { SVGProps } from 'react';

export const EditAccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
    {...props}
  >
    <path
      d="M8.75604 2.99722L3.86913 8.16984C3.68461 8.36627 3.50604 8.75317 3.47032 9.02103L3.25009 10.9496C3.17271 11.646 3.67271 12.1222 4.36318 12.0032L6.27985 11.6758C6.54771 11.6282 6.9227 11.4317 7.10723 11.2294L11.9941 6.05675C12.8394 5.16389 13.2203 4.14603 11.9048 2.90198C10.5953 1.66984 9.60128 2.10437 8.75604 2.99722Z"
      stroke="#1A191F"
      strokeWidth="0.892857"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9375 3.86328C8.19345 5.50614 9.52679 6.76209 11.1815 6.92876"
      stroke="#1A191F"
      strokeWidth="0.892857"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.64844 13.9492H13.3627"
      stroke="#1A191F"
      strokeWidth="0.892857"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
