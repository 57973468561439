import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import styled, { css } from 'styled-components';

const { TextArea: AntTextArea } = Input;

type TProps = TextAreaProps & {
  errorMsg?: string;
  hasError?: boolean;
  labelProps?: TLabelProps;
  isBlank?: boolean;
};

const Wrapper = styled.div<{ $isBlank?: boolean }>`
  ${({ $isBlank }) =>
    $isBlank &&
    css`
      .ant-input-outlined {
        border: none;
        box-shadow: none;
      }
      textarea {
        font-weight: 400 !important;
        background-color: transparent;
        min-height: fit-content;
        padding: 0;

        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    `}
`;

export const TextArea = ({ labelProps, errorMsg, hasError, isBlank, ...props }: TProps) => {
  const status = errorMsg || hasError ? 'error' : undefined;
  return (
    <LabelWrapper style={{ alignItems: 'start' }} {...labelProps}>
      <Wrapper $isBlank={isBlank}>
        <AntTextArea size="large" status={status} {...props} />
        {errorMsg && <ErrorMessage message={errorMsg} />}
      </Wrapper>
    </LabelWrapper>
  );
};
