import { YMInitializer } from 'react-yandex-metrika';

const YANDEX_METRIK_ID = process.env.REACT_APP_YANDEX_METRIKA;

export const YandexMetrika = () => {
  if (!YANDEX_METRIK_ID) {
    return null;
  }

  return (
    <YMInitializer
      accounts={[Number(YANDEX_METRIK_ID)]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      }}
    />
  );
};
