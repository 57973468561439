import { useGetAccountQuery } from '@/entities/user-controller';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';
import { CreatePost } from '@/widgets/post';
import { Pawsgram } from '@/widgets/pawsgram';
import { useMedia } from '@/shared/hooks';
import { Body2, CustomAvatar } from '@/shared/components';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';

const AccountPostsInfo = ({ accountId }: { accountId: string }) => {
  const { isMobile } = useMedia();

  const { data: account } = useGetAccountQuery(accountId);

  if (!account) {
    return <Loader />;
  }

  const { postsCount, subscribersCount, subscriptionsCount, name, avatar } = account;

  const accountStats = [
    { label: 'публикаций', value: postsCount },
    { label: 'подписчиков', value: subscribersCount },
    { label: 'подписок', value: subscriptionsCount },
  ];

  return (
    <Flex justify="space-between" align="center">
      <CustomAvatar round size={!isMobile ? '48px' : '36px'} name={name} src={avatar?.url} />
      {accountStats.map(({ label, value }) => (
        <Body2 key={label}>
          <b>{value}</b> {label}
        </Body2>
      ))}
      <CreatePost />
    </Flex>
  );
};

export const HomePawsgram = () => {
  const accountId = useTypedSelector(selectCurrentAccountId);

  if (!accountId) {
    return <Loader />;
  }

  return (
    <>
      <AccountPostsInfo accountId={accountId} />
      <Pawsgram accountId={accountId} />
    </>
  );
};
