import { Upload as AntUpload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useSendFileMutation } from '@/entities/file-controller';

export type TUploadFile = UploadFile & {
  id: string;
};

type TProps = Omit<UploadProps, 'fileList'> & {
  handleChange?: (value: TUploadFile[]) => void;
  onUploadHandler?: (file: RcFile[]) => void;
  fileList?: TUploadFile[];
};

export const Upload = ({ children, handleChange, onUploadHandler, maxCount, fileList = [], ...props }: TProps) => {
  const [sendFile] = useSendFileMutation();

  const uploadFiles = async (files: RcFile[]) => {
    if (!handleChange) {
      return;
    }

    const uploaded: TUploadFile[] = [];
    await Promise.all(
      files.map((file) =>
        sendFile(file)
          .unwrap()
          .then((response) => {
            uploaded.push({ ...file, ...response });
          }),
      ),
    );

    handleChange([...fileList, ...uploaded]);
  };

  const beforeUpload = (fileRequest: RcFile, list: RcFile[]) => {
    if (fileRequest.uid !== list[list.length - 1].uid) {
      return false;
    }
    if (onUploadHandler) {
      onUploadHandler(list);
      return false;
    }

    const getList = () => {
      if (maxCount) {
        const diff = maxCount - fileList.length;

        if (diff < list.length) {
          return list.splice(0, diff);
        }
      }

      return list;
    };

    uploadFiles(getList());

    return false;
  };

  return (
    <AntUpload showUploadList={false} beforeUpload={beforeUpload} {...props}>
      {children}
    </AntUpload>
  );
};
