import { Flex } from 'antd';
import { Body3 } from '@/shared/ui';
import cn from 'classnames';

type TProps = {
  label: string;
  text?: string | null;
  labelClassName?: string;
  textClassName?: string;
};

export const PseudoInput = ({ label, text, textClassName, labelClassName }: TProps) => (
  <Flex className="pseudo-input" justify="space-between" gap={16}>
    <Body3 className={cn('w-[calc(50%-8px)] font-semibold lg:text-body2', labelClassName)}>{label}</Body3>
    <Body3 className={cn('w-[calc(50%-8px)] lg:text-body2', textClassName)}>{text ?? '-'}</Body3>
  </Flex>
);
