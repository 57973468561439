import { Controller, FormProvider, useForm } from 'react-hook-form';

import { ROUTE } from '@/app/navigation/constants';
import { TSignupRequest, useSignUpMutation } from '@/entities/auth-controller';
import { SPECIES } from '@/entities/species-controller';
import { Credentials, Nursery, RegistrationModal, Residence, Terms } from '@/features/registration';
import { Button, Headline4, Input, RadioGroup } from '@/shared/components';
import { RowWrapper } from '@/shared/containers';
import { useModal } from '@/shared/hooks';
import { MODALS, setGlobalModal } from '@/app/store/slices/globalModals';
import {
  FORM_FIELDS,
  INTRODUCE,
  NAME,
  OPTIONS,
  QUESTION_BREEDER,
  REGISTRATION_SUBMIT,
  SURNAME,
  registrationValidation,
} from '@/widgets/registration/RegisterForm/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { FlexProps } from 'antd/es/flex/interface';
import { useMedia } from '@/shared/hooks/useMedia';
import { HOME_PATHS } from '@/app/navigation';

type TFormFields = TSignupRequest & {
  speciesId: SPECIES;
};

const FormWrapper = styled.form`
  display: contents;

  button {
    margin: 0 auto;
  }
`;

const BlockWrapper = ({ children, ...props }: { children: ReactNode } & FlexProps) => (
  <Flex vertical gap={16} {...props}>
    {children}
  </Flex>
);

export const RegisterForm = () => {
  const [signup, { isLoading }] = useSignUpMutation();
  const { open, isOpen } = useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useMedia();

  const formMethods = useForm<TFormFields>({
    defaultValues: {
      [FORM_FIELDS.name]: '',
      [FORM_FIELDS.surname]: '',
      [FORM_FIELDS.email]: '',
      [FORM_FIELDS.password]: '',
      [FORM_FIELDS.confirmPassword]: '',
      [FORM_FIELDS.breeder]: false,
      [FORM_FIELDS.terms]: false,
      [FORM_FIELDS.nurseryNameEn]: '',
      [FORM_FIELDS.speciesId]: SPECIES.CATS,
    },
    resolver: zodResolver(registrationValidation),
  });

  const { watch, handleSubmit, getValues } = formMethods;

  const isBreeder = watch(FORM_FIELDS.breeder);

  const goHome = () => navigate(HOME_PATHS.home);

  const onSubmit = async (data: TFormFields) => {
    try {
      await signup(data).unwrap();
      open();
    } catch (e) {
      console.error(e);
    }
  };

  const onResend = async () => {
    try {
      const data = getValues();
      await signup(data).unwrap();
      goHome();
      dispatch(setGlobalModal(MODALS.EMAIL_RESEND));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <FormWrapper onSubmit={handleSubmit(onSubmit)} role="presentation" autoComplete="off">
        <BlockWrapper>
          <Headline4>{INTRODUCE}</Headline4>
          <RowWrapper>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Input labelProps={{ label: NAME }} {...field} errorMsg={error?.message} />
              )}
              name={FORM_FIELDS.name}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Input labelProps={{ label: SURNAME }} {...field} errorMsg={error?.message} />
              )}
              name={FORM_FIELDS.surname}
            />
          </RowWrapper>
        </BlockWrapper>
        <Credentials />
        <BlockWrapper>
          <Headline4>{QUESTION_BREEDER}</Headline4>
          <Controller name={FORM_FIELDS.breeder} render={({ field }) => <RadioGroup {...field} options={OPTIONS} />} />
        </BlockWrapper>
        {isBreeder && (
          <>
            <Residence />
            <Nursery />
          </>
        )}
        <Terms />
        <Button fullWidth={isMobile} loading={isLoading} size="large" htmlType="submit">
          {REGISTRATION_SUBMIT}
        </Button>
      </FormWrapper>
      {isOpen && <RegistrationModal isLoading={isLoading} onSend={onResend} onCancel={goHome} />}
    </FormProvider>
  );
};
