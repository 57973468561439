import { Body2, Popup, TPopupProps } from '@/shared/components';

const CANCEL = 'Отмена';

export const ResetPasswordModal = ({ children, onCancel, ...props }: TPopupProps) => (
  <Popup
    open
    size="SMALL"
    withFooterDivider={false}
    onCancel={onCancel}
    maskClosable={false}
    cancelButtonProps={{ children: CANCEL, onClick: onCancel }}
    {...props}
  >
    <Body2>{children}</Body2>
  </Popup>
);
