import { Flex, Radio as AtnRadio, RadioGroupProps } from 'antd';
import { Radio } from './Radio';
import { Controller, FieldValues } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import { LabelWrapper, TLabelProps } from '@/shared/ui';

type TProps<R extends FieldValues> = Omit<RadioGroupProps, 'options'> & {
  options: { value: string | number | boolean; label: string }[];
  vertical?: boolean;
  labelProps?: TLabelProps;
  controllerProps: TControllerProps<R>;
};

export const RadioGroup = <R extends FieldValues>({
  options,
  vertical,
  controllerProps,
  labelProps,
  ...props
}: TProps<R>) => (
  <Controller
    render={({ field }) => (
      <LabelWrapper {...labelProps}>
        <AtnRadio.Group {...field} {...props}>
          <Flex vertical={vertical} gap={vertical ? 6 : 0}>
            {options.map(({ value, label }) => (
              <Radio key={label} value={value}>
                {label}
              </Radio>
            ))}
          </Flex>
        </AtnRadio.Group>
      </LabelWrapper>
    )}
    {...controllerProps}
  />
);
