import { SVGProps } from 'react';

export const Warning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8 5.16675V8.66675"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0533 5.71994V10.2799C14.0533 11.0266 13.6533 11.7199 13.0066 12.0999L9.04663 14.3866C8.39996 14.7599 7.59995 14.7599 6.94661 14.3866L2.98661 12.0999C2.33995 11.7266 1.93994 11.0333 1.93994 10.2799V5.71994C1.93994 4.97327 2.33995 4.27991 2.98661 3.89991L6.94661 1.61325C7.59328 1.23992 8.3933 1.23992 9.04663 1.61325L13.0066 3.89991C13.6533 4.27991 14.0533 4.96661 14.0533 5.71994Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 10.8V10.8667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
