import { FieldValues, Controller } from 'react-hook-form';
import { DatePickerProps, DatePicker as AntDatepicker } from 'antd';
import { TControllerProps } from '@/shared/common';
import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import dayjs, { Dayjs } from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { DatepickerIcon } from '@/app/icons';
import cn from 'classnames';

type TProps<R extends FieldValues> = Omit<DatePickerProps, 'onChange'> & {
  controllerProps: TControllerProps<R>;
  labelProps?: TLabelProps;
  onChange?: (value: string | null) => void;
};

export const Datepicker = <R extends FieldValues>({ controllerProps, labelProps, onChange, ...props }: TProps<R>) => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const formatDateWithoutTime = (date: Dayjs | null) => date?.format('YYYY-MM-DD') || null;

  return (
    <Controller
      render={({ field: { value, onChange: initChange, ...rest }, fieldState: { error } }) => (
        <LabelWrapper {...labelProps}>
          <ErrorWrapper>
            <AntDatepicker
              placeholder=""
              locale={locale}
              format="DD.MM.YYYY"
              size="large"
              suffixIcon={
                <DatepickerIcon
                  className={cn('text-neutral2', {
                    'text-primary3': error,
                  })}
                />
              }
              status={error && 'error'}
              value={value ? dayjs(value as Dayjs) : null}
              onChange={(date) => {
                const formattedDate = formatDateWithoutTime(date);
                if (onChange) {
                  return onChange(formattedDate);
                }
                initChange(formattedDate);
              }}
              {...rest}
              {...props}
            />
          </ErrorWrapper>
        </LabelWrapper>
      )}
      {...controllerProps}
    />
  );
};
