import { FlexPage } from '@/shared/containers';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { MarketplaceGoodsList } from '@/features/marketplace';
import { Flex } from 'antd';
import {
  ButtonActions,
  GoodsCharacteristics,
  GoodsDelivery,
  GoodsDescription,
  GoodsImagesView,
  GoodsSeller,
  GoodsTitle,
} from '@/features/goods';
import { AnimalPedigreeInfo } from '@/widgets/animal';
import { Collapse } from '@/shared/components';

export const DesktopView = ({ data }: { data: TGoodDtoExt }) => {
  const {
    animalExt: {
      images,
      description,
      nursery: {
        account: { id: accountId },
      },
    },
    city,
    canEdit,
  } = data;

  return (
    <FlexPage>
      <Flex justify="space-between" gap={32}>
        <Flex className="w-full max-w-[640px]" vertical gap={32}>
          <GoodsImagesView images={images} />
          <Collapse
            defaultActiveKey={1}
            items={[
              { key: 1, label: 'Описание', children: <GoodsDescription description={description} /> },
              { key: 2, label: 'Родословная', children: <AnimalPedigreeInfo animal={data.animalExt} /> },
            ]}
          />
        </Flex>
        <Flex className="w-[380px]" vertical gap={24}>
          <GoodsTitle data={data} />
          {!canEdit && <ButtonActions className="flex-col items-start" goodData={data} />}
          {city && <GoodsDelivery city={city.name} />}
          <GoodsCharacteristics withTitle good={data} />
          {!canEdit && <GoodsSeller accountId={accountId} good={data} />}
        </Flex>
      </Flex>
      <MarketplaceGoodsList />
    </FlexPage>
  );
};
