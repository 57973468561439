import { NotificationPopup } from '@/shared/components';

type TProps = {
  onClose: () => void;
};

const TITLE = 'Письмо отправлено';
const TEXT = 'Письмо было повторно отправлено на указанный Вами адрес электронной почты.';

export const EmailResendModal = ({ onClose }: TProps) => (
  <NotificationPopup title={TITLE} open onCancel={onClose}>
    {TEXT}
  </NotificationPopup>
);
