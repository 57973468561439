import { Radio as AntRadio, RadioProps } from 'antd';
import styled from 'styled-components';
import { device } from '@/app/theme/device';

type TProps = RadioProps;

const StyledRadio = styled(AntRadio)`
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: ${({ theme }) => theme.palette.neutral[9]};
      border-color: ${({ theme }) => theme.palette.neutral[6]};

      &::after {
        background-color: ${({ theme }) => theme.palette.primary[1]};
        transform: scale(0.5) !important;
      }
    }
  }

  ${({ theme }) => theme.typography['14SB']}

  @media screen and ${device.tablet} {
    ${({ theme }) => theme.typography['12SB']}
  }
`;

export const Radio = ({ ...props }: TProps) => <StyledRadio {...props} />;
