import { useLazyCheckEmailQuery } from '@/entities/auth-controller';
import { PasswordCreate } from '@/features/password';
import { Headline4, Input } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { EMAIL_EXIST_MESSAGE, emailRule } from '@/shared/validation';
import { FORM_FIELDS } from '@/widgets/registration/RegisterForm/constants';
import { Controller, useFormContext } from 'react-hook-form';
import { CREDENTIALS_TITLE, EMAIL } from './constants';
import { useMedia } from '@/shared/hooks/useMedia';

export const Credentials = () => {
  const { control, setError, clearErrors } = useFormContext();
  const [checkEmail] = useLazyCheckEmailQuery();

  const { isMobile } = useMedia();

  const onBlurEmail = async (email: string) => {
    try {
      const isEmailValid = emailRule.safeParse(email).success;
      if (isEmailValid) {
        const isEmailExist = await checkEmail(email).unwrap();
        isEmailExist ? setError(FORM_FIELDS.email, { message: EMAIL_EXIST_MESSAGE }) : clearErrors(FORM_FIELDS.email);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const width = isMobile ? '100%' : '48%';

  return (
    <ColumnWrapper>
      <Headline4>{CREDENTIALS_TITLE}</Headline4>
      <Controller
        name={FORM_FIELDS.email}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            width={width}
            {...field}
            onBlur={(e) => onBlurEmail(e.target.value)}
            labelProps={{ label: EMAIL }}
            errorMsg={error?.message}
          />
        )}
      />
      <PasswordCreate width={width} />
    </ColumnWrapper>
  );
};
