import { PasswordEyeIcon, PasswordEyeSlashIcon } from '@/app/icons';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import { ValidationText } from './ValidationText';
import {
  CAPITAL_LETTER,
  LOWER_LETTER,
  DIGITS,
  LIMIT_SYMBOLS,
  SYMBOLS,
  passwordCapitalLetterRule,
  passwordDigitRule,
  passwordMinMaxRule,
  passwordSymbolRule,
  passwordLowerLetterRule,
} from './constants';
import { device } from '@/app/theme/device';
import { Flex, Input as AntInput, InputProps } from 'antd';
import styled, { css } from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledInputPasswordShowIcon = styled(PasswordEyeIcon)<{ $error: boolean }>`
  color: ${({ theme }) => theme.palette.neutral[2]};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}

  @media ${device.tablet} {
    width: 16px;
    height: 16px;
  }
`;

const StyledInputPasswordHideIcon = styled(PasswordEyeSlashIcon)<{ $error: boolean }>`
  color: ${({ theme }) => theme.palette.neutral[2]};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}

  @media ${device.tablet} {
    width: 16px;
    height: 16px;
  }
`;

type TProps = InputProps & {
  errorMsg?: string;
  hasError?: boolean;
  labelProps?: TLabelProps;
  rule?: { isValid: boolean; label: string }[];
  showTips?: boolean;
};

const passwordRulesCheck = (value: string) => [
  { isValid: passwordMinMaxRule.safeParse(value).success, label: LIMIT_SYMBOLS },
  { isValid: passwordLowerLetterRule.safeParse(value).success, label: LOWER_LETTER },
  { isValid: passwordCapitalLetterRule.safeParse(value).success, label: CAPITAL_LETTER },
  { isValid: passwordDigitRule.safeParse(value).success, label: DIGITS },
  { isValid: passwordSymbolRule.safeParse(value).success, label: SYMBOLS },
];

export const InputPassword = ({
  width,
  labelProps,
  errorMsg,
  showTips = true,
  hasError,
  value,
  rule,
  ...props
}: TProps) => {
  const status = errorMsg || hasError ? 'error' : undefined;
  const validation = rule ?? passwordRulesCheck(value as string);

  const renderPasswordIcon = (visible: boolean) => {
    const Icon = visible ? StyledInputPasswordShowIcon : StyledInputPasswordHideIcon;
    return (
      <IconContainer>
        <Icon $error={!!status} />
      </IconContainer>
    );
  };

  return (
    <LabelWrapper style={{ width }} {...labelProps}>
      <Flex vertical>
        <AntInput.Password size="large" status={status} iconRender={renderPasswordIcon} value={value} {...props} />
        {errorMsg && <ErrorMessage message={errorMsg} />}
        {showTips && (
          <Flex vertical style={{ marginTop: 8 }}>
            {validation.map(({ isValid, label }) => (
              <ValidationText key={label} isValid={isValid} label={label} />
            ))}
          </Flex>
        )}
      </Flex>
    </LabelWrapper>
  );
};
