import { CONGRATS } from '@/features/modals/Notification/constants';
import { Body2, NotificationPopup } from '@/shared/components';

type TProps = {
  onClose: () => void;
};

const TITLE = 'Помет добавлен';
const SUCCESS_ADDED = 'Помет успешно добавлен.';

export const AddLitterNotificationModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Body2 weight="SB">{CONGRATS}</Body2>
    <p>{SUCCESS_ADDED}</p>
  </NotificationPopup>
);
