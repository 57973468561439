import { HOME_PATHS } from '@/app/navigation';
import { useGoogleAuthMutation } from '@/entities/auth-controller';
import { useTokens } from '@/shared/hooks';
import { GoogleIcon } from '@/app/icons';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

export const GoogleSignIn = () => {
  const [googleAuthMutation] = useGoogleAuthMutation();
  const { updateTokens } = useTokens();
  const navigate = useNavigate();
  const handleGoogleAuth = async (credentialResponse: TokenResponse) => {
    try {
      const { token: accessToken, refreshToken } = await googleAuthMutation(credentialResponse).unwrap();
      updateTokens(accessToken, refreshToken);
    } catch (e) {
      console.error(e);
    } finally {
      navigate(HOME_PATHS.home);
    }
  };

  const login = useGoogleLogin({
    flow: 'implicit',
    onSuccess: (credentialResponse: TokenResponse) => {
      handleGoogleAuth(credentialResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  return <GoogleIcon onClick={() => login()} />;
};
