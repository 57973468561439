import { clearStorage, getStorageItem, setStorageItem, StorageKeys } from '@/shared/utils/storage';
import { formatTime } from '@/shared/utils';
import { Body2 } from '@/shared/components';
import { useTimer } from '@/shared/hooks';
import { useEffect } from 'react';
import { theme } from '@/app/theme';

const INITIAL_TIMER = 180;
const currentDateInSeconds = () => Math.round(new Date().getTime() / 1000);

const getDiffTimeInSeconds = () => {
  const storageTimer = Number(getStorageItem(StorageKeys.PIN_RESEND));
  if (storageTimer) {
    const diff = INITIAL_TIMER - (currentDateInSeconds() - storageTimer);
    return diff <= 0 ? 0 : diff;
  }
  return 0;
};

export const useResendPinTimer = () => {
  const { timeInSeconds, setTimeInSeconds, startTimer } = useTimer(getDiffTimeInSeconds());

  const handleTimer = (callback: () => void) => {
    const storageTimer = Number(getStorageItem(StorageKeys.PIN_RESEND));
    if (storageTimer) {
      const diff = getDiffTimeInSeconds();
      setTimeInSeconds(diff);
    } else {
      setStorageItem({ key: StorageKeys.PIN_RESEND, value: String(currentDateInSeconds()) });
      setTimeInSeconds(INITIAL_TIMER);
      callback();
    }
  };

  const ResendTimerJSX = () => (
    <Body2 styles={{ color: theme.palette.neutral[3] }}>Получить новый код через {formatTime(timeInSeconds)}</Body2>
  );

  useEffect(() => {
    if (!timeInSeconds) {
      clearStorage();
    } else {
      startTimer();
    }
  }, [startTimer, timeInSeconds]);

  return { handleTimer, ResendTimerJSX, timeInSeconds };
};
