import { baseApi } from '@/app/core';

export type TImageInfoDto = {
  id: string;
  url: string;
};

const FILE_PATH = 'file';

export const fileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFile: builder.mutation<TImageInfoDto, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `${FILE_PATH}/addImageFile`,
          method: 'POST',
          data: formData,
        };
      },
    }),
    sendFiles: builder.mutation<TImageInfoDto[], File[]>({
      query: (data) => {
        const formData = new FormData();
        data.forEach((item) => {
          formData.append('files', item);
        });
        return {
          url: `${FILE_PATH}/addImageFiles`,
          method: 'POST',
          data: formData,
        };
      },
    }),
  }),
});

export const { useSendFileMutation, useSendFilesMutation } = fileApi;
