import { CONGRATS } from '@/features/modals/Notification/constants';
import { NotificationPopup } from '@/shared/components';
import { Body2, Headline6 } from '@/shared/ui';

type TProps = {
  onClose: () => void;
  text: string | null;
};

const TITLE = 'Товар опубликован';

export const AddPetСardNotificationModal = ({ onClose, text }: TProps) => {
  return (
    <NotificationPopup open title={TITLE} onCancel={onClose}>
      <Headline6>{CONGRATS}</Headline6>
      <Body2 className="flex">
        {'Карточка питомца '}
        {text}
        {' успешно опубликована для продажи.'}
      </Body2>
    </NotificationPopup>
  );
};
