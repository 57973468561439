import { CONGRATS } from '@/features/modals/Notification/constants';
import { NotificationPopup } from '@/shared/components';
import { Body2, Headline6 } from '@/shared/ui';

type TProps = {
  onClose: () => void;
  text: string | null;
};

const TITLE = 'Питомник добавлен';

export const AddNurseryNotificationModal = ({ onClose, text }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Headline6>{CONGRATS}</Headline6>
    <Body2 className="flex">
      Питомник <Headline6 className="font-semibold mx-1">{`"${text}"`}</Headline6> успешно добавлен
    </Body2>
  </NotificationPopup>
);
