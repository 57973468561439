import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimer = (startTime: number) => {
  const [timeInSeconds, setTimeInSeconds] = useState<number>(startTime);
  const [countDown, setCountDown] = useState<boolean>(false);
  const intervalId = useRef<NodeJS.Timer>();

  const startTimer = useCallback(() => {
    setCountDown(true);
  }, []);

  const stopTimer = useCallback(() => setCountDown(false), []);

  const resetTimer = useCallback(() => {
    stopTimer();
    setTimeInSeconds(startTime);
  }, [startTime, stopTimer]);

  useEffect(() => {
    if (countDown && timeInSeconds) {
      intervalId.current = setInterval(() => {
        setTimeInSeconds((prev) => prev - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId.current);
        stopTimer();
      };
    }
  }, [timeInSeconds, stopTimer, countDown]);

  return {
    timeInSeconds,
    setTimeInSeconds,
    startTimer,
    stopTimer,
    resetTimer,
  };
};
