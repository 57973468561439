export const size = {
  tablet: '768px',
  mobile: '360px',
  tabletMaxWidth: '1120px',
};

export const device = {
  tablet: `(min-width: ${size.tablet}) and (max-width: ${size.tabletMaxWidth})`,
  mobile: `(max-width: 767px)`,
};
