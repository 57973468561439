import { Outlet, useParams } from 'react-router-dom';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Loader } from '@/app/icons';
import { ACCOUNT_LINKS, ACCOUNT_TABS } from '@/app/navigation';
import { FlexPage } from '@/shared/containers';
import { Breadcrumb } from '@/shared/components';

export const useGetBreadcrumbs = () => {
  const { goodsId, accountId } = useParams();
  const { data, isFetching } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  if (!goodsId) {
    return [];
  }

  return [
    {
      title: 'Шоурум',
      path: `${ACCOUNT_LINKS.account}/${accountId}/${ACCOUNT_TABS.showroom}`,
    },
    {
      title: isFetching ? <Loader /> : `${data?.animal.fullName} ${data?.animal.nursery.nameEn}`,
    },
  ];
};

export const AccountShowroom = () => {
  const items = useGetBreadcrumbs();

  return (
    <FlexPage>
      <Breadcrumb items={items} />
      <Outlet />
    </FlexPage>
  );
};
