import { Tabs as AntTabs, TabsProps } from 'antd';
import { Tab } from 'rc-tabs/es/interface';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import cn from 'classnames';

export type TTabsProps = Omit<TabsProps, 'items'> & {
  items: (Tab & { isHidden?: boolean })[];
};

export const Tabs = ({ items, className, ...props }: TTabsProps) => {
  const navigate = useNavigate();
  const onTabClick = (route: string) => {
    navigate(route);
  };

  const visibleItems = useMemo(() => items.filter(({ isHidden }) => !isHidden), [items]);

  return (
    <AntTabs
      className={cn(styles['custom-tabs'], { 'has-children': !!items[0].children }, className)}
      onTabClick={onTabClick}
      items={visibleItems}
      {...props}
    />
  );
};
