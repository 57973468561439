import { ResetPasswordModal } from './ResetPasswordModal';
import { PasswordCreate, passwordSchema } from '@/features/password';
import { FormProvider, useForm } from 'react-hook-form';
import { TChangePassword, useChangePasswordMutation } from '@/entities/auth-controller';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { HOME_PATHS } from '@/app/navigation';

const TITLE = 'Смена пароля';
const NEXT = 'Далее';

type TProps = {
  onClose: () => void;
  onSubmit: () => void;
  token: string;
};

export const ChangePasswordModal = ({ onClose, token, onSubmit }: TProps) => {
  const navigate = useNavigate();

  const formMethods = useForm<TChangePassword>({
    defaultValues: {
      token,
    },
    resolver: zodResolver(
      z
        .object({
          token: z.string(),
        })
        .and(passwordSchema),
    ),
  });

  const { handleSubmit } = formMethods;

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onSubmitHandler = async (data: TChangePassword) => {
    try {
      await changePassword(data).unwrap();
      navigate(HOME_PATHS.home);
      onSubmit();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ResetPasswordModal
      title={TITLE}
      onCancel={onClose}
      okButtonProps={{ children: NEXT, loading: isLoading, onClick: handleSubmit(onSubmitHandler) }}
    >
      <FormProvider {...formMethods}>
        <PasswordCreate />
      </FormProvider>
    </ResetPasswordModal>
  );
};
