import { useCallback, useEffect, useRef } from 'react';
import { InputRef } from 'antd';
import { InputComponent, TInputProps } from '@/shared/ui';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import IMask from 'imask';
import cn from 'classnames';

type TProps<R extends FieldValues> = TInputProps<R> & {
  mask?: string;
};

export const PhoneInput = <R extends FieldValues>({
  mask = '+375(00)000-00-00',
  controllerProps,
  ...props
}: TProps<R>) => {
  const inputRef = useRef<InputRef | null>(null);
  const { setValue } = useFormContext();

  const onChange = useCallback(
    (value: string) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(controllerProps.name, value, { shouldDirty: true }),
    [controllerProps.name, setValue],
  );

  useEffect(() => {
    if (inputRef.current && inputRef.current.input) {
      const maskInstance = IMask(inputRef.current.input, { mask, lazy: false });
      // to set the initial value of input, that will trigger once
      onChange(maskInstance.value);

      maskInstance.on('accept', () => {
        if (onChange) {
          onChange(maskInstance.value);
        }
      });

      // Clean up on unmount
      return () => {
        maskInstance.destroy();
      };
    }
  }, [mask, onChange]);

  return (
    <Controller
      render={({ field: { ref, ...rest }, fieldState: { error, isDirty } }) => (
        <InputComponent
          className={cn({ 'text-neutral4': !isDirty })}
          error={error}
          ref={inputRef}
          {...rest}
          {...props}
        />
      )}
      {...controllerProps}
    />
  );
};
