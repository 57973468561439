import {
  TChangePassword,
  TForgotPassword,
  TJWTTokenResponse,
  TLoginRequest,
  TRestorePin,
  TSignupRequest,
  TVKSilentTokenData,
} from './types';
import { TokenResponse } from '@react-oauth/google';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { baseApi } from '@/app/core';

const AUTH_PATH = 'auth';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<void, TSignupRequest>({
      query: (data) => ({
        url: `${AUTH_PATH}/signup`,
        method: 'POST',
        data,
      }),
    }),
    checkEmail: builder.query<boolean, string>({
      query: (email) => ({
        url: `${AUTH_PATH}/emailExist`,
        method: 'GET',
        params: {
          email,
        },
      }),
    }),
    confirmEmail: builder.mutation<TJWTTokenResponse, string>({
      query: (confirmString) => ({
        url: `${AUTH_PATH}/confirmEmail`,
        method: 'GET',
        params: {
          confirmString,
        },
      }),
    }),
    emailSignIn: builder.mutation<TJWTTokenResponse, TLoginRequest>({
      query: (data) => ({
        url: `${AUTH_PATH}/signin`,
        method: 'POST',
        data,
      }),
    }),
    vkAuth: builder.mutation<TJWTTokenResponse, TVKSilentTokenData>({
      query: (body) => ({
        url: `${AUTH_PATH}/vk`,
        method: 'POST',
        data: body,
      }),
    }),
    forgotPassword: builder.mutation<string, TForgotPassword>({
      query: (data) => ({
        url: `${AUTH_PATH}/forgotPassword`,
        method: 'POST',
        data,
      }),
    }),
    confirmPin: builder.mutation<string, TRestorePin>({
      query: (data) => ({
        url: `${AUTH_PATH}/confirmPin`,
        method: 'POST',
        data,
      }),
    }),
    changePassword: builder.mutation<string, TChangePassword>({
      query: (data) => ({
        url: `${AUTH_PATH}/changePassword`,
        method: 'POST',
        data,
      }),
    }),
    googleAuth: builder.mutation<TJWTTokenResponse, TokenResponse>({
      query: (body) => ({
        url: `${AUTH_PATH}/google`,
        method: 'POST',
        data: body,
      }),
    }),
    fbAuth: builder.mutation<TJWTTokenResponse, ReactFacebookLoginInfo>({
      query: (body) => ({
        url: `${AUTH_PATH}/fb`,
        method: 'POST',
        data: body,
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useLazyCheckEmailQuery,
  useVkAuthMutation,
  useGoogleAuthMutation,
  useFbAuthMutation,
  useEmailSignInMutation,
  useConfirmEmailMutation,
  useForgotPasswordMutation,
  useConfirmPinMutation,
  useChangePasswordMutation,
} = authApi;
