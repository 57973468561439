import { device } from '@/app/theme/device';
import { TAnimalRequestDto, useGetAnimalQuery, usePatchAnimalMutation } from '@/entities/animal-controller';
import { useGetCountriesQuery } from '@/entities/country-controller';
import { useGetTribalTypesQuery } from '@/entities/tribal-type-controller';
import { Checkbox, DatePicker, Drag, Dropdown, Input, Popup, Select, TextArea } from '@/shared/components';
import { RowWrapper } from '@/shared/containers';
import { zodResolver } from '@hookform/resolvers/zod';
import { MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';

const TITLE = 'Редактировать информацию';
const LOAD_PEDIGREE = 'Загрузить фото родословной';
const DESCRIPTION = 'Описание';
const PET_NAME = 'Домашняя кличка';
const BIRTH_COUNTRY = 'Страна рождения';
const LIVE_COUNTRY = 'Страна проживания';
const DEATH_DAY = 'Дата смерти';
const CANCEL = 'Отменить';
const SAVE_CHANGES = 'Сохранить изменения';
const TRIBAL_CLASS = 'Племенной класс';
const EDIT_FIELDS = 'Изменить нередактируемые поля';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(6)};

  .ant-dropdown-trigger {
    margin-left: auto;
  }
`;

const Row = styled(RowWrapper)`
  align-items: center;
  > div {
    width: 100%;
    &:first-child {
      max-width: 400px;
    }
    &:last-child {
      max-width: 353px;
    }
  }

  @media screen and ${device.mobile} {
    > div {
      &:first-child,
      &:last-child {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

const FORM_FIELDS = {
  pedigreeId: 'pedigree',
  liveCountryId: 'liveCountryId',
  birthCountryId: 'birthCountryId',
  description: 'description',
  shortName: 'shortName',
  tribalTypes: 'tribalTypes',
  dateDead: 'dateDead',
  isShowDeadDate: 'isShowDeadDate',
} as const;

const validation = z.object({
  [FORM_FIELDS.pedigreeId]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array()
    .optional(),
  [FORM_FIELDS.description]: z.string().optional(),
  [FORM_FIELDS.shortName]: z.string().optional(),
  [FORM_FIELDS.birthCountryId]: z.number(),
  [FORM_FIELDS.liveCountryId]: z.number(),
  [FORM_FIELDS.tribalTypes]: z.number().array(),
  [FORM_FIELDS.dateDead]: z.string().optional(),
});

type TProps = {
  onCancel: () => void;
  defaultValues: Partial<TAnimalRequestDto>;
  animalId: string;
};

export const EditAnimalInformationModal = ({ onCancel, defaultValues, animalId }: TProps) => {
  const { data: countries, isLoading: isLoadingCountries } = useGetCountriesQuery();
  const { data } = useGetAnimalQuery(animalId);
  const { data: tribalTypes = [], isLoading: isLoadingTribalTypes } = useGetTribalTypesQuery();
  const [updateAnimal, { isLoading: isSaving }] = usePatchAnimalMutation();
  const [isShowDeathDate, setIsShowDeathDate] = useState(false);

  const handleCheckboxChange = (value: boolean | CheckboxChangeEvent) => {
    typeof value === 'boolean' ? setIsShowDeathDate(value) : setIsShowDeathDate(value.target.checked);
  };

  const disabledDeathDate = (current: Dayjs) => current.isBefore(data?.dateBirth) || current.isAfter(dayjs());

  const formMethods = useForm<TAnimalRequestDto>({
    defaultValues,
    resolver: zodResolver(validation),
  });

  const { handleSubmit } = formMethods;

  const onSaveAnimal = async (data: Partial<TAnimalRequestDto>) => {
    try {
      const { pedigree } = data as { pedigree?: { id: string; url: string }[] };
      const updatedData = {
        ...data,
        [FORM_FIELDS.pedigreeId]: pedigree && pedigree[0]?.id,
      };
      await updateAnimal({ id: animalId, ...updatedData }).unwrap();
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit-fields',
      label: EDIT_FIELDS,
    },
  ];

  return (
    <Popup
      open
      title={TITLE}
      width={785}
      cancelButtonProps={{
        children: CANCEL,
        onClick: onCancel,
      }}
      okButtonProps={{
        children: SAVE_CHANGES,
        onClick: handleSubmit(onSaveAnimal),
        loading: isSaving,
      }}
      onCancel={onCancel}
    >
      <FormProvider {...formMethods}>
        <Form>
          <Dropdown menu={{ items }} placement="bottomRight" />
          <Controller
            render={({ field: { onChange, value } }) => (
              <Drag label={LOAD_PEDIGREE} maxCount={1} fileList={value} handleChange={onChange} />
            )}
            name={FORM_FIELDS.pedigreeId}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextArea
                {...field}
                labelProps={{ label: DESCRIPTION, isHorizontal: true }}
                maxLength={256}
                style={{ height: 144, resize: 'none' }}
                hasError={!!error}
              />
            )}
            name={FORM_FIELDS.description}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Input {...field} labelProps={{ label: PET_NAME, isHorizontal: true }} hasError={!!error} />
            )}
            name={FORM_FIELDS.shortName}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                hasError={!!error}
                labelProps={{ label: BIRTH_COUNTRY, isHorizontal: true }}
                options={countries}
                loading={isLoadingCountries}
              />
            )}
            name={FORM_FIELDS.birthCountryId}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                hasError={!!error}
                labelProps={{ label: LIVE_COUNTRY, isHorizontal: true }}
                options={countries}
                loading={isLoadingCountries}
              />
            )}
            name={FORM_FIELDS.liveCountryId}
          />
          <Row>
            <Controller
              render={({ field }) => (
                <Checkbox
                  label={DEATH_DAY}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value);
                    handleCheckboxChange(value);
                  }}
                />
              )}
              name={FORM_FIELDS.isShowDeadDate}
            />
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  onChange={onChange}
                  value={value}
                  errorMsg={error?.message}
                  disabled={!isShowDeathDate}
                  disabledDate={disabledDeathDate}
                />
              )}
              name={FORM_FIELDS.dateDead}
            />
          </Row>
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select.Badge
                valueIds={value}
                onChange={onChange}
                options={tribalTypes}
                loading={isLoadingTribalTypes}
                hasError={!!error}
                labelProps={{ label: TRIBAL_CLASS, isHorizontal: true }}
              />
            )}
            name={FORM_FIELDS.tribalTypes}
          />
        </Form>
      </FormProvider>
    </Popup>
  );
};
