import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertErrorData {
  code: string;
  message: string;
  withoutTimeout?: boolean;
}

export const ERROR_SLICE_NAME = 'error';

export interface ErrorState {
  error?: AlertErrorData;
  subscriptionError?: boolean;
}

export const initialState: ErrorState = {};

export const errorSlice = createSlice({
  name: ERROR_SLICE_NAME,
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<AlertErrorData>) => {
      state.error = payload;
    },
    resetError: (state) => {
      state.error = undefined;
    },
    resetSubscriptionError: (state) => {
      state.subscriptionError = initialState.subscriptionError;
    },
  },
});

export const { setError, resetError, resetSubscriptionError } = errorSlice.actions;

export const errorReducer = errorSlice.reducer;
