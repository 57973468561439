import { GRID_MODULE } from './constants';
import { Spacing, SpacingArgument } from './types';

export const spacing: Spacing = (...args: SpacingArgument[]) => {
  if (!args || args.length > 4) {
    return '';
  }

  const values = args.map((item) => {
    if (typeof item === 'string') {
      return item;
    }

    return `${item * GRID_MODULE}px`;
  });

  return values.join(' ');
};
