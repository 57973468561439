import cn from 'classnames';
import {
  GoodsCharacteristics,
  GoodsDelivery,
  GoodsDescription,
  GoodsFooter,
  GoodsImagesView,
  GoodsSeller,
  GoodsTitle,
} from '@/features/goods';
import { Collapse } from '@/shared/components';
import { AnimalPedigreeInfo } from '@/widgets/animal';
import { FlexPage } from '@/shared/containers';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { MarketplaceGoodsList } from '@/features/marketplace';

export const MobileView = ({ data }: { data: TGoodDtoExt }) => {
  const {
    animalExt: {
      images,
      description,
      nursery: {
        account: { id: accountId },
      },
    },
    city,
    canEdit,
  } = data;

  return (
    <>
      <FlexPage className={cn({ 'pb-[120px]': !canEdit }, { 'pb-[70px]': canEdit })}>
        <GoodsTitle data={data} />
        <GoodsImagesView images={images} />
        {city && <GoodsDelivery city={city.name} />}
        {!canEdit && <GoodsSeller accountId={accountId} good={data} />}
        <Collapse
          defaultActiveKey={1}
          items={[
            { key: 1, label: 'Описание', children: <GoodsDescription description={description} /> },
            { key: 2, label: 'Характеристики', children: <GoodsCharacteristics good={data} /> },
            { key: 3, label: 'Родословная', children: <AnimalPedigreeInfo animal={data.animalExt} /> },
          ]}
        />
        <MarketplaceGoodsList />
      </FlexPage>
      <GoodsFooter goodData={data} />
    </>
  );
};
