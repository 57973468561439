import { Loader } from '@/app/icons';
import { SelectIcon } from '@/app/icons/SelectIcon';
import { device } from '@/app/theme/device';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import { Select as AntSelect, SelectProps } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import styled, { css } from 'styled-components';
import { AsyncSelect } from './AsyncSelect';
import { BadgeSelect } from './BadgeSelect';

export type TSelectProps<ValueType, OptionType extends DefaultOptionType> = SelectProps<ValueType, OptionType> & {
  loading?: boolean;
  errorMsg?: string;
  hasError?: boolean;
  width?: string | number;
  labelProps?: TLabelProps;
};

const StyledSelect = styled.div`
  .ant-select {
    width: 100%;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding-inline-end: unset !important;
      max-width: calc(100% - 24px);
    }
  }
  .ant-select-status-error {
    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.palette.primary[3]};
    }
  }
`;

const StyledSelectIcon = styled(SelectIcon)<{ $error: boolean }>`
  color: ${({ theme }) => theme.palette.neutral[2]};
  @media screen and ${device.tablet} {
    width: 16px;
    height: 16px;
  }
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}
`;

export const Select = <ValueType, OptionType extends DefaultOptionType>({
  loading,
  errorMsg,
  hasError,
  labelProps,
  value,
  width,
  ...props
}: TSelectProps<ValueType, OptionType>) => {
  const status = errorMsg || hasError ? 'error' : undefined;

  return (
    <LabelWrapper style={{ width }} {...labelProps}>
      <StyledSelect>
        <AntSelect
          size="large"
          status={status}
          showSearch
          optionFilterProp="label"
          suffixIcon={loading ? <Loader /> : <StyledSelectIcon $error={!!status} />}
          loading={loading}
          value={loading ? null : value === 0 ? null : value}
          {...props}
        />
        {errorMsg && <ErrorMessage message={errorMsg} />}
      </StyledSelect>
    </LabelWrapper>
  );
};

Select.Badge = BadgeSelect;
Select.Async = AsyncSelect;
