import { Button, TButtonProps } from '@/shared/ui';
import { useMedia, useModal } from '@/shared/hooks';
import { Body2, Divider, Popup } from '@/shared/components';
import { Flex } from 'antd';
import { EmailShareButton, FacebookShareButton, TelegramShareButton, VKShareButton } from 'react-share';
import { CopyLinkIcon, EmailShareIcon, FacebookShareIcon, TelegramShareIcon, VKShareIcon } from '@/app/icons';
import { copyClipboard } from '@/shared/utils';
import { ReactNode } from 'react';

const ButtonContent = ({ icon, text, onClick }: { icon: ReactNode; text: string; onClick?: () => void }) => (
  <Flex gap={16} align="center" onClick={onClick} style={{ cursor: 'pointer' }}>
    {icon}
    <Body2 weight="SB">{text}</Body2>
  </Flex>
);

type TProps = {
  shareProps: {
    text: string;
    title: string;
  };
  buttonProps?: TButtonProps & {
    text?: string;
  };
};

export const ShareButton = ({ shareProps, buttonProps }: TProps) => {
  const { isOpen, close, open } = useModal();
  const { isDesktop } = useMedia();
  const shareUrl = window.location.href;

  const mobileShare = async () => {
    const shareData = {
      shareProps,
      url: shareUrl,
    };
    if (navigator.canShare && navigator.canShare(shareData) && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (e) {
        console.error(e);
      }
    } else {
      open();
    }
  };

  return (
    <>
      <Button type="default" onClick={isDesktop ? open : mobileShare} {...buttonProps}>
        {buttonProps?.text || 'Поделиться карточкой'}
      </Button>
      {isOpen && (
        <Popup open maskClosable onCancel={close} size="SMALL" title="Поделиться..." withFooterDivider={false}>
          <Flex vertical gap={24}>
            <Divider />
            <VKShareButton url={shareUrl}>
              <ButtonContent icon={<VKShareIcon />} text="Вконтакте" />
            </VKShareButton>
            <FacebookShareButton url={shareUrl}>
              <ButtonContent icon={<FacebookShareIcon />} text="Facebook" />
            </FacebookShareButton>
            <TelegramShareButton url={shareUrl}>
              <ButtonContent icon={<TelegramShareIcon />} text="Telegram" />
            </TelegramShareButton>
            <EmailShareButton url={shareUrl}>
              <ButtonContent icon={<EmailShareIcon />} text="Электронная почта" />
            </EmailShareButton>
            <ButtonContent onClick={() => copyClipboard(shareUrl)} icon={<CopyLinkIcon />} text="Копировать ссылку" />
          </Flex>
        </Popup>
      )}
    </>
  );
};
