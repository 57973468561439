import { useIntersection } from '@/shared/hooks';
import { Flex } from 'antd';
import { Loader } from '@/app/icons';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { TCommonPageResponse } from '@/entities';
import { Headline5 } from '@/shared/ui';

type TProps<R> = {
  setPage: Dispatch<SetStateAction<number>>;
  data?: TCommonPageResponse & {
    content: R[];
  };
  isFetching: boolean;
  title?: string;
  renderedItem: (content: R[]) => ReactNode;
};

export const InfinityContainer = <R extends { id: string | number }>({
  setPage,
  data,
  title,
  isFetching,
  renderedItem,
}: TProps<R>) => {
  const { observerRef } = useIntersection<HTMLDivElement>({
    threshold: 0,
    onNext: () => {
      setPage((prevState) => prevState + 1);
    },
  });

  if (!data) {
    return <Loader />;
  }

  const isMoreElements = data.totalElements > data.content.length;

  return (
    <Flex vertical>
      <Flex vertical gap={16}>
        {title && <Headline5>{title}</Headline5>}
        {renderedItem(data.content)}
      </Flex>
      <Flex vertical gap={16} style={{ marginTop: 16 }}>
        {isFetching && (
          <Flex vertical justify="center" className="w-full h-full">
            <Loader />
          </Flex>
        )}
        {isMoreElements && (
          <div
            className="h-[32px] w-full bg-transparent text-transparent"
            ref={!isFetching && isMoreElements ? observerRef : null}
          >
            some content
          </div>
        )}
      </Flex>
    </Flex>
  );
};
