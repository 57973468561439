import { TPostReadDto, useGetPostsQuery } from '@/entities/post-controller';
import { Loader } from '@/app/icons';
import { Images } from '@/shared/components';
import { useEffect, useState } from 'react';
import { PostModal } from '@/widgets/post';
import { useTypedDispatch } from '@/app/store';
import { updateAccount } from '@/entities/user-controller';
import { queries } from '@/shared/utils';

type TProps = {
  accountId: string;
};

export const Pawsgram = ({ accountId }: TProps) => {
  const { data } = useGetPostsQuery({
    page: 1,
    size: 20,
    query: queries.equal('account.id', accountId),
  });

  const [post, setPost] = useState<TPostReadDto | null>(null);
  const dispatch = useTypedDispatch();

  const resetPost = () => setPost(null);

  useEffect(() => {
    if (data) {
      dispatch(updateAccount(accountId, { postsCount: data.totalElements }));
    }
  }, [accountId, dispatch, data]);

  useEffect(() => {
    return () => {
      resetPost();
    };
  }, [accountId]);

  if (!data) {
    return <Loader />;
  }

  const posts = data.content;

  return (
    <>
      <Images
        images={posts.map((item) => ({
          src: item.images[0].url,
          showMulti: item.images.length > 1,
          onClick: () => setPost(item),
        }))}
      />
      {post && <PostModal onCancel={() => setPost(null)} post={post} />}
    </>
  );
};
