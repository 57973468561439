import { Drag, LIST_TYPE, Popup } from '@/shared/components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const TITLE = 'Добавить медиа файл';

const FORM_FIELDS = {
  images: 'images',
} as const;

const validation = z.object({
  [FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array()
    .nonempty(),
});

type TSchema = z.infer<typeof validation>;

type TProps = {
  onSubmit: (data: TSchema) => void;
  onCancel: () => void;
};

export const AddMediaModal = ({ onCancel, onSubmit }: TProps) => {
  const formMethods = useForm<TSchema>({
    defaultValues: {
      [FORM_FIELDS.images]: [],
    },
    resolver: zodResolver(validation),
  });

  const { handleSubmit } = formMethods;

  return (
    <Popup
      title={TITLE}
      open
      okButtonProps={{ children: 'Далее', onClick: handleSubmit(onSubmit) }}
      cancelButtonProps={{ children: 'Отменить', onClick: onCancel }}
      onCancel={onCancel}
    >
      <FormProvider {...formMethods}>
        <Controller
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Drag maxCount={6} handleChange={onChange} error={!!error} fileList={value} typeList={LIST_TYPE.IMAGE} />
          )}
          name={FORM_FIELDS.images}
        />
      </FormProvider>
    </Popup>
  );
};
