import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

export const USER_SLICE_NAME = 'userSlice';

export enum ROLES {
  ADMIN = 'ADMIN',
  BREEDER = 'BREEDER',
}

type TSecurityState = {
  roles: ROLES[];
  accountId?: string;
};

const initialState: TSecurityState = { roles: [] };

export const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setUserRoles: (state, { payload }: PayloadAction<string>) => {
      const decoded: { roles: ROLES[]; sub: string } = jwtDecode(payload);
      state.roles = decoded.roles;
      state.accountId = decoded.sub;
    },
    resetUserRoles: () => initialState,
  },
});

export const { setUserRoles, resetUserRoles } = userSlice.actions;

export const userReducer = userSlice.reducer;
