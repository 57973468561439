import styled from 'styled-components';

export const ProfileHeader = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(8)};

  > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};
  }
`;
