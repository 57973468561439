import styled, { css } from 'styled-components';
import { LogoWord } from '@/app/icons';
import { theme } from '@/app/theme';
import { ColumnWrapper } from '@/shared/containers';
import { useNavMenu } from './hooks/useNavMenu';
import { useMedia } from '@/shared/hooks/useMedia';
import { LogoItem } from '../LogoItem';

const Wrapper = styled.div<{ $isExpanded: boolean; $isTablet: boolean }>`
  height: 100svh;
  width: 270px;
  margin-right: -158px;
  background-color: ${({ theme }) => theme.palette.primary[1]};
  display: flex;
  flex-direction: column;
  padding: 32px;
  transition: all 0.2s ease-out;

  ${({ $isTablet }) =>
    $isTablet &&
    css`
      width: 200px;
      padding: 16px;
      margin-right: -136px;
    `}

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      margin-right: 0;
    `}
`;

const NavItemsWrapper = styled(ColumnWrapper)`
  flex: 1;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(14)};
`;

const Logotype = styled(LogoWord)<{ $isExpanded: boolean }>`
  ${({ $isExpanded }) =>
    $isExpanded
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          width: 0;
          opacity: 0;
          visibility: hidden;
        `};
  transition: all 0.2s ease-out;
`;

export const LeftSidebar = () => {
  const { navMenuItems, ExpandItem, isExpanded } = useNavMenu();
  const { isTablet } = useMedia();

  return (
    <Wrapper $isTablet={isTablet} $isExpanded={isExpanded}>
      <LogoWrapper>
        <LogoItem />
        <Logotype $isExpanded={isExpanded} width={isTablet ? 100 : 130} color={theme.palette.neutral[8]} />
      </LogoWrapper>
      <NavItemsWrapper>{navMenuItems}</NavItemsWrapper>
      <ExpandItem />
    </Wrapper>
  );
};
