import { List as AntList, ListProps } from 'antd';
import React, { ReactNode } from 'react';

type TProps<R> = ListProps<R> & {
  customItem: (item: R) => ReactNode;
  emptyText?: string | ReactNode;
};

const { Item } = AntList;

export const List = <R extends { id: number | string }>({ customItem, dataSource, emptyText, ...props }: TProps<R>) =>
  dataSource?.length ? (
    <AntList
      className="overflow-x-hidden"
      dataSource={dataSource}
      renderItem={(item) => <Item key={item.id}>{customItem(item)}</Item>}
      {...props}
    />
  ) : (
    emptyText
  );
