import { FC, PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { GlobalStyles } from './globalStyles';
import { Theme, theme } from './theme';
import { useAntToken } from './hooks';
import { ConfigProvider } from 'antd';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { token, components } = useAntToken();

  return (
    <StyledThemeProvider theme={theme as Theme}>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          components,
          token,
        }}
      >
        {children}
      </ConfigProvider>
    </StyledThemeProvider>
  );
};
