import { HOME_PATHS } from '@/app/navigation';
import { useTokens } from '@/shared/hooks';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TVKSilentTokenData, useVkAuthMutation } from '@/entities/auth-controller';
import { Typography } from '@/shared/components';

const CONFIRMATION = 'Подтверждение авторизации VK ID...';

export const VkAuth = () => {
  const location = useLocation();
  const [vkAuthMutation] = useVkAuthMutation();
  const navigate = useNavigate();
  const { updateTokens } = useTokens();

  useEffect(() => {
    const handleVkAuth = async () => {
      const queryParams = new URLSearchParams(location.search);
      const payload = queryParams.get('payload');

      try {
        if (payload) {
          const silentTokenData: TVKSilentTokenData = JSON.parse(decodeURIComponent(payload));
          const { token: accessToken, refreshToken } = await vkAuthMutation(silentTokenData).unwrap();
          updateTokens(accessToken, refreshToken);
        }
      } catch (e) {
        console.error(e);
      } finally {
        navigate(HOME_PATHS.home);
      }
    };

    handleVkAuth();
  }, [updateTokens, location.search, navigate, vkAuthMutation]);

  return <Typography type="20B">{CONFIRMATION}</Typography>;
};
