import { Dropdown as AntDropdown, DropdownProps } from 'antd';
import { DropdownDots } from '@/app/icons/DropdownDots';
import cn from 'classnames';

type TProps = DropdownProps;

export const Dropdown = ({ className, ...props }: TProps) => (
  <AntDropdown trigger={['click']} className={cn('cursor-pointer', className)} {...props}>
    <DropdownDots />
  </AntDropdown>
);
