import { baseApi } from '@/app/core';
import { TCommonPageResponse } from '@/entities';
import { TImageInfoDto } from '@/entities/file-controller';
import { TAnimalInfoDto } from '@/entities/animal-controller';
import { TNurseryDto } from '@/entities/nursery-controller';

export enum SEARCH_TYPE {
  USER = 'user',
  ANIMAL = 'animal',
  NURSERY = 'nursery',
}

export type TUserShortDto = {
  id: string;
  login: string;
  surname: string;
  name: string;
  avatar?: TImageInfoDto;
  breeder: boolean;
  accountIds: string[];
};

export type TSearchResultDto = {
  id: string;
  text: string;
  createdAt: string;
  type: SEARCH_TYPE;
  user: TUserShortDto | null;
  animal: TAnimalInfoDto | null;
  nursery: TNurseryDto | null;
};

type TPageSearchResultDto = TCommonPageResponse & {
  content: TSearchResultDto[];
};

type TQuery = {
  page: number;
  query: string;
  type: SEARCH_TYPE | null;
  signal: AbortController['signal'];
};

const searchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<TPageSearchResultDto, TQuery>({
      query: ({ signal, ...params }) => ({
        url: 'search',
        signal,
        params,
      }),
    }),
  }),
});

export const { useLazySearchQuery } = searchApi;
