import dayjs, { Dayjs } from 'dayjs';

export const getAge = (birtDate: string | Dayjs | Date) => {
  const currentDate = dayjs().startOf('day');
  const diffDate = dayjs(birtDate).startOf('day');

  const diff = currentDate.diff(diffDate, 'day');

  const years = Math.floor(diff / 365);

  if (years >= 1) {
    const months = Math.floor((diff - 365 * years) / 30);
    if (months >= 1) {
      return `${years} год(а) ${months} месяца(ев)`;
    }
    return `${years} год(а)`;
  }

  const months = Math.floor(diff / 30);
  if (months >= 1) {
    return `${months} месяца(ев)`;
  }

  return `${diff} дня(ей)`;
};
