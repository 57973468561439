import { Popup } from '@/shared/components';
import { EmailSignIn } from '@/features/login';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useRef } from 'react';
import { RegistrationButton, ForgetPasswordButton } from '@/features/buttons';

const TITLE = 'Войти';

type TProps = {
  onClose: () => void;
};

const StyledLoginModal = styled(Popup)`
  .ant-modal-footer {
    justify-content: end;
  }
`;
export const LoginModal = ({ onClose }: TProps) => {
  const ref = useRef<{ submit: () => void; isLoading: boolean }>(null);

  const onSubmit = () => {
    ref.current?.submit();
  };

  return (
    <StyledLoginModal
      open
      withFooterDivider={false}
      title={TITLE}
      size="SMALL"
      maskClosable
      onCancel={onClose}
      okButtonProps={{ children: 'Войти', onClick: onSubmit, loading: ref.current?.isLoading }}
    >
      <EmailSignIn showBtn={false} ref={ref} />
      <Flex justify="space-between" style={{ marginTop: 16 }}>
        <RegistrationButton onClick={onClose} />
        <ForgetPasswordButton />
      </Flex>
    </StyledLoginModal>
  );
};
