import { Route, Routes } from 'react-router-dom';

import { MainLayout } from '@/widgets/layouts';
import { privateRoutes, publicRoutes, TRoute } from '@/app/navigation';
import { useEffect, useMemo } from 'react';
import { resetUserRoles, selectCurrentAccountId, setUserRoles, useTypedDispatch, useTypedSelector } from '@/app/store';
import { getStorageItem, StorageKeys } from '@/shared/utils/storage';

import './global.scss';

const renderRoutes = (routes: TRoute[]) => {
  return routes.map((route) => {
    const { nestedRoutes } = route;

    if (nestedRoutes && nestedRoutes.length) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Route key={route.path} {...route}>
          {renderRoutes(nestedRoutes)}
        </Route>
      );
    }

    return <Route key={route.path} {...route} />;
  });
};

export const App = () => {
  const dispatch = useTypedDispatch();
  const currentAccountId = useTypedSelector(selectCurrentAccountId);
  const accessToken = getStorageItem(StorageKeys.ACCESS_TOKEN);

  const availableRoutes = currentAccountId || accessToken ? privateRoutes : publicRoutes;

  const routes = useMemo(() => {
    return renderRoutes(availableRoutes);
  }, [availableRoutes]);

  useEffect(() => {
    dispatch(accessToken ? setUserRoles(accessToken) : resetUserRoles());
  }, [dispatch, accessToken]);

  return (
    <Routes>
      <Route element={<MainLayout key={currentAccountId || 'guest-key'} />}>{routes}</Route>
    </Routes>
  );
};
