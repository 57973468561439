import { TAccountDto, TSubscriptionInfoDto, TUserDto, TUserUpdateDto } from '@/entities/user-controller/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/app/core';

const USER_PATH = 'user';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['CURRENT_USER', 'ACCOUNT', 'RECOMMENDATIONS', 'USER_ACCOUNTS'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<TUserDto, void>({
      query: (query) => ({
        url: `${USER_PATH}/current`,
        method: 'GET',
        params: { query },
      }),
      providesTags: ['CURRENT_USER'],
    }),
    updateCurrentUser: builder.mutation<TUserDto, TUserUpdateDto>({
      query: (data) => ({
        url: `${USER_PATH}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['CURRENT_USER'],
    }),
    getAccount: builder.query<TAccountDto, string>({
      query: (accountId) => ({
        url: `${USER_PATH}/getAccount`,
        method: 'GET',
        params: { accountId },
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => (result ? [{ type: 'ACCOUNT', id: result.id }] : []),
    }),
    getForeignUser: builder.query<TUserDto, string>({
      query: (accountId) => ({
        url: `${USER_PATH}/foreignUser`,
        method: 'GET',
        params: { accountId },
      }),
    }),
    getUserAccounts: builder.query<TAccountDto[], string>({
      query: (userId) => ({
        url: `${USER_PATH}/accounts`,
        method: 'GET',
        params: { userId },
      }),
      providesTags: ['USER_ACCOUNTS'],
    }),
    postFollowAccount: builder.mutation<string, string>({
      query: (applicantAccountId) => ({
        url: `${USER_PATH}/follow`,
        method: 'POST',
        params: { applicantAccountId },
      }),
      invalidatesTags: (result, error, arg) =>
        !error ? [{ type: 'ACCOUNT', id: arg }, 'RECOMMENDATIONS', 'CURRENT_USER'] : [],
    }),
    putUnfollowAccount: builder.mutation<string, string>({
      query: (applicantAccountId) => ({
        url: `${USER_PATH}/unfollow`,
        method: 'PUT',
        params: { applicantAccountId },
      }),
      invalidatesTags: (result, error, arg) =>
        !error ? [{ type: 'ACCOUNT', id: arg }, 'RECOMMENDATIONS', 'CURRENT_USER'] : [],
    }),
    getRecommendations: builder.query<TSubscriptionInfoDto[], void>({
      query: () => ({
        url: `${USER_PATH}/recommend`,
        method: 'GET',
      }),
      providesTags: ['RECOMMENDATIONS'],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateCurrentUserMutation,
  useLazyGetAccountQuery,
  useLazyGetForeignUserQuery,
  useGetAccountQuery,
  useGetForeignUserQuery,
  useGetUserAccountsQuery,
  usePostFollowAccountMutation,
  usePutUnfollowAccountMutation,
  useGetRecommendationsQuery,
} = userApi;

export const updateAccount = (accountId: string, payload: Partial<TAccountDto>) =>
  userApi.util.updateQueryData('getAccount', accountId, (draft) => ({ ...draft, ...payload }));
