import { SVGProps } from 'react';

export const VKShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 0.5C36.9787 0.5 47.5 11.0213 47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5Z"
      fill="#FBFCFE"
      stroke="#212029"
    />
    <path
      d="M12 23.52C12 18.088 12 15.376 13.68 13.68C15.384 12 18.096 12 23.52 12H24.48C29.912 12 32.624 12 34.32 13.68C36 15.384 36 18.096 36 23.52V24.48C36 29.912 36 32.624 34.32 34.32C32.616 36 29.904 36 24.48 36H23.52C18.088 36 15.376 36 13.68 34.32C12 32.616 12 29.904 12 24.48V23.52Z"
      fill="#212029"
    />
    <path
      d="M24.7688 29.2889C19.2968 29.2889 16.1768 25.5449 16.0488 19.3049H18.8008C18.8888 23.8809 20.9048 25.8169 22.5048 26.2169V19.3049H25.0888V23.2489C26.6648 23.0809 28.3288 21.2809 28.8888 19.2969H31.4648C31.2548 20.3239 30.8352 21.2966 30.2323 22.1541C29.6294 23.0116 28.8562 23.7356 27.9608 24.2809C28.96 24.7782 29.8423 25.4816 30.5498 26.3449C31.2572 27.2081 31.7735 28.2115 32.0648 29.2889H29.2248C28.6168 27.3929 27.0968 25.9209 25.0888 25.7209V29.2889H24.7768H24.7688Z"
      fill="white"
    />
  </svg>
);
