import { Caption2, CustomImage, TImageProps } from '@/shared/components';
import styled from 'styled-components';

type TProps = {
  image: TImageProps;
};

const StyledDiv = styled.div`
  position: relative;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const BottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  zindex: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary[1]};
  padding: 6px;
  border-radius: 0 0 8px 8px;
  > div {
    color: ${({ theme }) => theme.palette.neutral[8]};
    line-height: 13px;
  }
`;

export const NurseryAnimalCard = ({ image }: TProps) => (
  <StyledDiv className="custom-image">
    <CustomImage
      square
      styles={{
        objectFit: 'cover',
        overflow: 'hidden',
        borderRadius: '8px',
        cursor: 'pointer',
      }}
      {...image}
    />
    <BottomInfo>
      <Caption2>{image.bottomInfo?.title}</Caption2>
      <Caption2 weight="B">{image.bottomInfo?.subTitle}</Caption2>
    </BottomInfo>
  </StyledDiv>
);
