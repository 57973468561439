import { SVGProps } from 'react';

export const Logo = ({ width, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentFill"
      viewBox="0 0 48 48"
      width={width}
      height={width}
      {...props}
    >
      <g>
        <path d="M16.9,23.4c-2.4,0-2.4,1.1-2.4,2.4c0,1.3,0,2.3,2.4,2.3c2.4,0,2.4-1.1,2.4-2.3S19.3,23.4,16.9,23.4z" />
        <path
          d="M40.7,0H7.3C3.3,0,0,3.3,0,7.3v33.5c0,4,3.3,7.3,7.3,7.3h33.5c4,0,7.3-3.3,7.3-7.3V7.3C48,3.3,44.7,0,40.7,0z
		 M32.2,38.6c-5.4,1.2-10,1.2-16.4,0c-6.6-1.4-7.3-5.1-7.3-10.7c0-1.6,0.6-17.7,3.1-18.2c1.6,0,4.5,2.9,6.8,6.3
		c1.5-0.2,3.4-0.3,5.6-0.3c2.1,0,4,0.1,5.6,0.3c2.3-3.4,5-6.3,6.7-6.3h0.3c2.1,0.5,2.8,16.7,2.8,18.2C39.5,33.3,38.6,37,32.2,38.6z"
        />
        <path d="M31.4,23.5c-2.4,0-2.4,1.1-2.4,2.3s0,2.4,2.4,2.4c2.4,0,2.4-1.1,2.4-2.4C33.8,24.5,33.8,23.5,31.4,23.5z" />
      </g>
    </svg>
  );
};
