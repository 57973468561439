import { z } from 'zod';
import { emailRule, selectRule, stringRule } from '@/shared/validation';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export const SHOWROOM_FORM_FIELDS = {
  phone: 'phone',
  animal: 'animal',
  price: 'price',
  currency: 'currency',
  email: 'email',
  country: 'country',
  city: 'city',
  delivery: 'delivery',
} as const;

const schema = z.object({
  [SHOWROOM_FORM_FIELDS.phone]: z
    .string()
    .nonempty()
    .refine((phone) => !phone.includes('_'), {
      path: [SHOWROOM_FORM_FIELDS.phone],
    }),
  [SHOWROOM_FORM_FIELDS.email]: emailRule,
  [SHOWROOM_FORM_FIELDS.animal]: stringRule('Необходимо выбрать питомца'),
  [SHOWROOM_FORM_FIELDS.country]: selectRule(),
  [SHOWROOM_FORM_FIELDS.city]: selectRule(),
  [SHOWROOM_FORM_FIELDS.delivery]: z.boolean(),
  [SHOWROOM_FORM_FIELDS.price]: stringRule(),
  [SHOWROOM_FORM_FIELDS.currency]: selectRule(),
});

export type TAddGoodsSchema = z.infer<typeof schema>;

export const useShowroomForm = (defaultValues?: Partial<TAddGoodsSchema>) => {
  return useForm<TAddGoodsSchema>({
    defaultValues: {
      [SHOWROOM_FORM_FIELDS.delivery]: false,
      [SHOWROOM_FORM_FIELDS.phone]: '+375(__)___-__-__',
      [SHOWROOM_FORM_FIELDS.currency]: 2,
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  });
};
