import { SVGProps } from 'react';

export const AnimalAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="169" height="169" viewBox="0 0 169 169" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_15080_45415)">
      <rect width="169" height="169" rx="8" fill="#E5E7EF" />
      <path
        d="M93.3 99.0796C100.037 97.4551 101 93.5773 101 87.9439C101 86.3718 100.257 69.5502 97.975 69.0262H97.645C95.8575 69.0262 92.97 72.0394 90.495 75.5242C88.79 75.3408 86.7825 75.2622 84.5 75.2622C82.1625 75.2622 80.155 75.3408 78.505 75.5242C76.1125 72.0132 73.005 69 71.3 69C68.6875 69.524 68 86.2932 68 87.9177C68 93.7345 68.825 97.5599 75.8375 99.0534C82.6575 100.337 87.525 100.285 93.3 99.0796ZM76.9375 88.1797C74.325 88.1797 74.325 87.1316 74.325 85.7691C74.325 84.4066 74.325 83.28 76.9375 83.28C79.4675 83.28 79.4675 84.459 79.4675 85.7691C79.4675 87.0792 79.495 88.1797 76.9375 88.1797ZM89.8625 85.8477C89.8625 84.5377 89.8625 83.411 92.3925 83.411C95.005 83.411 95.005 84.459 95.005 85.8477C95.005 87.2364 95.005 88.3369 92.3925 88.3369C89.8625 88.3369 89.8625 87.1578 89.8625 85.8477Z"
        fill="#FBFCFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_15080_45415">
        <rect width="169" height="169" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
