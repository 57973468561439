import { Button } from '@/shared/components';
import { ROUTE } from '@/app/navigation';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch, setRightSidebar } from '@/app/store';

const REGISTRATION = 'Регистрация';

type TProps = {
  onClick?: () => void;
};

export const RegistrationButton = ({ onClick }: TProps) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const onOpenRegistration = () => {
    dispatch(setRightSidebar(false));
    onClick && onClick();
    navigate(ROUTE.REGISTRATION);
  };

  return (
    <Button type="link" onClick={onOpenRegistration}>
      {REGISTRATION}
    </Button>
  );
};
