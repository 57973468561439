import { baseApi } from '@/app/core';
import { TSelectOption } from '@/shared/common';

export type TRegistrationSystemDto = {
  id: number;
  name: string;
  nameEn: string;
  abbreviation: string;
};

const REGISTRATION_SYSTEMS_PATH = 'registrationSystems';

type TResponse = TRegistrationSystemDto & TSelectOption;

const registrationSystemApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationSystems: builder.query<TResponse[], void>({
      query: () => ({
        url: `${REGISTRATION_SYSTEMS_PATH}`,
        method: 'GET',
      }),
    }),
    getSpeciesRegistrationSystems: builder.query<TResponse[], number>({
      query: (speciesId) => ({
        url: `${REGISTRATION_SYSTEMS_PATH}/forSpecies`,
        method: 'GET',
        params: { species_id: speciesId },
      }),
      transformResponse: (response: TRegistrationSystemDto[]) =>
        response.map((item) => ({ ...item, value: item.id, label: item.abbreviation })),
    }),
  }),
});

export const { useGetRegistrationSystemsQuery, useGetSpeciesRegistrationSystemsQuery } = registrationSystemApi;
