import { ANIMAL_SEX, SPECIES } from '@/entities/species-controller';
import { useColorsSchema } from '@/features/animal/species-colors';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { litterNameRule, NAME_LITTER_INDIVIDUAL_VALIDATION_MESSAGE, nativeEnumRule } from '@/shared/validation';
import { zodResolver } from '@hookform/resolvers/zod';

export const LITTER_INDIVIDUAL_FORM_FIELDS = {
  fullName: 'fullName',
  animalSex: 'animalSex',
} as const;

export const useLitterIndividualForm = (speciesId: SPECIES) => {
  const colorsSchema = useColorsSchema(speciesId);

  const litterIndividualSchema = z
    .object({
      [LITTER_INDIVIDUAL_FORM_FIELDS.fullName]: litterNameRule(NAME_LITTER_INDIVIDUAL_VALIDATION_MESSAGE),
      [LITTER_INDIVIDUAL_FORM_FIELDS.animalSex]: nativeEnumRule(ANIMAL_SEX),
    })
    .and(colorsSchema);

  return useForm<z.infer<typeof litterIndividualSchema>>({
    resolver: zodResolver(litterIndividualSchema),
  });
};
