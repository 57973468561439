import { commonFont } from './commonFont';

export default {
  '24R': {
    ...commonFont,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
  },
  '14R': {
    ...commonFont,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  '12R': {
    ...commonFont,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
  },
  '11R': {
    ...commonFont,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '16px',
  },
};
