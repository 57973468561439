import { useGetSpeciesRegistrationSystemsQuery } from '@/entities/registration-system-controller';
import { SPECIES } from '@/entities/species-controller';
import { Select, TSelectProps } from '@/shared/ui';
import { FieldValues } from 'react-hook-form';

export const SystemRegistrationSelect = <R extends FieldValues>({
  speciesId,
  ...props
}: TSelectProps<R> & {
  speciesId: SPECIES;
}) => {
  const { data: registrationSystems = [], isFetching: isRegistrationSystemsFetching } =
    useGetSpeciesRegistrationSystemsQuery(speciesId);

  return (
    <Select
      labelProps={{ label: 'Система регистрации' }}
      loading={isRegistrationSystemsFetching}
      options={registrationSystems}
      {...props}
    />
  );
};
