import { useState } from 'react';

import { PetsListSection } from '@/features/nursery';

import { Loader } from '@/app/icons';
import { usePostAnimalMutation, useGetAnimalsQuery, TAnimalRequestDto } from '@/entities/animal-controller';
import { TNurseryDto } from '@/entities/nursery-controller';
import { ANIMAL_SEX, ANIMAL_STATUS } from '@/entities/species-controller';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';

import { AnimalModal } from '@/features/animal';
import { comparisonNode, queries } from '@/shared/utils';

type TProps = {
  nursery: TNurseryDto;
};

type TCreateAnimalData = {
  animalSex: ANIMAL_SEX;
  status: ANIMAL_STATUS;
};

export const NurseryProducers = ({ nursery }: TProps) => {
  const {
    species,
    canEdit,
    account: { id },
  } = nursery;
  const dispatch = useTypedDispatch();

  const animalsNeuterQuery = queries.and([
    comparisonNode.equal('speciesSex.status.name', ANIMAL_STATUS.NEUTER),
    comparisonNode.equal('owner.id', id),
  ]);

  const animalsTribalQuery = queries.and([
    comparisonNode.equal('speciesSex.status.name', ANIMAL_STATUS.TRIBAL),
    comparisonNode.equal('owner.id', id),
  ]);

  const [animalCreateData, setAnimalCreateData] = useState<TCreateAnimalData | null>(null);

  const {
    data: animalsNeuter = [],
    refetch: neuterRefetch,
    isFetching: isNeuterFetching,
  } = useGetAnimalsQuery({ query: animalsNeuterQuery });
  const {
    data: animalsTribal = [],
    refetch: tribalRefetch,
    isFetching: isTribalFetching,
  } = useGetAnimalsQuery({ query: animalsTribalQuery });

  const [createPet, { isLoading }] = usePostAnimalMutation();

  const resetAnimalCreateData = () => setAnimalCreateData(null);

  const onOpenModalChange = (data: TCreateAnimalData) => {
    setAnimalCreateData(data);
  };

  const onCreateAnimal = async (payload: TAnimalRequestDto) => {
    try {
      await createPet(payload).unwrap();
      resetAnimalCreateData();
      dispatch(setGlobalModal(MODALS.ADD_ANIMAL));

      payload.status === ANIMAL_STATUS.NEUTER ? neuterRefetch() : tribalRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  if (isNeuterFetching || isTribalFetching) {
    return <Loader />;
  }

  return (
    <>
      <PetsListSection
        canEdit={canEdit}
        data={[...animalsTribal, ...animalsNeuter]}
        speciesID={species.id}
        onOpenModal={onOpenModalChange}
      />
      {animalCreateData && (
        <AnimalModal
          onSubmit={onCreateAnimal}
          isLoading={isLoading}
          initialData={{ nursery, ...animalCreateData }}
          onCancel={resetAnimalCreateData}
        />
      )}
    </>
  );
};
