import { Carousel, Headline5, TCarouselProps } from '@/shared/ui';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';
import { TCommonPageResponse } from '@/entities';
import { Dispatch, SetStateAction } from 'react';

type TProps<R> = {
  slidesToShow: number;
  data?: TCommonPageResponse & {
    content: R[];
  };
  setPage: Dispatch<SetStateAction<number>>;
  renderedSlide: TCarouselProps<R>['renderedSlide'];
  title?: string;
};

export const SliderContainer = <R extends { id: string | number }>({
  slidesToShow,
  data,
  setPage,
  renderedSlide,
  title,
}: TProps<R>) => {
  if (!data) {
    return <Loader />;
  }

  const { totalElements, content } = data;

  return (
    <Flex vertical gap={16}>
      {title && <Headline5>{title}</Headline5>}
      <Carousel
        arrows
        dots={false}
        infinite={false}
        slidesToShow={slidesToShow}
        data={content}
        afterChange={() => {
          if (totalElements > content.length) {
            setPage((prev) => prev + 1);
          }
        }}
        renderedSlide={renderedSlide}
      />
    </Flex>
  );
};
