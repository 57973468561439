import styled from 'styled-components';
import { device } from '@/app/theme/device';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { useMedia } from '@/shared/hooks';

export const FlexPage = ({ children, className }: { children: ReactNode; className?: string }) => {
  const { isDesktop } = useMedia();

  return (
    <Flex vertical className={className} gap={isDesktop ? 32 : 16}>
      {children}
    </Flex>
  );
};

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(6)};
`;

export const RowWrapper = styled(Flex)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;

  > div {
    width: calc(50% - 6px);
  }

  @media screen and ${device.mobile} {
    flex-direction: column;
    row-gap: 16px;

    > div {
      width: 100%;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(4)};
`;
