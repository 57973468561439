import { Input as AntInput, InputProps, InputRef } from 'antd';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import styled from 'styled-components';
import { ForwardedRef, forwardRef } from 'react';

export type TInputProps = InputProps & {
  errorMsg?: string;
  hasError?: boolean;
  labelProps?: TLabelProps;
};

const StyledInput = styled(AntInput)`
  &.ant-input-status-error::placeholder {
    color: ${({ theme }) => theme.palette.primary[3]};
  }
`;

export const Input = forwardRef(
  ({ width, labelProps, errorMsg, hasError, ...props }: TInputProps, ref: ForwardedRef<InputRef | null>) => {
    const status = errorMsg || hasError ? 'error' : undefined;
    return (
      <LabelWrapper style={{ width }} {...labelProps}>
        <div>
          <StyledInput ref={ref} size="large" status={status} {...props} />
          {errorMsg && <ErrorMessage message={errorMsg} />}
        </div>
      </LabelWrapper>
    );
  },
);
