import { SVGProps } from 'react';

export const NurseryIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.41015 16.75C4.17015 19.64 6.35015 22 9.25015 22H14.0402C17.3002 22 19.5402 19.37 19.0002 16.15C18.4302 12.77 15.1702 10 11.7402 10C8.02015 10 4.72015 13.04 4.41015 16.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4702 7.5C11.8509 7.5 12.9702 6.38071 12.9702 5C12.9702 3.61929 11.8509 2.5 10.4702 2.5C9.0895 2.5 7.97021 3.61929 7.97021 5C7.97021 6.38071 9.0895 7.5 10.4702 7.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2998 8.70001C18.4044 8.70001 19.2998 7.80458 19.2998 6.70001C19.2998 5.59544 18.4044 4.70001 17.2998 4.70001C16.1952 4.70001 15.2998 5.59544 15.2998 6.70001C15.2998 7.80458 16.1952 8.70001 17.2998 8.70001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12.7C21.8284 12.7 22.5 12.0284 22.5 11.2C22.5 10.3716 21.8284 9.70001 21 9.70001C20.1716 9.70001 19.5 10.3716 19.5 11.2C19.5 12.0284 20.1716 12.7 21 12.7Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.97021 10.7C5.07478 10.7 5.97021 9.80458 5.97021 8.70001C5.97021 7.59544 5.07478 6.70001 3.97021 6.70001C2.86565 6.70001 1.97021 7.59544 1.97021 8.70001C1.97021 9.80458 2.86565 10.7 3.97021 10.7Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
