import { Select as AntSelect, SelectProps } from 'antd';
import cn from 'classnames';

import './styles.scss';
import { Controller, FieldError, FieldValues } from 'react-hook-form';
import { SelectIcon } from '@/app/icons/SelectIcon';
import { TControllerProps } from '@/shared/common';
import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { Loader } from '@/app/icons';

export type TSelect = SelectProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
};

export type TSelectProps<R extends FieldValues> = SelectProps & {
  controllerProps: TControllerProps<R>;
  labelProps?: TLabelProps;
};

export const SelectComponent = ({ labelProps, className, loading, value, error, ...props }: TSelect) => (
  <LabelWrapper {...labelProps}>
    <ErrorWrapper message={error?.message}>
      <AntSelect
        showSearch
        optionFilterProp="label"
        status={error && 'error'}
        className={cn('custom-select w-full', className)}
        size="large"
        suffixIcon={
          loading ? (
            <Loader />
          ) : (
            <SelectIcon
              className={cn('text-neutral2', {
                'text-primary3': error,
              })}
            />
          )
        }
        value={loading ? null : value === 0 ? null : value}
        {...props}
      />
    </ErrorWrapper>
  </LabelWrapper>
);

export const Select = <R extends FieldValues>({ controllerProps, ...props }: TSelectProps<R>) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => <SelectComponent error={error} {...field} {...props} />}
      {...controllerProps}
    />
  );
};
