import { commonFont } from './commonFont';

export default {
  '14M': {
    ...commonFont,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  },
};
