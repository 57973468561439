import { GridViewSwitcherIcon, ListViewSwitcherIcon, Loader } from '@/app/icons';
import { useSendFilesMutation } from '@/entities/file-controller';
import { useGetLitterQuery } from '@/entities/litters-controller';
import { Collapse, Divider, Headline3 } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { useModal } from '@/shared/hooks';
import { Headline4 } from '@/shared/ui';
import { convertDateToString } from '@/shared/utils';
import { Flex } from 'antd';
import { useParams } from 'react-router-dom';
import { LitterImagesHeader } from './litter-images-header';
import { LitterImagesList } from './litter-images-list';
import { LitterIndividuals } from './litter-individuals';
import { LitterInfo } from './litter-info';

export const LitterDetails = () => {
  const { id } = useParams();
  const { data, refetch } = useGetLitterQuery(id as string);
  const [, { isLoading: isUploading }] = useSendFilesMutation();
  const { isOpen: isListView, close: openGridView, open: openListView } = useModal();
  if (!data) return <Loader />;

  const { nursery, id: litterId, images, canEdit, name, birthDate, animals } = data;

  return (
    <FlexPage>
      <Divider />
      <Headline3>
        {name} / {birthDate && `${convertDateToString(birthDate)} г.`} / {nursery.nameEn}
      </Headline3>
      <Divider />
      <Collapse
        collapsible="icon"
        defaultActiveKey={1}
        items={[
          {
            label: (
              <LitterImagesHeader
                isUploading={isUploading}
                canEdit={canEdit}
                litterId={litterId}
                images={images}
                refetch={refetch}
                data={data}
              />
            ),
            key: 1,
            children: <LitterImagesList canEdit={canEdit} images={images} />,
          },
        ]}
      />
      <Headline4>Информация</Headline4>
      <LitterInfo data={data} />
      <Divider />
      <Flex>
        <Headline4>Рожденные в помете ({animals.length})</Headline4>
        {!!animals.length && (
          <Flex gap={12} justify="flex-end" className="ml-auto items-center">
            {isListView ? (
              <ListViewSwitcherIcon className="view-switcher cursor-pointer" onClick={openGridView} />
            ) : (
              <GridViewSwitcherIcon className="view-switcher cursor-pointer hover:fill-black" onClick={openListView} />
            )}
          </Flex>
        )}
      </Flex>
      <LitterIndividuals isList={isListView} />
    </FlexPage>
  );
};
