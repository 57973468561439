import { Popular } from '@/features/Popular';
import { Login } from '@/widgets/login';
import styled from 'styled-components';
import { useMedia, useModal } from '@/shared/hooks';
import { Politics } from '@/features/Politics';
import { device } from '@/app/theme/device';
import { Route, Routes } from 'react-router-dom';
import { ROUTE } from '@/app/navigation/constants';
import { AccountSidebar } from '@/widgets/account/AccountSidebar/AccountSidebar';
import { Flex } from 'antd';
import { Image } from '@/shared/components';
import { AccountActions } from './AccountActions';
import { useEffect } from 'react';
import { Recommendations } from '@/widgets/recommendations';
import { getStorageItem, StorageKeys } from '@/shared/utils/storage';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';

const Wrapper = styled(Flex)`
  height: 100svh;
  width: 400px;
  padding: 32px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${({ theme }) => theme.palette.neutral[2]};
  overflow-y: auto;

  @media screen and ${device.tablet} {
    width: 249px;
    padding: 16px;
  }

  @media screen and ${device.mobile} {
    padding: 16px;
  }
`;

const RightSidebarRoutes = ({ isLogged }: { isLogged: boolean }) => (
  <Routes>
    <Route
      path="*"
      element={
        <>
          {isLogged && <Recommendations />}
          <Popular />
        </>
      }
    />
    <Route path={`${ROUTE.ACCOUNT}/*`} element={<AccountSidebar />} />
  </Routes>
);

const Header = ({ toggle, isLogged }: { toggle: () => void; isLogged: boolean }) =>
  isLogged ? (
    <Flex justify="space-between" align="center">
      <div />
      <Image onClick={toggle} width={48} rounded />
    </Flex>
  ) : (
    <Login />
  );

export const RightSidebar = () => {
  const currentAccountId = useTypedSelector(selectCurrentAccountId);
  const { isDesktop } = useMedia();
  const { isOpen, toggle, close } = useModal();

  useEffect(() => {
    !currentAccountId && close();
  }, [close, currentAccountId]);

  return (
    <Wrapper vertical gap={isDesktop ? 32 : 16} className="right-sidebar">
      <Header toggle={toggle} isLogged={!!currentAccountId} />
      {isOpen ? (
        <AccountActions onBack={close} />
      ) : (
        <>
          <RightSidebarRoutes isLogged={!!currentAccountId} />
          <Politics />
        </>
      )}
    </Wrapper>
  );
};
