import { Carousel as AntCarousel, CarouselProps, Flex } from 'antd';
import { createRef, ReactNode, RefObject, useMemo, useState } from 'react';
import { CarouselRef } from 'antd/es/carousel';
import { CarouselArrowIcon } from '@/app/icons';
import cn from 'classnames';
import './styles.scss';

export type TCarouselProps<R> = CarouselProps & {
  arrowsPlacement?: 'vertical' | 'horizontal';
  data: R[];
  renderedSlide: (data: R[]) => ReactNode;
};

export const Carousel = <R extends { id: string | number }>({
  arrowsPlacement = 'horizontal',
  className,
  data,
  slidesToShow = 1,
  renderedSlide,
  afterChange,
  ...props
}: TCarouselProps<R>) => {
  const carouselRef: RefObject<CarouselRef> = createRef<CarouselRef>();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { infinite } = props;

  const isVerticalArrows = arrowsPlacement === 'vertical';
  const isHorizontalArrows = arrowsPlacement === 'horizontal';

  const slides = useMemo(() => {
    return data.reduce((result: R[][], item, index) => {
      if (index % slidesToShow === 0) {
        result.push(data.slice(index, index + slidesToShow));
      }
      return result;
    }, []);
  }, [slidesToShow, data]);

  const slidesCount = slides.length;

  const onAfterChange = (slideIndex: number) => {
    if (slideIndex !== currentSlide) {
      setCurrentSlide(slideIndex);
      afterChange && afterChange(slideIndex);
    }
  };

  const Arrow = ({ onClick, className }: { onClick: () => void; className: string }) => (
    <Flex
      onClick={onClick}
      className={cn(
        'shadow-carousel-arrow absolute aspect-square w-lg cursor-pointer bg-[#FCFCFD] justify-center items-center rounded-lg z-[1]',
        { 'left-[50%] translate-x-[-16px]': isVerticalArrows },
        { 'top-[50%] translate-y-[-16px]': isHorizontalArrows },
        className,
      )}
    >
      <CarouselArrowIcon />
    </Flex>
  );

  const NextArrow = ({ onClick }: { onClick: () => void }) => {
    const isShow = !infinite && !!slidesCount && currentSlide + 1 < slidesCount;
    return (
      isShow && (
        <Arrow
          className={cn({ 'bottom-[12px]': isVerticalArrows }, { 'rotate-[-90deg] right-[-16px]': isHorizontalArrows })}
          onClick={onClick}
        />
      )
    );
  };

  const PrevArrow = ({ onClick }: { onClick: () => void }) => {
    const isShow = !infinite && !!currentSlide;
    return (
      isShow && (
        <Arrow
          className={cn(
            { 'top-[12px] rotate-180': isVerticalArrows },
            { 'rotate-[90deg] left-[-16px]': isHorizontalArrows },
          )}
          onClick={onClick}
        />
      )
    );
  };

  return slidesCount ? (
    <AntCarousel
      className={cn('custom-carousel', className)}
      afterChange={onAfterChange}
      initialSlide={currentSlide}
      nextArrow={<NextArrow onClick={() => carouselRef.current?.next()} />}
      prevArrow={<PrevArrow onClick={() => carouselRef.current?.prev()} />}
      {...props}
    >
      {slides.map((slide) => (
        <div key={slide[0].id}>{renderedSlide(slide)}</div>
      ))}
    </AntCarousel>
  ) : null;
};
