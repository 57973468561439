import * as VKID from '@vkid/sdk';
import { VKIcon } from '@/app/icons/VKIcon';

const host = process.env.REACT_APP_API_HOST || window.location.origin;

export const VkSignIn = () => {
  const app = process.env.REACT_APP_VK_APP;
  VKID.Config.set({
    app: app ? parseInt(app, 10) : 0,
    redirectUrl: `${host}/auth/vk`,
    state: 'dj29fnsadjsd82...',
  });

  const handleClick = () => {
    VKID.Auth.login();
  };

  return <VKIcon onClick={handleClick} />;
};
