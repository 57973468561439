import { SVGProps } from 'react';

export const TopArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.3002 4.22852L5.58353 6.94518C5.2627 7.26602 4.7377 7.26602 4.41686 6.94518L1.7002 4.22852"
      stroke="#FBFCFE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
