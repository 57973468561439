import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { useGetAnimalSexQuery, useGetAnimalsQuery } from '@/entities/animal-controller';
import { TLitterRequestDto, usePostLitterMutation } from '@/entities/litters-controller/';
import { ANIMAL_SEX } from '@/entities/species-controller';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { Checkbox, DatePicker, Divider, Popup, Select } from '@/shared/components';
import { alphabetOptions, comparisonNode, queries } from '@/shared/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  AWAITING,
  BIRTH_DATE,
  FATHER_ID,
  LITTER_FORM_FIELDS,
  MATING_DATE,
  MOTHER_ID,
  NAME,
  TITLE,
  TLitterSchema,
  litterSchema,
} from '../constants';

type TProps = {
  onCancel: () => void;
  onSucces: () => void;
};

export const AddLitterModal = ({ onCancel, onSucces }: TProps) => {
  const formMethods = useForm<TLitterSchema>({
    defaultValues: {
      [LITTER_FORM_FIELDS.awaiting]: false,
    },
    resolver: zodResolver(litterSchema),
  });
  const { watch, handleSubmit } = formMethods;
  const matingDayWatch = watch(LITTER_FORM_FIELDS.matingDate);

  const { data: currentUser } = useGetCurrentUserQuery();
  const dispatch = useTypedDispatch();

  const ownerId = currentUser?.accountIds[0];
  const nurseryId = currentUser?.nurseries[0].id;
  const speciesId = currentUser?.nurseries[0].species.id;

  const { data: animalSexes = [] } = useGetAnimalSexQuery('');

  const femaleSexValue = animalSexes.find((sex) => sex.species.id === speciesId && sex.sex === ANIMAL_SEX.FEMALE)?.sex;

  const maleSexValue = animalSexes.find((sex) => sex.species.id === speciesId && sex.sex === ANIMAL_SEX.MALE)?.sex;

  const animalsMothersQuery = queries.and([
    comparisonNode.equal('owner.id', ownerId as string),
    comparisonNode.equal('species.id', speciesId as number),
    comparisonNode.equal('speciesSex.sex', femaleSexValue as string),
  ]);

  const { data: animalsMothers = [], isFetching: isAnimalsMotherFetching } = useGetAnimalsQuery({
    query: animalsMothersQuery,
  });

  const animalsFathersQuery = queries.and([
    comparisonNode.equal('species.id', speciesId as number),
    comparisonNode.equal('speciesSex.sex', maleSexValue as string),
  ]);

  const { data: animalsFathers = [], isFetching: isAnimalsFatherFetching } = useGetAnimalsQuery({
    query: animalsFathersQuery,
  });

  const [postLitter] = usePostLitterMutation();

  const onFormSubmit = async (data: TLitterSchema) => {
    const requestData: TLitterRequestDto = {
      ...data,
      nurseryId: nurseryId as number,
      canEdit: true,
    };

    try {
      await postLitter(requestData).unwrap();
      onCancel();
      dispatch(setGlobalModal(MODALS.ADD_LITTER_NOTIFICATION));
      onSucces();
    } catch (error) {
      console.error('Failed to save litter:', error);
    }
  };

  const motherIdWatch = watch('motherId');

  const motherBirthDay = useMemo(
    () => animalsMothers.find((animal) => animal.id === motherIdWatch)?.dateBirth,
    [animalsMothers, motherIdWatch],
  );

  const disabledMatingDay = (current: Dayjs) =>
    current.isAfter(dayjs()) || current.isBefore(dayjs(motherBirthDay).add(180, 'day'));

  const disabledBirthDay = (current: Dayjs) =>
    current.isAfter(dayjs()) ||
    current.isBefore(dayjs(matingDayWatch)) ||
    current.isBefore(dayjs(matingDayWatch).add(40, 'day'));

  return (
    <FormProvider {...formMethods}>
      <Popup
        title={TITLE}
        open
        onCancel={onCancel}
        size="SMALL"
        okButtonProps={{
          children: 'Добавить',
          onClick: handleSubmit(onFormSubmit),
        }}
        cancelButtonProps={{
          children: 'Отменить',
          onClick: onCancel,
        }}
      >
        <Flex vertical gap={14}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                labelProps={{ label: NAME, isHorizontal: true }}
                errorMsg={error?.message}
                options={alphabetOptions}
              />
            )}
            name={LITTER_FORM_FIELDS.name}
          />
          <Divider />
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <Select
                  {...field}
                  loading={isAnimalsMotherFetching}
                  labelProps={{ label: MOTHER_ID, isHorizontal: true }}
                  errorMsg={error?.message}
                  options={animalsMothers}
                />
              );
            }}
            name={LITTER_FORM_FIELDS.motherId}
          />
          <Divider />
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <Select
                  {...field}
                  loading={isAnimalsFatherFetching}
                  labelProps={{ label: FATHER_ID, isHorizontal: true }}
                  errorMsg={error?.message}
                  options={animalsFathers}
                />
              );
            }}
            name={LITTER_FORM_FIELDS.fatherId}
          />
          <Divider />
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                labelProps={{ label: MATING_DATE, isHorizontal: true }}
                errorMsg={error?.message}
                disabledDate={disabledMatingDay}
              />
            )}
            name={LITTER_FORM_FIELDS.matingDate}
          />
          <Divider />
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                onChange={onChange}
                value={value}
                labelProps={{ label: BIRTH_DATE, isHorizontal: true }}
                errorMsg={error?.message}
                disabledDate={disabledBirthDay}
                maxDate={dayjs()}
              />
            )}
            name={LITTER_FORM_FIELDS.birthDate}
          />
          <Divider />
          <Controller
            render={({ field }) => <Checkbox label={AWAITING} {...field} />}
            name={LITTER_FORM_FIELDS.awaiting}
          />
        </Flex>
      </Popup>
    </FormProvider>
  );
};
