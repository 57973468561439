import { Card as AntCard, CardProps } from 'antd';
import { CardMetaProps } from 'antd/es/card/Meta';
import './styles.scss';
import cn from 'classnames';

type TProps = CardProps & {
  metaProps?: CardMetaProps;
};

const { Meta } = AntCard;

export const Card = ({ metaProps, className, ...props }: TProps) => {
  return (
    <AntCard className={cn('custom-card', className)} {...props}>
      {metaProps && <Meta {...metaProps} />}
    </AntCard>
  );
};
