import { Select, TSelectProps } from '@/shared/ui';
import { useGetAnimalSexQuery } from '@/entities/animal-controller';
import { queries } from '@/shared/utils';
import { SPECIES } from '@/entities/species-controller';
import { FieldValues } from 'react-hook-form';

export const AnimalSexSelect = <R extends FieldValues>({
  speciesId,
  ...props
}: TSelectProps<R> & { speciesId: SPECIES }) => {
  const speciesQuery = queries.equal('species.id', speciesId);

  const { data: sexOptions = [], isFetching: isSexFetching } = useGetAnimalSexQuery(speciesQuery);

  return <Select options={sexOptions} loading={isSexFetching} labelProps={{ label: 'Пол' }} {...props} />;
};
