import { useEffect, useState } from 'react';

const initialMedia = {
  isDesktop: false,
  isTablet: false,
  isMobile: false,
};

const getSizes = () => {
  if (window.innerWidth >= 1120) {
    return { ...initialMedia, isDesktop: true };
  }
  if (window.innerWidth >= 768 && window.innerWidth < 1120) {
    return { ...initialMedia, isTablet: true };
  }
  if (window.innerWidth < 768) {
    return { ...initialMedia, isMobile: true };
  }

  return initialMedia;
};

export const useMedia = () => {
  const [media, setMedia] = useState<{ isDesktop: boolean; isTablet: boolean; isMobile: boolean }>(getSizes);

  useEffect(() => {
    const handleResize = () => {
      const sizes = getSizes();
      setMedia({ ...sizes });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [media]);

  return media;
};
