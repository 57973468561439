import { Flex } from 'antd';
import { AccountAvatar } from '@/features/account';
import { TCommentDto, useDeleteCommentMutation } from '@/entities/comments-controller';
import { Body2, Caption2 } from '@/shared/components';
import { getTimeAgo } from '@/shared/utils';
import { theme } from '@/app/theme';
import { LikeButton } from '@/features/buttons';
import React from 'react';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { Trash } from '@/app/icons';

type TProps = {
  data: TCommentDto;
};
export const Comment = ({ data }: TProps) => {
  const { accountShortInfo, text, createdAt, likedByCurrent, id: commentId, canEdit } = data;
  const [deleteComment] = useDeleteCommentMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const isMyComment = currentUser?.id === accountShortInfo.userId;

  const onDeleteComment = async () => {
    try {
      await deleteComment(commentId);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Flex vertical gap={10}>
      <Flex justify="space-between" align="center">
        <AccountAvatar
          accountInfo={{
            name: accountShortInfo.accountName,
            src: accountShortInfo.accountAvatar?.url,
            id: accountShortInfo.accountId,
            canEdit,
          }}
        />
        <LikeButton likedByCurrent={likedByCurrent} commentId={commentId} width={16} />
      </Flex>
      <Flex gap="8px">
        <Body2 styles={{ wordBreak: 'break-all' }}>{text}</Body2>
        {isMyComment && (
          <Trash
            onClick={onDeleteComment}
            style={{ color: theme.palette.neutral[4], cursor: 'pointer', alignSelf: 'end' }}
          />
        )}
      </Flex>
      <Caption2 styles={{ color: theme.palette.neutral[4] }} weight="B">
        {getTimeAgo(createdAt)}
      </Caption2>
    </Flex>
  );
};
