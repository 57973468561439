import styled from 'styled-components';
import { useState } from 'react';
import { Badge, MultiBadge, Input, TInputProps } from '@/shared/components';

type TProps = TInputProps & {
  options: string[];
  onChange: (value: string[]) => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const BadgeInput = ({ options, onChange, ...props }: TProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = () => {
    const value = options.find((item) => item === inputValue);
    if (!value) {
      onChange([...options, inputValue]);
      setInputValue('');
    }
  };

  const handleRemove = (value: string) => onChange(options.filter((item) => item !== value));

  return (
    <Wrapper>
      <Input
        onPressEnter={handleChange}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        {...props}
      />
      <MultiBadge>
        {!!options.length &&
          options.map((option) => <Badge label={option} key={option} onClose={() => handleRemove(option)} />)}
      </MultiBadge>
    </Wrapper>
  );
};
