import { postApi } from './api';
import { TPagePostDto, TPostReadDto } from './types';
import { queries } from '@/shared/utils';

export const updatePostFeed = (action: (draft: TPagePostDto) => void) =>
  postApi.util.updateQueryData('getPostLast', {}, action);

export const updatePostList = (accountId: string, action: (draft: TPagePostDto) => void) =>
  postApi.util.updateQueryData('getPosts', { query: queries.equal('account.id', accountId) }, action);

export const updatePost = (postId: string, action: (draft: TPostReadDto) => void) =>
  postApi.util.updateQueryData('getPostById', postId, action);
