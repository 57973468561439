import { NotificationPopup, Typography } from '@/shared/components';
import { CONGRATS } from './constants';

type TProps = {
  onClose: () => void;
};

const CHANGES_SAVED = 'Изменения сохранены';
const SUCCESSFULLY_SAVED = 'Изменения успешно сохранены.';

export const ChangesSavedModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={CHANGES_SAVED} onCancel={onClose}>
    <Typography type="14SB">{CONGRATS}</Typography>
    <p>{SUCCESSFULLY_SAVED}</p>
  </NotificationPopup>
);
