import { TImageInfoDto } from '@/entities/file-controller';
import { useEffect, useState } from 'react';
import { MobileView } from './MobileView';
import { useMedia } from '@/shared/hooks';
import { DesktopView } from '@/features/goods/goods-images-view/DescktopView';

type TProps = {
  images: TImageInfoDto[];
};

export const GoodsImagesView = ({ images }: TProps) => {
  const [activeImage, setActiveImage] = useState<TImageInfoDto | null>(null);
  const { isDesktop } = useMedia();

  const onChnageActiveImage = (value: TImageInfoDto) => setActiveImage(value);

  useEffect(() => {
    setActiveImage(images.length ? images[0] : null);
  }, [images]);

  const props = { images, activeImage, setActiveImage: onChnageActiveImage };

  return isDesktop ? <DesktopView {...props} /> : <MobileView {...props} />;
};
