import { ROUTE } from '@/app/navigation/';
import { NotificationPopup } from '@/shared/components';
import { useNavigate } from 'react-router-dom';

const TITLE = 'Требуется выполнить действие';
const TEXT =
  'Такого пользователя в системе не зарегистрировано. Форма «Войти» предназначена для авторизации зарегистрированных пользователей. Вероятно, Вам необходимо сначала зарегистрироваться.';

type TProps = {
  onClose: () => void;
};

export const RegistrationModal = ({ onClose }: TProps) => {
  const navigate = useNavigate();

  return (
    <NotificationPopup
      title={TITLE}
      open
      onCancel={onClose}
      cancelButtonProps={{ children: 'Отмена', onClick: onClose }}
      okButtonProps={{
        children: 'Зарегистрироваться',
        onClick: () => {
          navigate(ROUTE.REGISTRATION);
          onClose();
        },
      }}
    >
      {TEXT}
    </NotificationPopup>
  );
};
