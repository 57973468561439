import React, { ReactNode } from 'react';
import { device } from '@/app/theme/device';
import { Body2 } from '@/shared/components';
import styled, { css } from 'styled-components';

const EMPTY = '–';

export const Label = styled.div<{ $isHorizontal?: boolean; $error?: boolean; $labelWidth?: string | number }>`
  ${({ $labelWidth }) =>
    $labelWidth &&
    css`
      max-width: ${$labelWidth};
    `}
  ${({ $isHorizontal }) =>
    $isHorizontal
      ? css`
          ${({ theme }) => theme.typography['14SB']}
        `
      : css`
          ${({ theme }) => theme.typography.noto12B};
          color: ${({ theme }) => theme.palette.neutral[5]};
          text-transform: uppercase;
          margin-bottom: ${({ theme }) => theme.spacing(3)};

          @media ${device.tablet} {
            ${({ theme }) => theme.typography.noto10B};
          }
        `}
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}
  display: block;
`;

const Content = styled.div<{ $contentWidth?: string | number }>`
  ${({ $contentWidth }) =>
    $contentWidth &&
    css`
      max-width: ${$contentWidth};
    `}
`;

const Wrapper = styled.div<{ $isHorizontal?: boolean; $contentWidth?: string | number; $marginLeft?: string }>`
  width: 100%;
  ${({ $isHorizontal }) =>
    $isHorizontal &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 12px;
      > div {
        width: 100%;
      }
    `}
  ${({ $marginLeft }) =>
    $marginLeft &&
    css`
      > div {
        margin-left: auto;
      }
    `}
`;

export type TLabelProps = {
  label?: ReactNode;
  isHorizontal?: boolean;
  showRequired?: boolean;
  labelWidth?: string;
  contentWidth?: string;
};

type TProps = TLabelProps & {
  children: React.ReactNode;
  style?: React.CSSProperties;
  contentStyles?: React.CSSProperties;
  marginLeft?: string;
};

const Required = styled.span`
  display: inline-block;
  margin-left: 4px;
  color: ${({ theme }) => theme.palette.primary[3]};
`;

export const LabelWrapper = ({
  label,
  isHorizontal,
  children,
  style,
  contentStyles,
  showRequired,
  contentWidth,
  labelWidth,
  marginLeft,
}: TProps) => (
  <Wrapper $isHorizontal={isHorizontal} $contentWidth={contentWidth} style={style} $marginLeft={marginLeft}>
    {label && (
      <Label $isHorizontal={isHorizontal} $labelWidth={labelWidth}>
        {label}
        {showRequired && <Required>*</Required>}
      </Label>
    )}
    <Content style={contentStyles} $contentWidth={contentWidth}>
      {children}
    </Content>
  </Wrapper>
);

export const ReadableField = ({
  children,
  hideFieldWithoutValue = false,
  ...props
}: TProps & { hideFieldWithoutValue?: boolean }) => {
  if (hideFieldWithoutValue && !children) {
    return null;
  }
  return (
    <LabelWrapper {...props}>
      <Body2>{children || EMPTY}</Body2>
    </LabelWrapper>
  );
};
