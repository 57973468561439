import { Image, ImageProps } from 'antd';
import styled, { CSSProp, css } from 'styled-components';

export type TStyledImageProps = ImageProps & {
  styles?: CSSProp;
  square?: boolean;
};

const StyledImage = styled(Image)<TStyledImageProps>`
  ${({ styles }) => styles}
  ${({ square }) =>
    square &&
    css`
      aspect-ratio: 1/1;
    `}
`;

export const CustomImage = ({ src, width, ...props }: TStyledImageProps) => (
  <StyledImage src={src || '/avatar.svg'} preview={false} width={width || '100%'} {...props} />
);
