import { Flex } from 'antd';
import { Headline4, PseudoInput } from '@/shared/ui';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { getAge } from '@/shared/utils';

export const GoodsCharacteristics = ({ good, withTitle }: { withTitle?: boolean; good: TGoodDtoExt }) => {
  const {
    animalExt: {
      breed,
      speciesSex: { label },
      tribalTypes,
      colors,
      dateBirth,
      nursery: { breeds },
    },
  } = good;

  const tribal = tribalTypes?.length ? tribalTypes.map(({ name }) => name).join(', ') : null;
  const color = colors.map(({ name }) => name).join(', ');

  const age = dateBirth && getAge(dateBirth);
  const breedName = breed?.name || breeds[0].name;

  return (
    <Flex vertical gap={8}>
      {withTitle && <Headline4>Характеристики</Headline4>}
      <PseudoInput label="Порода:" text={breedName} />
      <PseudoInput label="Пол:" text={label} />
      {tribal && <PseudoInput label="Племенной класс:" text={tribal} />}
      <PseudoInput label="Окрас:" text={color} />
      <PseudoInput label="Возраст:" text={age} />
    </Flex>
  );
};
