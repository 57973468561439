import { Body2, Button, ReadableField } from '@/shared/components';
import { convertDateToString } from '@/shared/utils';
import { useModal } from '@/shared/hooks';
import { TLitterDto } from '@/entities/litters-controller';
import { EditLitterInformationModal } from '@/features/litters';
import { Flex } from 'antd';

const DESCRIPTION = 'Описание';
const LITTER = 'Помёт:';
const NURSERY = 'Питомник:';
const FATHER_NAME = 'Отец:';
const MOTHER_NAME = 'Мать:';
const MATING_DATE = 'Дата вязки:';
const BIRTH_DATE = 'Дата рождения помёта:';
const BREEDER_NAME = 'Заводчик:';
const EDIT_INFORMATION = 'Редактировать информацию:';

type TProps = {
  data: TLitterDto;
};

export const LitterInfo = ({ data }: TProps) => {
  const { open, close, isOpen } = useModal();
  const { description, nursery, name, father, mother, matingDate, birthDate, canEdit } = data || {};

  return (
    <Flex vertical gap={16}>
      {description && (
        <>
          <Body2 weight="SB">{DESCRIPTION}:</Body2>
          <Body2>{description}</Body2>
        </>
      )}
      <ReadableField hideFieldWithoutValue isHorizontal label={LITTER}>
        {name}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={NURSERY}>
        {nursery.nameEn}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={FATHER_NAME}>
        {father?.fullName || '-'}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={MOTHER_NAME}>
        {mother?.fullName || '-'}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={MATING_DATE}>
        {matingDate && convertDateToString(matingDate)}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={BIRTH_DATE}>
        {birthDate && convertDateToString(birthDate)}
      </ReadableField>
      <ReadableField hideFieldWithoutValue isHorizontal label={BREEDER_NAME}>
        {nursery.account.name}
      </ReadableField>
      {canEdit && <Button onClick={open}>{EDIT_INFORMATION}</Button>}
      {isOpen && (
        <EditLitterInformationModal
          onCancel={close}
          litterData={{
            ...data,
            fatherId: data?.father?.id,
            motherId: data?.mother?.id,
            nurseryId: data.nursery.id,
            images: data?.images?.map((img) => img.id),
          }}
        />
      )}
    </Flex>
  );
};
