import { NotificationPopup } from '@/shared/components';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/app/navigation/constants';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';

const TITLE = 'Требуется выполнить действие';
const TEXT = 'Для того, чтобы воспользоваться этой функцией, необходимо войти либо зарегистрироваться.';

type TProps = {
  onClose: () => void;
};

export const AuthModal = ({ onClose }: TProps) => {
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  return (
    <NotificationPopup
      title={TITLE}
      open
      onCancel={onClose}
      cancelButtonProps={{ children: 'Войти', onClick: () => dispatch(setGlobalModal(MODALS.SIGN_IN)) }}
      okButtonProps={{
        children: 'Зарегистрироваться',
        onClick: () => {
          navigate(ROUTE.REGISTRATION);
          onClose();
        },
      }}
    >
      {TEXT}
    </NotificationPopup>
  );
};
