import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Caption2, Checkbox, ErrorMessage } from '@/shared/components';
import { FORM_FIELDS } from '@/widgets/registration/RegisterForm/constants';
import { Flex } from 'antd';

const TERMS =
  'Нажимая кнопку «Зарегистрироваться», вы создаете учетную запись BREED SHOW и, следовательно, соглашаетесь с Условиями использования и Политикой конфиденциальности, а также даете свое согласие на обработку Персональных данных';

const TermsMemo = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name={FORM_FIELDS.terms}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Flex gap={16} align="start">
            <Checkbox {...field} />
            <Caption2>{TERMS}</Caption2>
          </Flex>
          {error?.message && <ErrorMessage message={error.message} />}
        </>
      )}
    />
  );
};

export const Terms = memo(TermsMemo);
