import {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  RawAxiosRequestHeaders,
} from 'axios';
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';

import { axiosJson } from './axiosJson';
import { axiosFormData } from './axiosFormData';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

interface Error {
  title: string;
  description: string;
}

interface AxiosBaseQueryError {
  status?: number;
  data?: string | Error;
}

interface AxiosBaseQueryArgs<Response> {
  axiosInstance: AxiosInstance;
  prepareHeaders?: (headers: RawAxiosRequestHeaders | AxiosHeaders, api: BaseQueryApi) => AxiosRequestHeaders;
  transformResponse?: (response: Response) => unknown;
}

interface ServiceExtraOptions {
  authRequired?: boolean;
}

const getRequestConfig = (args: string | AxiosRequestConfig) => {
  if (typeof args === 'string') {
    return { url: args };
  }

  return args;
};

export const axiosBaseQuery = <
  Args extends AxiosRequestConfig | string,
  Result = unknown,
  DefinitionExtraOptions extends ServiceExtraOptions = Record<string, unknown>,
>(
  { axiosInstance, prepareHeaders, transformResponse }: AxiosBaseQueryArgs<Result> = { axiosInstance: axiosJson },
): BaseQueryFn<Args, Result, AxiosBaseQueryError, DefinitionExtraOptions> => {
  return async (args, api, extraOptions) => {
    try {
      const { data, headers, ...requestConfig } = getRequestConfig(args);

      if (data instanceof FormData) {
        axiosInstance = axiosFormData;
      } else {
        axiosInstance = axiosJson;
      }

      const result = await axiosInstance({
        ...requestConfig,
        data,
        headers: prepareHeaders ? prepareHeaders(headers || {}, api) : headers,
        ...extraOptions,
      });

      return {
        data: transformResponse ? transformResponse(result.data) : result.data,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError<Error>;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});
