import { SVGProps } from 'react';

export const DropdownDots = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.75 12C3.75 11.3142 4.31421 10.75 5 10.75C5.68579 10.75 6.25 11.3142 6.25 12C6.25 12.6858 5.68579 13.25 5 13.25C4.31421 13.25 3.75 12.6858 3.75 12Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M17.75 12C17.75 11.3142 18.3142 10.75 19 10.75C19.6858 10.75 20.25 11.3142 20.25 12C20.25 12.6858 19.6858 13.25 19 13.25C18.3142 13.25 17.75 12.6858 17.75 12Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M10.75 12C10.75 11.3142 11.3142 10.75 12 10.75C12.6858 10.75 13.25 11.3142 13.25 12C13.25 12.6858 12.6858 13.25 12 13.25C11.3142 13.25 10.75 12.6858 10.75 12Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
