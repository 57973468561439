import { InputPassword } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { passwordRule } from '@/shared/validation';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { z } from 'zod';

const PASSWORD = 'ПАРОЛЬ';
const REPEAT_PASSWORD = 'Повторите пароль';
const PASSWORD_MATCH = 'Совпадение паролей';

export const PASSWORD_FORM_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
} as const;

export const passwordSchema = z
  .object({
    [PASSWORD_FORM_FIELDS.password]: passwordRule,
    [PASSWORD_FORM_FIELDS.confirmPassword]: passwordRule,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Пароль не совпадает',
    path: [PASSWORD_FORM_FIELDS.confirmPassword],
  });

type TSchema = z.infer<typeof passwordSchema>;

type TProps = {
  width?: string | number;
};

export const PasswordCreate = ({ width }: TProps) => {
  const { control } = useFormContext<TSchema>();

  const passwordValue = useWatch({
    control,
    name: PASSWORD_FORM_FIELDS.password,
  });
  const passwordRepeatValue = useWatch({
    control,
    name: PASSWORD_FORM_FIELDS.confirmPassword,
  });

  return (
    <ColumnWrapper style={{ width }}>
      {/* this life hack helps to turn off autofill and suggest generate password */}
      <input type="password" autoComplete="new-password" style={{ display: 'none' }} />
      <Controller
        name={PASSWORD_FORM_FIELDS.password}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputPassword {...field} hasError={!!error} autoComplete="off" labelProps={{ label: PASSWORD }} />
        )}
      />
      <Controller
        name={PASSWORD_FORM_FIELDS.confirmPassword}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputPassword
            labelProps={{ label: REPEAT_PASSWORD }}
            autoComplete="off"
            hasError={!!error}
            rule={[
              {
                isValid: !!passwordValue && !!passwordRepeatValue && passwordValue === passwordRepeatValue,
                label: PASSWORD_MATCH,
              },
            ]}
            onPaste={(e) => e.preventDefault()}
            {...field}
          />
        )}
      />
    </ColumnWrapper>
  );
};
