import { commonFont } from './commonFont';

export default {
  '26': {
    ...commonFont,
    fontSize: 26,
    lineHeight: '38px',
    letterSpacing: '-0.5px',
  },
  '18': {
    ...commonFont,
    fontSize: 18,
    lineHeight: '34px',
  },
  '14': {
    ...commonFont,
    fontSize: 14,
    lineHeight: '24px',
  },
  '12': {
    ...commonFont,
    fontSize: 12,
    lineHeight: '20px',
  },
  '11': {
    ...commonFont,
    fontSize: 11,
    lineHeight: '16px',
  },
};
