import { Flex } from 'antd';
import { Body2, Headline6 } from '@/shared/ui';
import { DeliveryIcon } from '@/app/icons';

export const GoodsDelivery = ({ city }: { city: string }) => (
  <Flex vertical gap={4}>
    <Headline6 className="text-neutral4">Информация о доставке</Headline6>
    <Flex gap={8} align="center">
      <DeliveryIcon />
      <Body2>Доставка по {city}</Body2>
    </Flex>
  </Flex>
);
