import { TGoodDto } from '@/entities/goods-controller';
import animalAvatar from '@/app/icons/svg/animalAvatar.svg';
import { priceConverter } from '@/shared/utils';
import React from 'react';
import { Card, Button, Headline4, Body2, Caption2 } from '@/shared/ui';

type TProps = {
  good: TGoodDto;
  onClick: (good: TGoodDto) => void;
};

export const GoodCard = ({ good, onClick }: TProps) => {
  const {
    animal: { avatar, nursery, fullName },
    price,
    city,
    country,
  } = good;

  return (
    <Card
      onClick={() => onClick(good)}
      hoverable
      cover={<img alt="card-img" className="h-[200px] object-cover" src={avatar?.url || animalAvatar} />}
      metaProps={{
        description: (
          <>
            <Headline4 className="text-neutral2">{priceConverter(price)} р.</Headline4>
            <Body2>{nursery.nameEn}</Body2>
            <Body2 className="font-semibold">{fullName}</Body2>
            <Caption2 className="text-neutral4">
              {country?.name}, {city?.name}
            </Caption2>
          </>
        ),
      }}
      actions={[
        <Button key="button" size="unset" className="w-full rounded-[4px] p-[4px]">
          <span className="font-semibold uppercase text-[10px]">В избранное</span>
        </Button>,
      ]}
    />
  );
};
