import { z } from 'zod';
import {
  checkBoxRequiredRule,
  emailRule,
  NAME_VALIDATION_MESSAGE,
  nameRule,
  selectRule,
  SURNAME_VALIDATION_MESSAGE,
} from '@/shared/validation';
import { NURSERY_FORM_FIELDS, nurserySchema } from '@/features/nursery';
import { PASSWORD_FORM_FIELDS, passwordSchema } from '@/features/password';

export const INTRODUCE = 'Представьтесь';
export const NAME = 'Имя';
export const SURNAME = 'Фамилия';
export const QUESTION_BREEDER = 'Вы заводчик?';
export const REGISTRATION_SUBMIT = 'Зарегистрироваться';

export const OPTIONS = [
  { value: false, label: 'Нет' },
  { value: true, label: 'Да' },
];

export const FORM_FIELDS = {
  name: 'name',
  surname: 'surname',
  email: 'email',
  breeder: 'breeder',
  terms: 'terms',
  countryId: 'countryId',
  cityId: 'cityId',
  avatarId: 'avatarId',
  ...PASSWORD_FORM_FIELDS,
  ...NURSERY_FORM_FIELDS,
} as const;

const defaultSchema = z
  .object({
    [FORM_FIELDS.name]: nameRule(NAME_VALIDATION_MESSAGE),
    [FORM_FIELDS.surname]: nameRule(SURNAME_VALIDATION_MESSAGE),
    [FORM_FIELDS.email]: emailRule,
    [FORM_FIELDS.breeder]: z.boolean(),
    [FORM_FIELDS.terms]: checkBoxRequiredRule,
  })
  .and(passwordSchema);

const userSchema = z.object({
  [FORM_FIELDS.breeder]: z.literal(false),
});

const breederSchema = z
  .object({
    [FORM_FIELDS.breeder]: z.literal(true),
    [FORM_FIELDS.countryId]: selectRule(),
    [FORM_FIELDS.cityId]: selectRule(),
  })
  .merge(nurserySchema)
  .merge(
    z.object({
      [NURSERY_FORM_FIELDS.breederName]: z.undefined(),
    }),
  );

const schemaCond = z.discriminatedUnion(FORM_FIELDS.breeder, [breederSchema, userSchema]);

export const registrationValidation = z.intersection(schemaCond, defaultSchema);
