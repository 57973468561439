import { useModal } from '@/shared/hooks';
import { CloseCircle, Info } from '@/app/icons';
import { Flex } from 'antd';
import { Caption2, ReadableField } from '@/shared/components';
import { theme } from '@/app/theme';
import { TUserDto, useGetCurrentUserQuery } from '@/entities/user-controller';
import styled from 'styled-components';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';

const MORE_DETAILS = 'Подробнее';
const COUNTRY = 'Страна';
const CITY = 'Город';
const JOB = 'Работа';
const WEBSITE = 'Вебсайт';

type TProps = {
  user: TUserDto;
};

const Close = styled(CloseCircle)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.palette.neutral[7]};
`;

const ShowDetails = styled(Flex)`
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
`;

export const AccountSidebarDetails = ({ user }: TProps) => {
  const { isOpen, close, open } = useModal();

  const { country, city } = user;
  const { data: currentUser } = useGetCurrentUserQuery();
  const dispatch = useTypedDispatch();

  const handleShowDetails = () => (currentUser ? open() : dispatch(setGlobalModal(MODALS.AUTH)));

  return isOpen ? (
    <Flex vertical gap={16} style={{ position: 'relative' }}>
      <Close onClick={close} />
      {[
        { label: COUNTRY, text: country?.name },
        { label: CITY, text: city?.name },
        { label: JOB },
        { label: WEBSITE },
      ].map(({ label, text }) => (
        <ReadableField
          key={label}
          labelWidth="90px"
          style={{ color: theme.palette.neutral[7] }}
          contentStyles={{ color: theme.palette.neutral[10] }}
          label={`${label}:`}
          isHorizontal
        >
          {text}
        </ReadableField>
      ))}
    </Flex>
  ) : (
    <ShowDetails gap={6} align="center" onClick={handleShowDetails}>
      <Info />
      <Caption2 weight="B" styles={{ color: theme.palette.neutral[9] }}>
        {MORE_DETAILS}
      </Caption2>
    </ShowDetails>
  );
};
