import { SVGProps } from 'react';

export const AddAccountIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.9974 7.9987C9.83834 7.9987 11.3307 6.50631 11.3307 4.66536C11.3307 2.82442 9.83834 1.33203 7.9974 1.33203C6.15645 1.33203 4.66406 2.82442 4.66406 4.66536C4.66406 6.50631 6.15645 7.9987 7.9974 7.9987Z"
        stroke="#212029"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
