import { axiosBaseQuery } from '@/app/core';
import { createApi } from '@reduxjs/toolkit/query/react';
import { TLitterDto, TLitterRequestDto, TLitterSaveResultDto, TLittersQuery, TPageLitterDto } from './types';

const LITTER_PATH = 'litters';

export const litterApi = createApi({
  reducerPath: 'litterApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['LITTER'],
  endpoints: (builder) => ({
    getLitters: builder.query<TPageLitterDto, Partial<TLittersQuery>>({
      query: (params) => ({
        url: `${LITTER_PATH}`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}/${queryArgs.query}`;
      },
      merge: (currentData, newData) => {
        if (!newData.content) return currentData;
        const existingIds = new Set(currentData.content.map((litter) => litter.id));
        currentData.content = [
          ...currentData.content,
          ...newData.content.filter((litter) => !existingIds.has(litter.id)),
        ];
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    postLitter: builder.mutation<TLitterSaveResultDto, TLitterRequestDto>({
      query: (body) => ({
        url: `${LITTER_PATH}/litter`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [{ type: 'LITTER' }],
    }),

    getLitter: builder.query<TLitterDto, string>({
      query: (litterId) => ({
        url: `${LITTER_PATH}/${litterId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'LITTER', id: result.id }] : []),
    }),

    putLitter: builder.mutation<TLitterDto, TLitterRequestDto>({
      query: (data) => ({
        url: `${LITTER_PATH}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'LITTER', id: result.id }] : []),
    }),
  }),
});

export const { useGetLittersQuery, usePostLitterMutation, useGetLitterQuery, usePutLitterMutation } = litterApi;
