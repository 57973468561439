import { useCallback } from 'react';
import { usePostAnimalImageMutation } from '@/entities/animal-controller';
import { RcFile } from 'antd/es/upload';

export const useUploadAnimalImages = () => {
  const [attachImage, { isLoading }] = usePostAnimalImageMutation();

  const upload = useCallback(
    async (animalId: string, fileList: RcFile[]) => {
      try {
        await attachImage({ animalId, fileList }).unwrap();
      } catch (e) {
        console.error(e);
      }
    },
    [attachImage],
  );

  return { upload, isLoading };
};
