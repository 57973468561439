import { Carousel, CarouselProps } from 'antd';
import { CarouselRef } from 'antd/es/carousel';

import styled, { css } from 'styled-components';
import { createRef, RefObject } from 'react';
import { ArrowCircleDown } from '@/app/icons';

const SliderWrapper = styled.div<{ $dotsPlacement: string }>`
  width: 100%;

  .ant-carousel {
    .slick-dots li,
    .slick-dots button {
      border-radius: ${({ theme }) => theme.borderRadius.sm};
      mix-blend-mode: difference;
      filter: invert(0.8);
    }

    ${({ $dotsPlacement }) =>
      $dotsPlacement &&
      css`
        .slick-dots-bottom {
          margin-bottom: ${$dotsPlacement === 'bottom' ? '-48px' : '18px'};
        }

        .slick-dots li,
        .slick-dots button {
          width: ${$dotsPlacement === 'bottom' ? '7px' : '8px'};
          height: ${$dotsPlacement === 'bottom' ? '7px' : '8px'};
        }

        .slick-dots li {
          background: ${({ theme }) => $dotsPlacement === 'bottom' && theme.palette.neutral[6]};
          filter: ${$dotsPlacement === 'bottom' && 'none'};
        }

        .slick-dots li.slick-active button {
          background: ${({ theme }) => $dotsPlacement === 'bottom' && theme.palette.primary[1]};
        }
      `}

    .slick-prev,
    .slick-next {
      z-index: 2;
      fill: ${({ theme }) => theme.palette.neutral[8]};
      color: ${({ theme }) => theme.palette.neutral[1]};
      filter: invert(0.2);
    }

    .slick-prev {
      left: 16px;
      transform: rotate(90deg);
    }

    .slick-next {
      right: 16px;
      transform: rotate(-90deg);
    }
  }
`;

export const Arrow = styled(ArrowCircleDown)``;

type TProps = CarouselProps & {
  dotsPlacement?: 'bottom' | 'default';
};

export const Slider = ({ children, dotsPlacement = 'default', ...props }: TProps) => {
  const carouselRef: RefObject<CarouselRef> = createRef<CarouselRef>();

  return (
    <SliderWrapper $dotsPlacement={dotsPlacement}>
      <Carousel
        arrows
        nextArrow={<Arrow onClick={() => carouselRef.current?.prev()} />}
        prevArrow={<Arrow onClick={() => carouselRef.current?.next()} />}
        ref={carouselRef}
        {...props}
      >
        {children}
      </Carousel>
    </SliderWrapper>
  );
};
