import { Headline4 } from '@/shared/components';
import { theme } from '@/app/theme';
import { Flex } from 'antd';
import { RecommendationList } from './RecommendationList';

export const Recommendations = () => {
  return (
    <Flex vertical gap={24}>
      <Flex justify="space-between" align="center">
        <Headline4 styles={{ color: theme.palette.neutral[7] }}>Рекомендации для вас</Headline4>
      </Flex>
      <RecommendationList />
    </Flex>
  );
};
