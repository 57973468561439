import { StyledObject } from 'styled-components';

import { palette, Typography, typography, borderRadius, spacing, zIndex, commonClasses, fontWeight } from './config';

export const theme = {
  palette,
  typography,
  borderRadius,
  zIndex,
  spacing,
  commonClasses,
  fontWeight,
};

export type Theme = Omit<typeof theme, 'typography'> & {
  typography: Record<keyof typeof typography, StyledObject<Typography>>;
};
