import { ROUTE } from '@/app/navigation/constants';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import styled from 'styled-components';
import { theme } from '@/app/theme';
import { Caption2 } from '@/shared/components';
import { useMedia } from '@/shared/hooks/useMedia';
import { HOME_PATHS } from '@/app/navigation';

const LINKS = [
  {
    label: 'О проекте',
    link: ROUTE.TG,
  },
  {
    label: 'Политика конфиденциальности',
    link: HOME_PATHS.home,
  },
];

const LINKS_DIVIDER = '•';
const PROJECT_NAME = '2023 © breed.show';

const StyledLink = styled(Link)`
  ${({ theme }) => theme.typography['12R']}
  color: ${({ theme }) => theme.palette.neutral[4]}
`;

export const Politics = () => {
  const { isTablet } = useMedia();

  return (
    <div style={{ marginTop: 'auto' }}>
      <Flex vertical={isTablet} gap={isTablet ? 0 : 10}>
        {LINKS.map(({ label, link }, index) => (
          <>
            {!isTablet && !!(index % 2) && <div style={{ color: theme.palette.neutral[4] }}>{LINKS_DIVIDER}</div>}
            <StyledLink key={label} to={link}>
              {label}
            </StyledLink>
          </>
        ))}
      </Flex>
      <Caption2 styles={{ color: theme.palette.neutral[4] }}>{PROJECT_NAME}</Caption2>
    </div>
  );
};
