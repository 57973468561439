import { ColumnWrapper } from '@/shared/containers';
import { ProfileHeader } from '@/features/profile/styles';
import { Body2, Divider, LabelWrapper, Typography } from '@/shared/components';
import { CITY, COUNTRY } from '@/features/profile/constants';
import { TUserDto } from '@/entities/user-controller';
import { ProfileAvatar } from '@/features/profile/ProfileAvatar';

type TProps = {
  user: TUserDto;
};

export const ProfileView = ({ user }: TProps) => {
  const { avatar, surname, name, country, city, description } = user;
  return (
    <ColumnWrapper>
      <ProfileHeader>
        <ProfileAvatar name={name} url={avatar?.url} />
        <div>
          <Body2 weight="SB">
            {name} {surname}
          </Body2>
          <Body2>{description}</Body2>
        </div>
      </ProfileHeader>
      <Divider />
      <LabelWrapper label={COUNTRY} isHorizontal>
        <Typography type="14R">{country?.name}</Typography>
      </LabelWrapper>
      <LabelWrapper label={CITY} isHorizontal>
        <Typography type="14R">{city?.name}</Typography>
      </LabelWrapper>
    </ColumnWrapper>
  );
};
