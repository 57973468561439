import { Controller, useFormContext } from 'react-hook-form';

import { useLazyGetCitiesQuery } from '@/entities/city-controller';
import { useGetCountriesQuery } from '@/entities/country-controller';

import { Headline4, Select } from '@/shared/components';
import { ColumnWrapper, RowWrapper } from '@/shared/containers';
import { FORM_FIELDS } from '@/widgets/registration/RegisterForm/constants';
import { CITIES_LABEL, COUNTRIES_LABEL, RESIDENCE_QUESTION } from './constants';

export const Residence = () => {
  const { control, setValue, resetField } = useFormContext();

  const { data: countries, isFetching: isCountriesFetching } = useGetCountriesQuery();
  const [getCities, { data: cities, isFetching: isCitiesFetching }] = useLazyGetCitiesQuery();

  const handleCountryChange = (value: number) => {
    setValue(FORM_FIELDS.countryId, value, { shouldValidate: true });
    resetField(FORM_FIELDS.cityId);
    getCities(value);
  };

  return (
    <ColumnWrapper>
      <Headline4>{RESIDENCE_QUESTION}</Headline4>
      <RowWrapper>
        <Controller
          control={control}
          name={FORM_FIELDS.countryId}
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              onChange={handleCountryChange}
              labelProps={{ label: COUNTRIES_LABEL }}
              options={countries}
              loading={isCountriesFetching}
              errorMsg={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={FORM_FIELDS.cityId}
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              labelProps={{ label: CITIES_LABEL }}
              options={cities}
              loading={isCitiesFetching}
              errorMsg={error?.message}
            />
          )}
        />
      </RowWrapper>
    </ColumnWrapper>
  );
};
