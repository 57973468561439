import { Outlet, useParams } from 'react-router-dom';
import { Loader } from '@/app/icons';
import { useGetAccountQuery, useGetForeignUserQuery } from '@/entities/user-controller';
import { AccountHeader } from '@/widgets/account';

import { FlexPage } from '@/shared/containers';
import { AccountTabs } from '@/pages/account/account-tabs';

export const AccountPage = () => {
  const { accountId, goodsId } = useParams();
  const { data: account } = useGetAccountQuery(accountId as string);
  const { data: user } = useGetForeignUserQuery(accountId as string);

  if (!account || !user) {
    return <Loader />;
  }

  return (
    <FlexPage>
      {!goodsId && <AccountHeader account={account} user={user} />}
      <AccountTabs />
      <Outlet />
    </FlexPage>
  );
};
