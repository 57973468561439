import { MultyPostsIcon } from '@/app/icons/MultyPostsIcon';
import { Caption2 } from '@/shared/components';
import { Image as AntImage, ImageProps } from 'antd';
import styled, { css } from 'styled-components';

type TBottomImageInfo = {
  title: string;
  subTitle: string;
};

export type TImageProps = ImageProps & {
  scale?: boolean;
  showMulti?: boolean;
  bottomInfo?: TBottomImageInfo;
  rounded?: boolean;
};

const StyledImage = styled(AntImage)`
  object-fit: cover;
  transition: transform 0.2s ease-out;
`;

const Wrapper = styled.div<{ $scale: boolean; $rounded?: boolean }>`
  overflow: hidden;
  border-radius: 8px;
  position: relative;

  ${({ $rounded }) =>
    $rounded &&
    css`
      border-radius: 50%;
    `}

  .ant-image {
    width: 100%;
    img {
      width: 100%;
      aspect-ratio: 1/1;
    }
  }

  .multi-posts-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &:hover {
    ${({ $scale }) =>
      $scale &&
      css`
        cursor: pointer;
        ${StyledImage} {
          transform: scale(1.1);
        }
      `}
  }
`;

const BottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary[1]};
  padding: 6px;

  > div {
    color: ${({ theme }) => theme.palette.neutral[8]};
    line-height: 13px;
  }
`;

export const Image = ({ scale = true, showMulti, src, onClick, width, bottomInfo, rounded, ...props }: TImageProps) => (
  <Wrapper className="custom-image" style={{ width }} $scale={scale} onClick={onClick} $rounded={rounded}>
    <StyledImage src={src || '/avatar.svg'} preview={false} {...props} />
    {showMulti && <MultyPostsIcon className="multi-posts-icon" />}
    {bottomInfo && (
      <BottomInfo>
        <Caption2>{bottomInfo.title}</Caption2>
        <Caption2 weight="B">{bottomInfo.subTitle}</Caption2>
      </BottomInfo>
    )}
  </Wrapper>
);
