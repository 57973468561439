import { TRoute } from '@/app/navigation';
import { HomePage, HomePawsgram, MyShowRoom, NurseryPage, ProfilePage, SocialFeed } from '@/pages';
import { ShowRoomList, ShowRoomPetsGoods } from '@/pages/showroom';

export const HOME_PATHS = {
  home: '/',
  profile: '/profile',
  posts: '/posts',
  nursery: '/my-nursery',
  showroom: '/showroom',
} as const;

export const HOME_PRIVATE: TRoute = {
  path: HOME_PATHS.home,
  element: <HomePage />,
  nestedRoutes: [
    { index: true, element: <SocialFeed /> },
    { path: HOME_PATHS.profile, element: <ProfilePage /> },
    { path: HOME_PATHS.posts, element: <HomePawsgram /> },
    { path: HOME_PATHS.nursery, element: <NurseryPage /> },
    {
      path: HOME_PATHS.showroom,
      element: <MyShowRoom />,
      nestedRoutes: [
        { index: true, element: <ShowRoomList /> },
        {
          path: ':goodsId',
          element: <ShowRoomPetsGoods />,
        },
      ],
    },
  ],
};

export const HOME_PUBLIC: TRoute = {
  path: HOME_PATHS.home,
  element: <HomePage />,
  nestedRoutes: [{ index: true, element: <SocialFeed /> }],
};
