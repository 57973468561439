import { Flex } from 'antd';
import { Image } from '@/shared/ui';
import cn from 'classnames';
import { TGoodsImagesView } from './types';

export const MobileView = ({ activeImage, images, setActiveImage }: TGoodsImagesView) => (
  <Flex gap={16} vertical>
    <Image className="w-full" src={activeImage ? activeImage.url : '/goods-no-image.png'} />
    <Flex gap={16} className="w-full overflow-auto">
      {images.map(({ url, id }) => (
        <div key={id} className="w-[90px] h-[90px]">
          <Image
            onClick={() => setActiveImage({ id, url })}
            width={90}
            height={90}
            src={url}
            alt={id}
            className={cn({
              'border-4 border-primary1': activeImage?.id === id,
            })}
          />
        </div>
      ))}
    </Flex>
  </Flex>
);
