import { useParams } from 'react-router-dom';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Loader } from '@/app/icons';
import { MobileView } from './MobileView';
import { useMedia } from '@/shared/hooks';
import { DesktopView } from './DesktopView';

export const PetsGoods = () => {
  const { goodsId } = useParams();
  const { data, isFetching } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  const { isMobile } = useMedia();

  if (!data || isFetching) {
    return <Loader />;
  }

  return isMobile ? <MobileView data={data} /> : <DesktopView data={data} />;
};
