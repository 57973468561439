import { Body2, Popup, TPopupProps } from '@/shared/components';

type TProps = TPopupProps & {
  hideCancelBtn?: boolean;
};

export const NotificationPopup = ({
  children,
  onCancel,
  maskClosable = true,
  cancelButtonProps,
  hideCancelBtn,
  ...props
}: TProps) => {
  const cancelBtn = hideCancelBtn ? undefined : cancelButtonProps || { children: 'Закрыть окно', onClick: onCancel };

  return (
    <Popup
      size="SMALL"
      withFooterDivider={false}
      onCancel={onCancel}
      maskClosable={maskClosable}
      cancelButtonProps={cancelBtn}
      {...props}
    >
      <Body2>{children}</Body2>
    </Popup>
  );
};
