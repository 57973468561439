import { SVGProps } from 'react';

export const CloseCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 22.25C6.34614 22.25 1.75 17.6539 1.75 12C1.75 6.34614 6.34614 1.75 12 1.75C17.6539 1.75 22.25 6.34614 22.25 12C22.25 17.6539 17.6539 22.25 12 22.25ZM12 2.25C6.62386 2.25 2.25 6.62386 2.25 12C2.25 17.3761 6.62386 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62386 17.3761 2.25 12 2.25Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M9.34631 15.0064L9.34022 15.0125L9.33433 15.0188C9.30409 15.0512 9.24522 15.08 9.16986 15.08C9.10532 15.08 9.04471 15.0577 8.99342 15.0064C8.89868 14.9117 8.89868 14.7483 8.99342 14.6535L14.6534 8.99354C14.7482 8.8988 14.9116 8.8988 15.0063 8.99354C15.101 9.08828 15.101 9.25169 15.0063 9.34643L9.34631 15.0064Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M14.8299 15.58C14.6399 15.58 14.4499 15.51 14.2999 15.36L8.63986 9.69998C8.34986 9.40998 8.34986 8.92999 8.63986 8.63999C8.92986 8.34999 9.40986 8.34999 9.69986 8.63999L15.3599 14.3C15.6499 14.59 15.6499 15.07 15.3599 15.36C15.2099 15.51 15.0199 15.58 14.8299 15.58Z"
        fill="currentColor"
      />
    </svg>
  );
};
