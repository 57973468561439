import { Loader } from '@/app/icons';
import { LINK_ACCOUNT } from '@/app/navigation';
import { theme } from '@/app/theme';
import { TSubscriptionInfoDto, useGetRecommendationsQuery } from '@/entities/user-controller';
import { SubscribeButton } from '@/features/buttons';
import { Body2, Caption2, CustomAvatar } from '@/shared/components';
import { useHistory, useMedia } from '@/shared/hooks';
import { Flex } from 'antd';
import { CSSProp } from 'styled-components';

const textTransformStyles: CSSProp = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const RecommendationList = () => {
  const { data } = useGetRecommendationsQuery();
  const { isTablet } = useMedia();
  const navigate = useHistory();

  if (!data) {
    return <Loader style={{ color: theme.palette.neutral[8] }} />;
  }

  const text = (values: Partial<TSubscriptionInfoDto>) => {
    const { subscribersCount, subscriptionsCount, firstSubscriber } = values;

    if (!subscriptionsCount && !subscribersCount && !firstSubscriber) {
      return 'Новый пользователь';
    }

    const restSubscribers = subscribersCount && subscribersCount - 1;
    const subscribersCountText = restSubscribers ? `и ${restSubscribers}` : '';

    return `Подписаны: ${firstSubscriber} ${subscribersCountText}`;
  };

  const onOpenAccount = (id: string) => {
    navigate({ pathname: `${LINK_ACCOUNT}/${id}` });
  };

  return (
    <Flex vertical gap={10}>
      {data.map(({ id, avatar, name, ...rest }) => (
        <Flex gap={8} key={id}>
          <CustomAvatar
            round
            size="36px"
            src={avatar?.url}
            style={{ cursor: 'pointer' }}
            onClick={() => onOpenAccount(id)}
            name={name}
          />
          <Flex vertical={isTablet} justify="space-between" gap={10} style={{ flex: 1, overflow: 'hidden' }}>
            <Flex vertical style={{ flex: 1, overflow: 'hidden' }}>
              <Body2
                weight="SB"
                styles={{
                  color: theme.palette.neutral[8],
                  ...textTransformStyles,
                }}
              >
                {name}
              </Body2>
              <Caption2
                styles={{
                  color: theme.palette.neutral[4],
                  ...textTransformStyles,
                }}
              >
                {text(rest)}
              </Caption2>
            </Flex>
            <SubscribeButton
              accountId={id}
              subscribed={false}
              btnProps={{ icon: null, type: 'link', style: { fontWeight: 700 } }}
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
