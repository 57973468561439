import { HOME_PATHS } from '@/app/navigation';
import { selectIsBreeder, useTypedSelector } from '@/app/store';
import { TTabsProps, Tabs } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { useAuth } from '@/shared/hooks';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

export const HomePage = () => {
  const { pathname } = useLocation();
  const { goodsId } = useParams();
  const navigate = useNavigate();
  const { handleAuth } = useAuth();

  const onTabClick = (route: string) => {
    if (route === HOME_PATHS.home) {
      navigate(route);
    } else {
      handleAuth(() => navigate(route));
    }
  };

  const isBreeder = useTypedSelector(selectIsBreeder);

  const items: TTabsProps['items'] = [
    {
      key: HOME_PATHS.home,
      label: 'Лента',
    },
    {
      key: HOME_PATHS.posts,
      label: 'Публикации',
    },
    {
      key: goodsId ? `${HOME_PATHS.showroom}/${goodsId}` : HOME_PATHS.showroom,
      label: 'Шоу-рум',
      isHidden: !isBreeder,
    },
    {
      key: HOME_PATHS.nursery,
      label: 'Питомник',
      isHidden: !isBreeder,
    },
    {
      key: HOME_PATHS.profile,
      label: 'Профиль',
    },
  ];

  return (
    <FlexPage>
      <Tabs onTabClick={onTabClick} activeKey={pathname} items={items} />
      <Outlet />
    </FlexPage>
  );
};
