import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTokens } from '@/shared/hooks';
import { useCallback, useEffect } from 'react';
import { HOME_PATHS, ROUTE } from '@/app/navigation';
import { Typography } from '@/shared/components';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { useConfirmEmailMutation } from '@/entities/auth-controller';

const CONFIRMATION = 'Подтверждение email...';

export const ConfirmMailPage = () => {
  const navigate = useNavigate();
  const { updateTokens } = useTokens();

  const dispatch = useTypedDispatch();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;

  const [getTokens] = useConfirmEmailMutation();

  const onSubmit = useCallback(
    async (token: string) => {
      try {
        const { token: accessToken, refreshToken } = await getTokens(token).unwrap();
        updateTokens(accessToken, refreshToken);
        dispatch(setGlobalModal(MODALS.EMAIL_CONFIRMATION));
      } catch (e) {
        console.error(e);
      } finally {
        navigate(HOME_PATHS.home);
      }
    },
    [dispatch, getTokens, navigate, updateTokens],
  );

  useEffect(() => {
    onSubmit(token);
  }, [onSubmit, token]);

  return <Typography type="20B">{CONFIRMATION}</Typography>;
};
