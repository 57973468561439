import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const RIGHT_SIDEBAR_SLICE_NAME = 'rightSidebar';

const initialState: { isRightOpen: boolean } = {
  isRightOpen: false,
};

const rightSidebarSlice = createSlice({
  name: RIGHT_SIDEBAR_SLICE_NAME,
  initialState,
  reducers: {
    setRightSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.isRightOpen = payload;
    },
  },
});

export const { setRightSidebar } = rightSidebarSlice.actions;
export const rightSidebarReducer = rightSidebarSlice.reducer;
