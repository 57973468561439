import { SVGProps } from 'react';

export const CarouselArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.39961 7.42499L8.83294 12.8583C9.47461 13.5 10.5246 13.5 11.1663 12.8583L16.5996 7.42499"
      stroke="#8A91A9"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
