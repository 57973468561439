import { z } from 'zod';

export const TITLE = 'Добавить помет';

export const NAME = 'Буква помета';
export const FATHER_ID = 'Отец';
export const MOTHER_ID = 'Мать';
export const MATING_DATE = 'Дата вязки';
export const BIRTH_DATE = 'Дата рождения';
export const AWAITING = 'Показать в пометах, что ожидаем';

export const LITTER_FORM_FIELDS = {
  name: 'name',
  fatherId: 'fatherId',
  motherId: 'motherId',
  matingDate: 'matingDate',
  birthDate: 'birthDate',
  awaiting: 'awaiting',
} as const;

export const litterSchema = z.object({
  [LITTER_FORM_FIELDS.name]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.fatherId]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.motherId]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.matingDate]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.birthDate]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.awaiting]: z.boolean(),
});

export type TLitterSchema = z.infer<typeof litterSchema>;
