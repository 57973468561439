import { Outlet, useLocation } from 'react-router-dom';

import { GlobalModals } from '@/app/store/providers';
import styled from 'styled-components';
import { LeftSidebar } from './LeftSidebar';
import { RightSidebar } from './RightSidebar';
import { device } from '@/app/theme/device';
import { useMedia } from '@/shared/hooks/useMedia';
import { Flex } from 'antd';
import { MobileSidebars } from '@/widgets/layouts/Mobile';
import { MainSearch } from '@/widgets/MainSearch';
import { ROUTE, MARKETPLACE_PATHS } from '@/app/navigation';

const LayoutWrapper = styled(Flex)`
  height: 100svh;
  width: 100vw;
  overflow: hidden;
`;

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 32px;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.neutral[8]};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  @media screen and (${device.tablet}) {
    padding-top: 16px;
  }
`;

const OutletWrapper = styled(Flex)<{ $isMarketplaceWidth?: boolean }>`
  padding: 0 16px;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;

  > div {
    max-width: ${(props) => (props.$isMarketplaceWidth ? '1080px' : '540px')};
    width: 100%;
  }
`;

export const MainLayout = () => {
  const { isMobile } = useMedia();
  const { pathname } = useLocation();
  const isRegistration = pathname === ROUTE.REGISTRATION;
  const isMarketplacePage = pathname.includes(MARKETPLACE_PATHS.marketplace);

  return (
    <LayoutWrapper vertical={isMobile} justify="space-between">
      {isMobile && <MobileSidebars />}
      {!isMobile && <LeftSidebar />}
      <MainWrapper>
        {!isRegistration && !isMarketplacePage && <MainSearch />}
        <OutletWrapper justify="center" $isMarketplaceWidth={isMarketplacePage}>
          <Outlet />
        </OutletWrapper>
        <GlobalModals />
      </MainWrapper>
      {!isMobile && !isMarketplacePage && <RightSidebar />}
    </LayoutWrapper>
  );
};
