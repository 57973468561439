import { useCallback, useEffect, useRef } from 'react';

export const useDebounce = <T>(fn: (value: T) => void, timeoutDelay = 500) => {
  const ref = useRef<number>();

  useEffect(() => clearTimeout(ref.current));

  return useCallback(
    (...args: unknown[]) => {
      clearTimeout(ref.current);
      ref.current = window.setTimeout(fn, timeoutDelay, ...args);
    },
    [fn, timeoutDelay],
  );
};
