import { css, RuleSet } from 'styled-components';

export const pageContainer: RuleSet = css`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(8)};
`;

export const loaderContainer: RuleSet = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
