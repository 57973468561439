import { Navigate, RouteProps } from 'react-router-dom';

import { AnimalPage, ConfirmMailPage, NurseryPage, RegistrationPage, VkAuth, NotFoundPage } from '@/pages';

import { ROUTE } from './constants';
import { MARKETPLACE_ROUTES } from './marketplace';
import { LITTERS_ROUTES } from './litters';
import { HOME_PATHS, HOME_PRIVATE, HOME_PUBLIC } from '@/app/navigation/home';
import { ACCOUNT_ROUTES } from '@/app/navigation/account';

export type TRoute = RouteProps & {
  nestedRoutes?: TRoute[];
};

const commonRoutes: TRoute[] = [
  { path: ROUTE.NOT_FOUND, element: <NotFoundPage /> },
  { path: ROUTE.OTHER, element: <Navigate to={HOME_PATHS.home} /> },
  ACCOUNT_ROUTES,
  { path: ROUTE.PETS, element: <AnimalPage /> },
  LITTERS_ROUTES,
  MARKETPLACE_ROUTES,
];

// We place the ROUTE.HOME at the end of the arrays because we are matching paths to display the right sidebar
export const privateRoutes: TRoute[] = [
  { path: ROUTE.NURSERY, element: <NurseryPage /> },
  HOME_PRIVATE,
  ...commonRoutes,
];

export const publicRoutes: TRoute[] = [
  { path: ROUTE.REGISTRATION, element: <RegistrationPage /> },
  { path: ROUTE.CONFIRM_MAIL, element: <ConfirmMailPage /> },
  { path: ROUTE.VK_AUTH, element: <VkAuth /> },
  HOME_PUBLIC,
  ...commonRoutes,
];
