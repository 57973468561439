import { useState } from 'react';
import { EmailStepModal, ChangePasswordModal, ConfirmPinModal, PasswordResetModal } from '@/features/modals';

enum STEPS {
  EMAIL,
  CONFIRM_PIN,
  CHANGE_PASSWORD,
  PASSWORD_RESET,
}

type TProps = {
  onClose: () => void;
};
export const ForgetPassword = ({ onClose }: TProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.EMAIL);
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const onSubmitEmailStep = (value: string) => {
    setEmail(value);
    setStep(STEPS.CONFIRM_PIN);
  };

  const onSubmitPinStep = (token: string) => {
    setToken(token);
    setStep(STEPS.CHANGE_PASSWORD);
  };

  const onSubmitChangePassword = () => {
    setStep(STEPS.PASSWORD_RESET);
  };

  return (
    <>
      {step === STEPS.EMAIL && <EmailStepModal onSubmit={onSubmitEmailStep} onClose={onClose} />}
      {step === STEPS.CONFIRM_PIN && <ConfirmPinModal email={email} onSubmit={onSubmitPinStep} onClose={onClose} />}
      {step === STEPS.CHANGE_PASSWORD && (
        <ChangePasswordModal onSubmit={onSubmitChangePassword} token={token} onClose={onClose} />
      )}
      {step === STEPS.PASSWORD_RESET && <PasswordResetModal onClose={onClose} />}
    </>
  );
};
