import { SVGProps } from 'react';

export const OutlineClose = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.7123 16.773L7.22699 8.28768C6.93708 7.99776 6.93708 7.51693 7.22699 7.22702C7.51691 6.9371 7.99774 6.9371 8.28765 7.22702L16.7729 15.7123C17.0628 16.0022 17.0628 16.483 16.7729 16.773C16.483 17.0629 16.0022 17.0629 15.7123 16.773Z"
        fill="currentColor"
      />
      <path
        d="M7.58062 16.4194C7.48597 16.3248 7.48597 16.1605 7.58062 16.0659L16.0659 7.58057C16.1606 7.48592 16.3248 7.48592 16.4195 7.58057C16.5141 7.67522 16.5141 7.83947 16.4195 7.93412L7.93417 16.4194C7.83952 16.5141 7.67527 16.5141 7.58062 16.4194Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
