import { TGoodDto, TPageGoodDto } from '@/entities/goods-controller';
import { InfinityContainer, List, SliderContainer } from '@/shared/ui';
import { GoodCard } from '@/features/marketplace';
import { Dispatch, SetStateAction } from 'react';

type TProps = {
  isSliderView?: boolean;
  slidesToShow: number;
  title?: string;
  emptyText?: string;
  onClick: (good: TGoodDto) => void;
  data?: TPageGoodDto;
  setPage: Dispatch<SetStateAction<number>>;
  isFetching: boolean;
};

export const GoodsCardsList = ({
  isSliderView,
  isFetching,
  data,
  setPage,
  slidesToShow,
  title,
  onClick,
  emptyText,
}: TProps) => {
  const renderedCards = (goods: TGoodDto[]) => (
    <List
      emptyText={emptyText}
      grid={{ gutter: 16, column: slidesToShow }}
      dataSource={goods}
      customItem={(item) => <GoodCard onClick={onClick} good={item} />}
    />
  );

  if (isSliderView) {
    return (
      <SliderContainer
        title={title}
        data={data}
        slidesToShow={slidesToShow}
        setPage={setPage}
        renderedSlide={renderedCards}
      />
    );
  }

  return (
    <InfinityContainer
      title={title}
      setPage={setPage}
      data={data}
      isFetching={isFetching}
      renderedItem={renderedCards}
    />
  );
};
