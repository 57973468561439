import { Button, List } from '@/shared/ui';
import { useModal } from '@/shared/hooks';
import { Flex } from 'antd';
import { AddLitterIndividualModal } from '@/features/litters/add-litter-individual-modal';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { useParams } from 'react-router-dom';
import { LitterIndividualsCard } from '@/pages/nursery/litters/litters-details/ui/litter-individuals-card';
import { queries } from '@/shared/utils';
import { useGetAnimalsQuery } from '@/entities/animal-controller';
import { Loader } from '@/app/icons';

type TProps = {
  isList: boolean;
};

export const LitterIndividuals = ({ isList }: TProps) => {
  const { open, close, isOpen } = useModal();
  const { id } = useParams();
  const { data: currentUser } = useGetCurrentUserQuery();
  const speciesId = currentUser?.nurseries[0].species.id;
  const nurseryId = currentUser?.nurseries[0].id;
  const query = queries.equal('litter.id', id as string);
  const { data = [], isFetching: isPetsFetching } = useGetAnimalsQuery({ query });

  if (isPetsFetching) {
    return <Loader />;
  }

  return (
    <Flex vertical gap={16}>
      <List
        grid={{ gutter: 16, column: isList ? 1 : 3 }}
        itemLayout={isList ? 'vertical' : 'horizontal'}
        dataSource={data}
        customItem={(individual) => <LitterIndividualsCard key={individual.id} animal={individual} isList={isList} />}
      />

      <Button size="x-small" onClick={open}>
        Добавить особь
      </Button>
      {speciesId && isOpen && nurseryId && (
        <AddLitterIndividualModal
          litter={Number(id)}
          nurseryId={nurseryId}
          speciesId={speciesId}
          open={isOpen}
          onCancel={close}
        />
      )}
    </Flex>
  );
};
