import { Flex } from 'antd';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { useMedia } from '@/shared/hooks';
import { ButtonActions } from '../goods-actions';

type TProps = {
  goodData: TGoodDtoExt;
};

export const GoodsFooter = ({ goodData }: TProps) => {
  const { isMobile } = useMedia();
  if (!isMobile) {
    return null;
  }
  return (
    <Flex
      vertical
      className="py-xs px-xs bg-neutral9 rounded-t-xxs shadow-neutral2 absolute left-0 right-0 bottom-0"
      justify="center"
      align="center"
      gap={8}
    >
      <ButtonActions goodData={goodData} />
    </Flex>
  );
};
