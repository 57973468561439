import { SVGProps } from 'react';

export const SubscribeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.33301 8.5H10.6663" stroke="#FBFCFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 11.1667V5.83337" stroke="#FBFCFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.99967 15.1667H9.99967C13.333 15.1667 14.6663 13.8334 14.6663 10.5V6.50004C14.6663 3.16671 13.333 1.83337 9.99967 1.83337H5.99967C2.66634 1.83337 1.33301 3.16671 1.33301 6.50004V10.5C1.33301 13.8334 2.66634 15.1667 5.99967 15.1667Z"
      stroke="#FBFCFE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
