import { primary } from './primary';
import { secondary } from './secondary';
import { neutral } from './neutral';
import { common } from './common';

export const palette = {
  primary,
  secondary,
  neutral,
  common,
};
