import { NurseryPage } from '@/pages';
import { useParams } from 'react-router-dom';
import { Loader } from '@/app/icons';
import { useGetAccountQuery } from '@/entities/user-controller';

export const AccountNursery = () => {
  const { accountId } = useParams();
  const { data: account } = useGetAccountQuery(String(accountId), { skip: !accountId });
  const nursery = account?.nursery;

  if (!nursery) {
    return <Loader />;
  }

  return <NurseryPage nursery={nursery} />;
};
