import { TPedigreeConverted, TPedigreeDto } from '@/entities/animal-controller/types';
import { typedObjectEntries } from '@/shared/utils';

export const convertPedigreeResponse = (pedigree: TPedigreeDto): TPedigreeConverted[] => {
  const { animal } = pedigree;
  return [
    {
      animalData: animal,
      title: 'DAD',
      imageProps: { width: 166, height: 172 },
      parents: [
        {
          animalData: animal,
          title: 'DAD',
          imageProps: { width: 60, height: 61 },
          parents: [
            { animalData: animal, title: 'DAD', parents: null },
            { animalData: animal, title: 'MOM', parents: null },
          ],
        },
        {
          animalData: animal,
          title: 'MOM',
          imageProps: { width: 60, height: 61 },
          parents: [
            { animalData: animal, title: 'DAD', parents: null },
            { animalData: animal, title: 'MOM', parents: null },
          ],
        },
      ],
    },
    {
      animalData: animal,
      title: 'MOM',
      imageProps: { width: 166, height: 172 },
      parents: [
        {
          animalData: animal,
          title: 'DAD',
          imageProps: { width: 60, height: 61 },
          parents: [
            { animalData: animal, title: 'DAD', parents: null },
            { animalData: animal, title: 'MOM', parents: null },
          ],
        },
        {
          animalData: animal,
          title: 'MOM',
          imageProps: { width: 60, height: 61 },
          parents: [
            { animalData: animal, title: 'DAD', parents: null },
            { animalData: animal, title: 'MOM', parents: null },
          ],
        },
      ],
    },
  ];
  // const { animal: _, ...parents } = pedigree;
  // const { father, mother } = parents;
  //
  // if (!father && !mother) {
  //   return null;
  // }
  //
  // const getObject = (data: { mother: TPedigreeDto | null; father: TPedigreeDto | null }, level: number) =>
  //   typedObjectEntries(data).map(([key, value]) => {
  //     if (!value) {
  //       return null;
  //     }
  //
  //     const { chipId, color, images } = value.animal;
  //     const { father, mother } = value;
  //
  //     const result: TPedigreeConverted = {
  //       animalData: value.animal,
  //       chipId,
  //       color: color?.name,
  //       parents: null,
  //     };
  //
  //     if (level === 1) {
  //       const title = key === 'father' ? 'Отец' : 'Мать';
  //       const subTitle = key === 'father' ? 'Sir' : 'Dam';
  //
  //       result.imageProps = { url: images[0]?.url, width: 166, height: 172 };
  //       result.title = title;
  //       result.subTitle = subTitle;
  //     }
  //
  //     if (level === 2) {
  //       result.imageProps = { url: images[0]?.url, width: 60, height: 61 };
  //     }
  //
  //     result.parents = level === 3 || (!father && !mother) ? null : getObject({ father, mother }, level + 1);
  //
  //     return result;
  //   });
  //
  // return getObject({ father, mother }, 1);
};
