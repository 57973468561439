import React, { useMemo } from 'react';
import { Breadcrumb as AntBreadcrumb, Flex, BreadcrumbProps } from 'antd';
import { BreadcrumbSeparatorArrow } from '@/app/icons';
import { Link } from 'react-router-dom';
import { Body2 } from '@/shared/components';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import styles from './styles.module.scss';

export type TBreadcrumbProps = Omit<BreadcrumbProps, 'items'> & {
  items: (ItemType & { isHidden?: boolean })[];
};

const itemRender = ({ title, path }: ItemType, _params: unknown, items: ItemType[]) => {
  const isLast = path === items[items.length - 1]?.path;

  return isLast ? (
    <Body2 className="text-neutral4">{title}</Body2>
  ) : (
    <Link to={path || ''}>
      <Body2 className="text-neutral4">{title}</Body2>
    </Link>
  );
};
export const Breadcrumb = ({ items, ...props }: TBreadcrumbProps) => {
  const visibleItems = useMemo(() => items.filter(({ isHidden }) => !isHidden), [items]);

  if (!visibleItems.length) {
    return null;
  }

  return (
    <AntBreadcrumb
      separator={
        <Flex style={{ height: '24px' }} align="center">
          <BreadcrumbSeparatorArrow />
        </Flex>
      }
      className={styles['custom-breadcrumb']}
      itemRender={itemRender}
      items={visibleItems}
      {...props}
    />
  );
};
