import { Body2, Headline1 } from '@/shared/components';
import { RegisterForm } from '@/widgets/registration';
import { Flex } from 'antd';

const REGISTER_TITLE =
  'Регистрация позволит Вам получить доступ ко всем функциям и возможностям, недоступным для не зарегистрированных пользователей.';
const REGISTRATION = 'Регистрация';

export const RegistrationPage = () => {
  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <Headline1>{REGISTRATION}</Headline1>
        <Body2>{REGISTER_TITLE}</Body2>
      </Flex>
      <RegisterForm />
    </Flex>
  );
};
