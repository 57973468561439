import { SVGProps } from 'react';

export const BreadcrumbSeparatorArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.96755 13.28L10.3142 8.9333C10.8276 8.41997 10.8276 7.57997 10.3142 7.06664L5.96755 2.71997"
        stroke="#212029"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
