import { useModal } from '@/shared/hooks';
import { Button } from '@/shared/components';
import { animalApi, TAnimalDto } from '@/entities/animal-controller';
import { AddShowroomProductModal } from '@/features/show-room';
import { updateShowRoomCache, useDeleteGoodsMutation } from '@/entities/goods-controller';
import { selectCurrentAccountId, useTypedDispatch } from '@/app/store';
import { useSelector } from 'react-redux';

const PUBLISH_BTN = 'Опубликовать в маркетплейс';
const DELETE_BTN = 'Снять с продажи';

type TProps = {
  animal: TAnimalDto;
};

export const PublishMarketplaceButton = ({ animal }: TProps) => {
  const { canPublishToMarket, goodId, canEdit } = animal;
  const { isOpen: isPublishOpen, close: closePublish, open: openPublish } = useModal();
  const [deleteGoods, { isLoading }] = useDeleteGoodsMutation();
  const dispatch = useTypedDispatch();
  const accountId = useSelector(selectCurrentAccountId);

  const buttonHandler = async () => {
    if (!accountId) {
      return;
    }
    if (!goodId) {
      return openPublish();
    }
    try {
      await deleteGoods(goodId);
      dispatch(updateShowRoomCache(goodId, accountId));
      dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: animal.id }]));
    } catch (e) {
      console.error(e);
    }
  };

  const btnText = canPublishToMarket ? PUBLISH_BTN : DELETE_BTN;

  return (
    <>
      {canEdit && (
        <Button loading={isLoading} onClick={buttonHandler}>
          {btnText}
        </Button>
      )}
      {isPublishOpen && (
        <AddShowroomProductModal
          speciesId={animal.species.id}
          open={isPublishOpen}
          onCancel={closePublish}
          defaultValues={{ animal: animal.id }}
        />
      )}
    </>
  );
};
