import { CustomAvatar, TUploadFile, Upload } from '@/shared/components';
import { EditIcon } from '@/app/icons';
import styled from 'styled-components';

const StyledProfileAvatar = styled.div`
  position: relative;
`;

const StyledUpload = styled(Upload)`
  position: absolute;
  top: 0;
  right: -20px;
  cursor: pointer;
`;

export const ProfileAvatar = ({
  url,
  onChange,
  name,
}: {
  url?: string;
  onChange?: (value: TUploadFile) => void;
  name: string;
}) => {
  return (
    <StyledProfileAvatar>
      <CustomAvatar size="100px" src={url} name={name} />
      {onChange && (
        <StyledUpload handleChange={(value: TUploadFile[]) => onChange(value[0])}>
          <EditIcon />
        </StyledUpload>
      )}
    </StyledProfileAvatar>
  );
};
