import styled, { css } from 'styled-components';
import { theme } from '@/app/theme';
import { TopArrowDown, LogoWord, MobileloginIcon } from '@/app/icons';
import { Flex } from 'antd';
import { useNavMenu } from '../LeftSidebar/hooks';
import { LogoItem } from '../LogoItem';
import { RightSidebar } from '../RightSidebar';
import { useModal } from '@/shared/hooks';
import { useTypedDispatch, setRightSidebar, useTypedSelector, selectIsRightOpen } from '@/app/store';

const Top = styled(Flex)<{ $isRightOpen: boolean }>`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.neutral[3]};
  z-index: 50;

  .right-sidebar {
    position: absolute;
    right: 0;
    top: 64px;
    width: 100vw;
    border-radius: 0;
    height: 0;
    padding: 0 16px;

    transition: all 0.2s ease-out;
    visibility: hidden;
  }

  ${({ $isRightOpen }) =>
    $isRightOpen &&
    css`
      .right-sidebar {
        visibility: visible;
        padding: 16px;
        height: calc(100svh - 64px);
      }
    `}
`;

const NavMenu = styled(Flex)<{ $isOpen: boolean }>`
  width: 100%;
  top: 64px;
  height: 0;
  left: 0;
  padding: 16px;
  position: absolute;
  transition: all 0.2s ease-out;
  visibility: hidden;
  background-color: ${({ theme }) => theme.palette.neutral[3]};

  .nav-item {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      .nav-item {
        visibility: visible;
        height: 48px;
        opacity: 1;
      }
      visibility: visible;
      height: calc(100svh - 64px);
    `}
`;

const Arrow = styled(TopArrowDown)<{ $isOpen: boolean }>`
  transition: all 0.2s ease-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

const Login = styled(MobileloginIcon)<{ $isOpen: boolean }>`
  transition: all 0.2s ease-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const MobileSidebars = () => {
  const { isOpen: isLeftOpen, open: openLeftSide, close: closeLeftSide } = useModal();
  const { navMenuItems } = useNavMenu();
  const isRightOpen = useTypedSelector(selectIsRightOpen);
  const dispatch = useTypedDispatch();

  const onCloseSidebars = () => {
    closeLeftSide();
    dispatch(setRightSidebar(false));
  };

  return (
    <Top $isRightOpen={isRightOpen} vertical>
      <Flex justify="space-between" align="center">
        <Flex gap={8} align="center">
          <LogoItem onClick={onCloseSidebars} />
          <Flex
            gap={8}
            align="center"
            onClick={() => {
              isLeftOpen ? closeLeftSide() : openLeftSide();
              dispatch(setRightSidebar(false));
            }}
          >
            <LogoWord cursor="pointer" width={104} color={theme.palette.neutral[8]} />
            <Arrow $isOpen={isLeftOpen} cursor="pointer" />
          </Flex>
        </Flex>
        <Login
          $isOpen={isRightOpen}
          onClick={() => {
            dispatch(setRightSidebar(!isRightOpen));
            closeLeftSide();
          }}
        />
      </Flex>
      <NavMenu onClick={onCloseSidebars} vertical gap={16} $isOpen={isLeftOpen}>
        {navMenuItems}
      </NavMenu>
      <RightSidebar />
    </Top>
  );
};
