import { StrictMode, useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import { store, useTypedDispatch, useTypedSelector, resetError } from '@/app/store';
import { ThemeProvider } from '@/app/theme';

import { AnalyticInit } from '@/app/google';
import { YandexMetrika } from '@/app/yandex';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { Alert } from 'antd';
import styled from 'styled-components';

import { App } from '@/app/App';

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 24px;
  width: 640px;
  margin: 0 auto;
  z-index: 10000;
`;

const SHOW_TIME = 5000;

export const AlertError = () => {
  const dispatch = useTypedDispatch();

  const [open, setOpen] = useState(false);

  const { error } = useTypedSelector((state) => {
    return state.error;
  });

  useEffect(() => {
    if (error?.message) {
      setOpen(true);
    }
  }, [error?.message]);

  const handleCloseError = useCallback(() => {
    setOpen(false);
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
        dispatch(resetError());
        clearTimeout(timer);
      }, SHOW_TIME);
    }
  }, [open, dispatch]);

  if (!open || !error) return null;

  return (
    <StyledWrapper>
      <Alert message={error.message} type="error" showIcon closable onClick={handleCloseError} />
    </StyledWrapper>
  );
};

AnalyticInit();

const root = createRoot(document.getElementById('root') as HTMLElement, {});
const googleCode = process.env.REACT_APP_GOOGLE_APP || '';
root.render(
  <StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <ThemeProvider>
        <BrowserRouter>
          <Provider store={store}>
            <GoogleOAuthProvider clientId={googleCode}>
              <App />
            </GoogleOAuthProvider>
            <YandexMetrika />
            <AlertError />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  </StrictMode>,
);
