import { TRoute } from '@/app/navigation';
import { LitterDetails } from '@/pages/nursery';

const DYNAMIC_PARAMS = {
  id: 'id',
} as const;

const LITTERS = '/litters';
const LITTER_DETAILS = `${LITTERS}/:${DYNAMIC_PARAMS.id}`;

export const LITTERS_PATHS = {
  litters: LITTERS,
  litterDetails: LITTER_DETAILS,
} as const;

export const LITTERS_ROUTES: TRoute = {
  path: LITTERS_PATHS.litters,
  nestedRoutes: [
    {
      path: LITTERS_PATHS.litterDetails,
      element: <LitterDetails />,
    },
  ],
};
