import { NotificationPopup } from '@/shared/components';

const REGISTRATION_FINISHED = 'Регистрация завершена';
const CONTINUE = 'Продолжить';

type TProps = {
  onClose: () => void;
};

export const EmailConfirmationModal = ({ onClose }: TProps) => (
  <NotificationPopup
    open
    title={REGISTRATION_FINISHED}
    hideCancelBtn
    okButtonProps={{ children: CONTINUE, onClick: onClose }}
  >
    <p>
      Поздравляем! Вы успешно создали учетную запись BREED SHOW. Теперь вы можете воспользоваться всеми функциями
      проекта без органичений.
    </p>
  </NotificationPopup>
);
