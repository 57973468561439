import { Popup } from '@/shared/components';
import { FormProvider, useForm } from 'react-hook-form';
import { NURSERY_FORM_FIELDS, NurseryCreate, nurserySchema, TNurserySchema } from '@/features/nursery';
import { zodResolver } from '@hookform/resolvers/zod';
import { SPECIES } from '@/entities/species-controller';
import { TNurseryDto, usePostNurseryMutation } from '@/entities/nursery-controller';

const TITLE = 'Добавить питомник';

type TProps = {
  onCancel: () => void;
  speciesId: SPECIES;
  onSubmit: (data: TNurseryDto) => void;
  disabledFields?: (keyof typeof NURSERY_FORM_FIELDS)[];
};

export const AddNurseryModal = ({ onCancel, speciesId, onSubmit, disabledFields }: TProps) => {
  const [createNursery, { isLoading }] = usePostNurseryMutation();

  const formMethods = useForm<TNurserySchema>({
    defaultValues: {
      speciesId,
    },
    resolver: zodResolver(nurserySchema),
  });

  const { handleSubmit } = formMethods;

  const onFormSubmit = async (data: TNurserySchema) => {
    try {
      const response = await createNursery(data).unwrap();
      onSubmit(response);
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Popup
      open
      onCancel={onCancel}
      title={TITLE}
      size="SMALL"
      cancelButtonProps={{
        children: 'Отменить',
        onClick: onCancel,
      }}
      okButtonProps={{
        children: 'Добавить',
        onClick: handleSubmit(onFormSubmit),
        loading: isLoading,
      }}
    >
      <FormProvider {...formMethods}>
        <NurseryCreate disabledFields={disabledFields} />
      </FormProvider>
    </Popup>
  );
};
