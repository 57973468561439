import styled, { css } from 'styled-components';
import { Flex } from 'antd';
import { ExitIcon, Loader, Success } from '@/app/icons';
import { theme } from '@/app/theme';
import { Body2, Image } from '@/shared/components';
import { size } from '@/app/theme/device';
import { useTokens } from '@/shared/hooks';
import { useSwitchAccount } from '@/shared/hooks/account';

const Wrapper = styled(Flex)`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.neutral[3]};
  padding: 32px;
  margin: 0 -32px -32px -32px;

  @media screen and (max-width: ${size.tabletMaxWidth}) {
    padding: 16px;
    margin: 0 -16px -16px -16px;
  }
`;

const ActionBlock = styled.div<{ $active?: boolean }>`
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(251, 252, 254, 0.1);
  height: 48px;
  padding: 0 8px;
  align-items: center;
  column-gap: 10px;

  ${({ $active }) =>
    $active &&
    css`
      cursor: unset;
      background-color: ${({ theme }) => theme.palette.primary[1]};
    `}
`;

const Text = styled(Body2)`
  ${({ theme }) => theme.fontWeight.SB}
  color: ${({ theme }) => theme.palette.neutral[9]}}
`;

const iconColor = theme.palette.neutral[9];

export const AccountActions = ({ onBack }: { onBack: () => void }) => {
  const { accounts, handleSwitchAccount, isActiveAccount } = useSwitchAccount();
  const { removeTokens } = useTokens();

  if (!accounts) {
    return <Loader color={iconColor} />;
  }

  const defaultActions = [
    {
      icon: <ExitIcon color={iconColor} />,
      text: 'Вернуться назад',
      action: onBack,
    },
    {
      icon: <ExitIcon color={iconColor} />,
      text: 'Выйти',
      action: removeTokens,
    },
  ];

  return (
    <Wrapper>
      <Flex vertical gap={12} style={{ width: '100%' }}>
        {accounts.map(({ avatar, name, id }) => (
          <ActionBlock key={id} $active={isActiveAccount(id)} onClick={() => handleSwitchAccount(id)}>
            <Image src={avatar?.url} scale={false} rounded width={32} />
            <Text>{name}</Text>
            <Success color={iconColor} style={{ marginLeft: 'auto' }} />
          </ActionBlock>
        ))}
        {defaultActions.map(({ icon, text, action }) => (
          <ActionBlock key={text} onClick={action}>
            {icon}
            <Text>{text}</Text>
          </ActionBlock>
        ))}
      </Flex>
    </Wrapper>
  );
};
