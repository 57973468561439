import { useParams } from 'react-router-dom';
import { Pawsgram } from '@/widgets/pawsgram';

export const AccountPosts = () => {
  const { accountId } = useParams();

  if (!accountId) {
    return null;
  }

  return <Pawsgram accountId={accountId} />;
};
