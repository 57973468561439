import { createSearchParams, NavigateOptions, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export const useHistory = () => {
  const navigateFunc = useNavigate();

  return useCallback(
    (args: { pathname: string; search?: Record<string, string> } | string, options?: NavigateOptions) => {
      const getToProps = () => {
        if (typeof args === 'string') {
          return args;
        }

        const { pathname, search } = args;

        return {
          pathname,
          search: createSearchParams(search).toString(),
        };
      };

      navigateFunc(getToProps(), options);
    },
    [navigateFunc],
  );
};
