import { Headline4, Images, Popup } from '@/shared/components';
import { TImageInfoDto } from '@/entities/file-controller';
import { theme } from '@/app/theme';
import { useModal } from '@/shared/hooks';
import { useUploadAnimalImages } from '@/features/animal';
import { AnimalAvatar } from '@/app/icons/AnimalAvatar';

type TProps = {
  images: TImageInfoDto[];
  animalId: string;
  canEdit: boolean;
};

export const AnimalImagesList = ({ images, animalId, canEdit }: TProps) => {
  const showImages = images.slice(0, 5);
  const restImagesLength = images.length - showImages.length;
  const { upload, isLoading } = useUploadAnimalImages();

  const { isOpen, close, open } = useModal();

  const addIcon = <Headline4 styles={{ color: theme.palette.neutral[6] }}>+{restImagesLength}</Headline4>;

  const onAddBtnClick = images.length - showImages.length && canEdit ? open : undefined;

  return (
    <>
      {images.length ? (
        <Images
          images={showImages.map(({ url }) => ({ src: url, scale: false }))}
          onAddBtnClick={onAddBtnClick}
          addIcon={addIcon}
        />
      ) : (
        <AnimalAvatar />
      )}
      {isOpen && (
        <Popup title="Медиа" open maskClosable onCancel={close} withFooterDivider={false}>
          <Images
            images={images.map(({ url }) => ({ src: url, scale: false }))}
            onUploadHandler={(file) => upload(animalId, file)}
            isLoading={isLoading}
          />
        </Popup>
      )}
    </>
  );
};
