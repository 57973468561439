import { NotificationPopup } from '@/shared/components';
import { Flex } from 'antd';

const TITLE = 'Подтверждение регистрации';
const SEND = 'Отправить повторно';
const CLOSE = 'Закрыть';

type TProps = {
  isLoading: boolean;
  onSend: () => void;
  onCancel: () => void;
};

export const RegistrationModal = ({ isLoading, onSend, onCancel }: TProps) => (
  <NotificationPopup
    title={TITLE}
    open
    onCancel={onCancel}
    cancelButtonProps={{
      children: SEND,
      onClick: onSend,
      loading: isLoading,
    }}
    okButtonProps={{
      children: CLOSE,
      onClick: onCancel,
    }}
  >
    <Flex vertical gap={20}>
      <p>Для активации Вашего аккаунта Вам необходимо подтвердить адрес электронной почты.</p>
      <p>Пройдите по ссылке, отправленной Вам в письме.</p>
      <p>Если Вы не получили письмо, повторно отправьте его, нажав кнопку ниже.</p>
    </Flex>
  </NotificationPopup>
);
