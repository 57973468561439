import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { Profile } from '@/widgets/profile';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 540px;
`;

export const ProfilePage = () => {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser) {
    return null;
  }

  return (
    <Wrapper>
      <Profile user={currentUser} />
    </Wrapper>
  );
};
