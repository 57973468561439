import { TCommentDto, TCreateCommentDto, TPutCommentDto } from './types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@/app/core';

const COMMENTS_PATH = 'comments';

export const commentApi = createApi({
  reducerPath: 'commentApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['COMMENT'],
  endpoints: (builder) => ({
    getPostComments: builder.query<TCommentDto[], string>({
      query: (postId) => ({
        url: COMMENTS_PATH,
        method: 'GET',
        params: {
          postId,
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'COMMENT' as const, id })), { type: 'COMMENT', id: `POST_${arg}` }]
          : [],
    }),
    postComment: builder.mutation<TCommentDto, TCreateCommentDto>({
      query: ({ postId, text }) => ({
        url: COMMENTS_PATH,
        method: 'POST',
        data: { postId, text },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'COMMENT', id: `POST_${result.postId}` }] : []),
    }),
    updateComment: builder.mutation<TCommentDto, TPutCommentDto>({
      query: ({ commentId, text }) => ({
        url: `${COMMENTS_PATH}/${commentId}`,
        method: 'PUT',
        data: { text },
      }),
    }),
    deleteComment: builder.mutation<void, string>({
      query: (id) => ({
        url: `${COMMENTS_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'COMMENT', id: arg }],
    }),
    postCommentLike: builder.mutation<void, string>({
      query: (commentId) => ({
        url: `${COMMENTS_PATH}/${commentId}/likes`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'COMMENT', id: arg }],
    }),
    deleteCommentLike: builder.mutation<void, string>({
      query: (commentId) => ({
        url: `${COMMENTS_PATH}/${commentId}/likes`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'COMMENT', id: arg }],
    }),
  }),
});

export const {
  useGetPostCommentsQuery,
  useLazyGetPostCommentsQuery,
  usePostCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  usePostCommentLikeMutation,
  useDeleteCommentLikeMutation,
} = commentApi;
