import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTop = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [pathname]);

  return topRef;
};
