import { ResetPasswordModal } from './ResetPasswordModal';
import { ColumnWrapper } from '@/shared/containers';
import { Input } from '@/shared/components';
import { Controller, useForm } from 'react-hook-form';
import { useConfirmPinMutation, useForgotPasswordMutation } from '@/entities/auth-controller';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { pinRule } from '@/shared/validation';
import { useResendPinTimer } from './hooks';
import { Flex } from 'antd';

const TITLE = 'Введите код подтверждения';
const CONFIRM = 'Подтвердить';
const REPEAT_SEND = 'Получить повторно';

const FORM_FIELDS = {
  restorePin: 'restorePin',
  email: 'email',
} as const;

type TProps = {
  onClose: () => void;
  onSubmit: (token: string) => void;
  email: string;
};

export const ConfirmPinModal = ({ onClose, onSubmit, email }: TProps) => {
  const { control, handleSubmit } = useForm<{ restorePin: string }>({
    resolver: zodResolver(
      z.object({
        [FORM_FIELDS.restorePin]: pinRule,
      }),
    ),
  });

  const [sendPin, { isLoading: isResendLoading }] = useForgotPasswordMutation();
  const [confirmPin, { isLoading }] = useConfirmPinMutation();
  const { timeInSeconds, ResendTimerJSX, handleTimer } = useResendPinTimer();

  const onSendPin = async () => {
    try {
      await sendPin({ email }).unwrap();
    } catch (e) {
      console.error(e);
    }
  };

  const onResend = () => {
    handleTimer(onSendPin);
  };

  const onSubmitHandler = async (data: { restorePin: string }) => {
    try {
      const token = await confirmPin({ ...data, email }).unwrap();
      onSubmit(token);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ResetPasswordModal
      title={TITLE}
      onCancel={onClose}
      cancelButtonProps={{
        children: REPEAT_SEND,
        loading: isResendLoading,
        disabled: !!timeInSeconds,
        onClick: onResend,
      }}
      okButtonProps={{ children: CONFIRM, loading: isLoading, onClick: handleSubmit(onSubmitHandler) }}
    >
      <ColumnWrapper>
        <p>Введите 6-значный код подтверждения, который мы отправили Вам на электронную почту.</p>
        <Flex vertical gap={12}>
          <Controller
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input {...field} errorMsg={error?.message} autoComplete="off" />
            )}
            name={FORM_FIELDS.restorePin}
          />
          {!!timeInSeconds && <ResendTimerJSX />}
        </Flex>
      </ColumnWrapper>
    </ResetPasswordModal>
  );
};
