import { SVGProps } from 'react';

export const TelegramShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24C47.5 36.9787 36.9787 47.5 24 47.5Z"
      fill="#FBFCFE"
      stroke="#212029"
    />
    <path
      d="M12.1499 23.5624C18.5923 20.7556 22.8883 18.9051 25.0378 18.0111C31.175 15.4584 32.4503 15.0149 33.2815 15.0001C33.4643 14.9971 33.8731 15.0424 34.1379 15.2572C34.3614 15.4386 34.4229 15.6837 34.4524 15.8557C34.4818 16.0277 34.5185 16.4195 34.4893 16.7257C34.1567 20.2201 32.7177 28.7002 31.9856 32.6141C31.6758 34.2702 31.0658 34.8254 30.4753 34.8798C29.1919 34.9979 28.2174 34.0317 26.9744 33.2169C25.0294 31.9419 23.9305 31.1482 22.0425 29.904C19.8606 28.4662 21.2751 27.6759 22.5185 26.3844C22.844 26.0464 28.4985 20.9032 28.6079 20.4366C28.6216 20.3783 28.6343 20.1608 28.5051 20.0459C28.3759 19.9311 28.1851 19.9703 28.0475 20.0016C27.8524 20.0458 24.7452 22.0996 18.7258 26.1628C17.8439 26.7685 17.045 27.0636 16.3292 27.0481C15.5402 27.0311 14.0223 26.602 12.894 26.2352C11.51 25.7853 10.41 25.5474 10.5058 24.7834C10.5557 24.3854 11.1037 23.9785 12.1499 23.5624Z"
      fill="#212029"
    />
  </svg>
);
