import { useCallback, useState } from 'react';

export const useModal = (initialMode = false) => {
  const [isOpen, setIsOpen] = useState(initialMode);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback(() => setIsOpen(true), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return { close, open, isOpen, toggle };
};
