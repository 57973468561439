import { useGetLittersQuery } from '@/entities/litters-controller';
import { Body2, Button } from '@/shared/components';
import { useModal } from '@/shared/hooks';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { List } from '@/shared/ui';
import { useCallback, useState } from 'react';
import { PaginatedContainer } from '@/shared/containers';
import { AddLitterModal, LitterCard } from '@/features/litters';
import { AddSquare, GridViewSwitcherIcon, ListViewSwitcherIcon } from '@/app/icons';

const ADD_BUTTON_LABEL = 'Добавить помёт';
const INFO_SECTION_TEXT = 'У вас еще нет рожденных питомцев.';

type TProps = {
  nurseryId: number;
};

export const Litters = ({ nurseryId }: TProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isList, setIsList] = useState(false);
  const { isOpen, open, close } = useModal(false);

  const litterQuery = queries.equal('nursery.id', nurseryId as number);

  const {
    data: littersData,
    isFetching,
    refetch,
  } = useGetLittersQuery({
    query: litterQuery,
    size: 9,
    page: currentPage,
  });

  const getLittersCallback = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const litters = littersData?.content || [];

  return (
    <PaginatedContainer
      isFetching={isFetching}
      actualLength={litters.length}
      totalElements={littersData?.totalElements}
      getData={getLittersCallback}
    >
      <Flex vertical gap={16}>
        {!litters.length && <Body2>{INFO_SECTION_TEXT}</Body2>}
        {!litters.length && (
          <Button type="default" onClick={open}>
            {ADD_BUTTON_LABEL}
          </Button>
        )}
        {litters.length > 0 && (
          <Flex vertical={false} gap={12} justify="flex-end">
            {isList ? (
              <GridViewSwitcherIcon className="cursor-pointer" onClick={() => setIsList(false)} />
            ) : (
              <ListViewSwitcherIcon className="cursor-pointer" onClick={() => setIsList(true)} />
            )}
            <AddSquare className="cursor-pointer" onClick={open} />
          </Flex>
        )}
        <List
          grid={{ gutter: 16, column: isList ? 1 : 3 }}
          itemLayout={isList ? 'vertical' : 'horizontal'}
          dataSource={litters}
          customItem={(litter) => <LitterCard key={litter.id} litter={litter} isList={isList} />}
        />
        {isOpen && <AddLitterModal onCancel={close} onSucces={refetch} />}
      </Flex>
    </PaginatedContainer>
  );
};
