import { Warning } from '@/app/icons';
import { theme } from '@/app/theme';
import { Flex } from 'antd';
import { Caption2 } from '@/shared/components';

type TProps = {
  message: string;
};

export const ErrorMessage = ({ message }: TProps) => (
  <Flex gap={4} style={{ marginTop: '8px', color: theme.palette.primary[3] }}>
    <Flex>
      <Warning />
    </Flex>
    <Caption2>{message}</Caption2>
  </Flex>
);
