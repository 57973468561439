import { Button } from '@/shared/components';

const ADD_BUTTON_LABEL = 'Добавить питомца';

type TProps = {
  onAddButtonClick?: () => void;
};

export const EmptySection = ({ onAddButtonClick }: TProps) =>
  onAddButtonClick && (
    <Button type="default" onClick={onAddButtonClick}>
      {ADD_BUTTON_LABEL}
    </Button>
  );
