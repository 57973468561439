import { SVGProps } from 'react';

export const DeliveryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.99854 10.0195H8.6652C9.39854 10.0195 9.99854 9.41953 9.99854 8.6862V2.01953H3.99854C2.99854 2.01953 2.12521 2.57286 1.67188 3.38619"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M1.33594 12.0195C1.33594 13.1262 2.22927 14.0195 3.33594 14.0195H4.0026C4.0026 13.2862 4.6026 12.6862 5.33594 12.6862C6.06927 12.6862 6.66927 13.2862 6.66927 14.0195H9.33594C9.33594 13.2862 9.93594 12.6862 10.6693 12.6862C11.4026 12.6862 12.0026 13.2862 12.0026 14.0195H12.6693C13.7759 14.0195 14.6693 13.1262 14.6693 12.0195V10.0195H12.6693C12.3026 10.0195 12.0026 9.71953 12.0026 9.35286V7.35286C12.0026 6.9862 12.3026 6.6862 12.6693 6.6862H13.5293L12.3893 4.69287C12.1493 4.27954 11.7093 4.01953 11.2293 4.01953H10.0026V8.6862C10.0026 9.41953 9.4026 10.0195 8.66927 10.0195H8.0026"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33333 15.3522C6.06971 15.3522 6.66667 14.7553 6.66667 14.0189C6.66667 13.2825 6.06971 12.6855 5.33333 12.6855C4.59695 12.6855 4 13.2825 4 14.0189C4 14.7553 4.59695 15.3522 5.33333 15.3522Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6693 15.3522C11.4057 15.3522 12.0026 14.7553 12.0026 14.0189C12.0026 13.2825 11.4057 12.6855 10.6693 12.6855C9.93289 12.6855 9.33594 13.2825 9.33594 14.0189C9.33594 14.7553 9.93289 15.3522 10.6693 15.3522Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 8.68555V10.0189H12.6667C12.3 10.0189 12 9.71888 12 9.35221V7.35221C12 6.98555 12.3 6.68555 12.6667 6.68555H13.5267L14.6667 8.68555Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.33594 6.01953H5.33594" stroke="#8A91A9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.33594 8.01953H4.0026" stroke="#8A91A9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.33594 10.0195H2.66927" stroke="#8A91A9" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
