import { Checkbox as AntCheckbox, CheckboxProps } from 'antd';
import { Body2 } from '@/shared/ui';

import styles from './styles.module.scss';
import { Controller, FieldValues } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import cn from 'classnames';

type TProps = CheckboxProps;

export type TCheckboxValue = string | number;

export const DraftCheckbox = ({ children, ...props }: TProps) => (
  <AntCheckbox className={cn(styles['custom-checkbox'])} {...props}>
    <Body2 className="font-semibold ml-mxs">{children}</Body2>
  </AntCheckbox>
);

export const Checkbox = <R extends FieldValues>({
  controlledProps,
  ...props
}: TProps & { controlledProps: TControllerProps<R> }) => (
  <Controller render={({ field }) => <DraftCheckbox {...field} {...props} />} {...controlledProps} />
);
