import { SVGProps } from 'react';

export const PasswordEyeSlashIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m14.53 9.47004-5.05996 5.05996c-.65-.65-1.05-1.54-1.05-2.53 0-1.98 1.59996-3.57996 3.57996-3.57996.99 0 1.88.4 2.53 1.05z" />
        <path d="m17.82 5.76998c-1.75-1.32-3.75-2.04-5.82-2.04-3.53003 0-6.82003 2.08-9.11003 5.68-.9 1.41002-.9 3.78002 0 5.19002.79 1.24 1.71 2.31 2.71 3.17" />
        <path d="m8.42004 19.5301c1.14.48 2.34996.74 3.57996.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19005-.33-.52-.69-1.01-1.06-1.47" />
        <path d="m15.5099 12.7c-.26 1.41-1.41 2.56-2.82 2.82" />
        <path d="m9.47 14.53-7.47 7.47" />
        <path d="m22 2-7.47 7.47" />
      </g>
    </svg>
  );
};
