import { baseApi } from '@/app/core';
import { convertToSelectOption } from '@/shared/utils';
import { TSelectOption } from '@/shared/common';

const TRIBAL_PATH = 'tribalType';

export type TTribalTypeDto = {
  id: number;
  name: string;
};

type TResponse = TTribalTypeDto & TSelectOption;

const tribalTypeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTribalTypes: builder.query<TResponse[], void>({
      query: () => ({
        url: `${TRIBAL_PATH}`,
        method: 'GET',
      }),
      transformResponse: (response: TTribalTypeDto[]) => convertToSelectOption(response),
    }),
  }),
});

export const { useGetTribalTypesQuery } = tribalTypeApi;
