import { RefObject, useEffect, useRef } from 'react';

interface UseIntersectionReturn<T> {
  observerRef: RefObject<T>;
}

interface Options {
  onNext: () => void;
  threshold: number;
}

export const useIntersection = <T extends HTMLElement>(options?: Options): UseIntersectionReturn<T> => {
  const observerRef = useRef<T>(null);

  useEffect(() => {
    const observerRefValue = observerRef?.current || null;
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting) {
          options?.onNext();

          if (observerRefValue) {
            observer.unobserve(entry.target);
          }
        }
      },
      { threshold: options?.threshold },
    );

    if (observerRefValue) {
      observer.observe(observerRefValue);
    }

    return () => {
      if (observerRefValue) {
        observer?.unobserve(observerRefValue);
      }
    };
  }, [options, options?.threshold]);

  return { observerRef };
};
