import { Carousel, Image } from '@/shared/ui';
import cn from 'classnames';
import { Flex } from 'antd';
import { TGoodsImagesView } from './types';

const IMAGES_SHOW = 4;

export const DesktopView = ({ images, activeImage, setActiveImage }: TGoodsImagesView) => (
  <Flex gap={16}>
    <Carousel
      arrows
      arrowsPlacement="vertical"
      dotPosition="left"
      dots={false}
      className="w-[90px]"
      infinite={false}
      data={images}
      slidesToShow={IMAGES_SHOW}
      renderedSlide={(slide) => (
        <div className="flex flex-col gap-xs">
          {slide.map((image) => (
            <Image
              key={image.id}
              width={90}
              height={90}
              onClick={() => setActiveImage(image)}
              className={cn({
                'border-4 border-primary1': activeImage?.id === image.id,
              })}
              src={image.url}
              alt="animal-img"
            />
          ))}
        </div>
      )}
    />
    <Image width={activeImage ? 535 : 640} height={408} src={activeImage ? activeImage.url : '/goods-no-image.png'} />
  </Flex>
);
