import { Outlet, useLocation } from 'react-router-dom';
import { Divider, Tabs, TTabsProps } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { Button } from '@/shared/ui';
import { PlusButtonIcon } from '@/app/icons';
import { useMedia, useModal } from '@/shared/hooks';
import { AddShowroomProductModal } from '@/features/show-room';
import { Flex } from 'antd';
import styles from './styles.module.scss';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useGetAccountQuery } from '@/entities/user-controller';

export const MarketplacePage = () => {
  const { pathname } = useLocation();
  const { isMobile } = useMedia();
  const { isOpen, open, close } = useModal();
  const accountId = useTypedSelector(selectCurrentAccountId);
  const { data: account } = useGetAccountQuery(String(accountId), { skip: !accountId });
  const speciesId = account?.nursery?.species.id;

  const items: TTabsProps['items'] = [
    {
      key: MARKETPLACE_PATHS.marketplace,
      label: 'Рекомендации',
    },
    {
      key: MARKETPLACE_PATHS.catalog,
      label: 'Каталог',
    },
  ];

  const activekey =
    (pathname.includes(MARKETPLACE_PATHS.catalog) && MARKETPLACE_PATHS.catalog) ||
    (pathname === MARKETPLACE_PATHS.marketplace && MARKETPLACE_PATHS.marketplace) ||
    undefined;

  return (
    <FlexPage>
      <Flex vertical={isMobile} gap={24} justify="space-between" align="center">
        <div className="w-full">
          <Tabs items={items} activeKey={activekey} className={styles['marketplace-tabs']} />
        </div>
        {account?.nursery && (
          <Button
            size={isMobile ? 'x-small' : 'small'}
            className={isMobile ? 'w-full' : ''}
            onClick={open}
            icon={<PlusButtonIcon width={16} height={16} className="border-[1.5px] rounded border-white" />}
          >
            Подать объявление
          </Button>
        )}
      </Flex>
      <Divider />
      {speciesId && isOpen && <AddShowroomProductModal speciesId={speciesId} open={isOpen} onCancel={close} />}
      <Outlet />
    </FlexPage>
  );
};
