import { SVGProps } from 'react';

export const GridViewSwitcherIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.07692 3.84375H9.23077C9.90999 3.84375 10.4639 4.3977 10.4639 5.07692V9.23077C10.4639 9.90999 9.90999 10.4639 9.23077 10.4639H5.07692C4.3977 10.4639 3.84375 9.90999 3.84375 9.23077V5.07692C3.84375 4.3977 4.3977 3.84375 5.07692 3.84375Z"
      stroke="#1A191F"
      strokeWidth="1.6875"
    />
    <path
      d="M5.07692 13.5364H9.23077C9.90999 13.5364 10.4639 14.0903 10.4639 14.7696V18.9234C10.4639 19.6026 9.90999 20.1566 9.23077 20.1566H5.07692C4.3977 20.1566 3.84375 19.6026 3.84375 18.9234V14.7696C3.84375 14.0903 4.3977 13.5364 5.07692 13.5364Z"
      stroke="#1A191F"
      strokeWidth="1.6875"
    />
    <path
      d="M14.7683 3.84375H18.9222C19.6014 3.84375 20.1553 4.3977 20.1553 5.07692V9.23077C20.1553 9.90999 19.6014 10.4639 18.9222 10.4639H14.7683C14.0891 10.4639 13.5352 9.90999 13.5352 9.23077V5.07692C13.5352 4.3977 14.0891 3.84375 14.7683 3.84375Z"
      stroke="#1A191F"
      strokeWidth="1.6875"
    />
    <path
      d="M14.7683 13.5364H18.9222C19.6014 13.5364 20.1553 14.0903 20.1553 14.7696V18.9234C20.1553 19.6026 19.6014 20.1566 18.9222 20.1566H14.7683C14.0891 20.1566 13.5352 19.6026 13.5352 18.9234V14.7696C13.5352 14.0903 14.0891 13.5364 14.7683 13.5364Z"
      stroke="#1A191F"
      strokeWidth="1.6875"
    />
  </svg>
);
