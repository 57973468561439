import { SVGProps } from 'react';

export const CopyLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 47.5C11.0213 47.5 0.5 36.9787 0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24C47.5 36.9787 36.9787 47.5 24 47.5Z"
      fill="#FBFCFE"
      stroke="#212029"
    />
    <path
      d="M29.8563 24.8915L31.013 23.7347C33.3264 21.4213 33.4796 17.775 31.3371 15.6325C29.2016 13.497 25.5559 13.6355 23.2348 15.9566L22.0781 17.1133"
      stroke="#212029"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4911 21.7022L16.3421 22.8513C14.021 25.1724 13.8755 28.811 16.018 30.9535C18.1535 33.089 21.7992 32.9505 24.1203 30.6295L25.2693 29.4804"
      stroke="#212029"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6133 26.3574L26.7415 20.2292"
      stroke="#212029"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
