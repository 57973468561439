import { baseApi } from '@/app/core';
import { TSelectOption } from '@/shared/common';

export type TSpeciesDto = {
  id: number;
  species: string;
  speciesEn: string;
};

export enum ANIMAL_SEX {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum ANIMAL_STATUS {
  TRIBAL = 'TRIBAL',
  NEUTER = 'NEUTER',
}

export type TSexStatusDto = {
  id: number;
  name: ANIMAL_STATUS;
  label: string;
};

export type TSpeciesSexDto = {
  category: string;
  id: number;
  label: string;
  sex: ANIMAL_SEX;
  species: TSpeciesDto;
  status: TSexStatusDto;
};

export enum SPECIES {
  CATS = 1,
  DOGS = 2,
}

const SPECIES_PATH = 'species';

const speciesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpecies: builder.query<(TSpeciesDto & TSelectOption)[], void>({
      query: () => ({
        url: `${SPECIES_PATH}`,
        method: 'GET',
      }),
      transformResponse: (response: TSpeciesDto[]) =>
        response.map((item) => ({ value: item.id, label: item.species, ...item })),
    }),
    getSpeciesSex: builder.query<TSpeciesSexDto[], string>({
      query: (query) => ({
        url: `${SPECIES_PATH}/sex`,
        method: 'GET',
        params: { query },
      }),
      transformResponse: (response: TSpeciesSexDto[]) =>
        response.map((data: TSpeciesSexDto) => ({ ...data, value: data.sex })),
    }),
    getSpeciesSexStatus: builder.query<(TSexStatusDto & { value: ANIMAL_STATUS })[], void>({
      query: () => ({
        url: `${SPECIES_PATH}/sex/status`,
        method: 'GET',
      }),
      transformResponse: (response: TSexStatusDto[]) => response.map((item) => ({ ...item, value: item.name })),
    }),
  }),
});

export const { useGetSpeciesQuery, useGetSpeciesSexQuery, useGetSpeciesSexStatusQuery } = speciesApi;
