import { useIntersection } from '@/shared/hooks';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@/app/icons';
import { Flex } from 'antd';

const INITIAL_PAGE = 1;

const Observer = styled.div`
  height: 32px;
  width: 100%;
  background-color: transparent;
  color: transparent;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
`;

export type TPaginatedContainerProps = {
  isFetching: boolean;
  actualLength?: number;
  totalElements?: number;
  children: ReactNode;
  getData: (page: number) => void;
};

export const PaginatedContainer = ({
  isFetching,
  actualLength = 0,
  totalElements = 0,
  children,
  getData,
}: TPaginatedContainerProps) => {
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);

  const isMoreElements = actualLength < totalElements;

  const { observerRef } = useIntersection<HTMLDivElement>({
    threshold: 0,
    onNext: () => {
      setCurrentPage((prevState) => prevState + 1);
    },
  });

  useEffect(() => {
    getData(currentPage);
  }, [getData, currentPage]);

  return (
    <>
      {children}
      <Flex vertical gap={16} style={{ marginTop: 16 }}>
        {isFetching && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {isMoreElements && <Observer ref={!isFetching && isMoreElements ? observerRef : null}>some content</Observer>}
      </Flex>
    </>
  );
};
