import { LITTERS_PATHS } from '@/app/navigation';
import { TLitterDto } from '@/entities/litters-controller/types';
import { Body2, Headline1 } from '@/shared/components/Typography';
import { Card } from '@/shared/ui';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { convertDateToString } from '@/shared/utils';

type TProps = {
  litter: Partial<TLitterDto>;
  isList: boolean;
};

export const LitterCard = ({ litter, isList }: TProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${LITTERS_PATHS.litters}/${litter.id}`);
  };

  return (
    <Card
      className="cursor-pointer"
      onClick={handleClick}
      metaProps={{
        description: (
          <Flex vertical={!isList} justify="space-between" align={isList ? 'center' : 'start'} gap={8} className="px-2">
            <Headline1>{litter.name}</Headline1>
            {isList && litter.awaiting && <Body2>Ожидаем</Body2>}
            <Body2>
              <strong>Мать: </strong>
              {litter?.mother?.fullName || '-'}
            </Body2>
            <Body2>
              <strong>Отец: </strong>
              {litter?.father?.fullName || '-'}
            </Body2>
            {!isList && <Body2>{litter.birthDate && convertDateToString(litter.birthDate)}</Body2>}
          </Flex>
        ),
      }}
    />
  );
};
