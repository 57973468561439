import { Body2 } from '@/shared/components';
import { Flex } from 'antd';
import { LikeButton } from '@/features/buttons';
import { Message } from '@/app/icons';
import { useAuth } from '@/shared/hooks';

type TProps = {
  likeCount: number;
  likedByCurrent: boolean;
  postId: string;
  messageAction: () => void;
};

export const PostActions = ({ likeCount, likedByCurrent, postId, messageAction }: TProps) => {
  const { handleAuth } = useAuth();

  return (
    <Flex justify="space-between" align="center">
      <Flex align="center" gap={8}>
        <LikeButton postId={postId} likedByCurrent={likedByCurrent} />
        <Body2>{likeCount}</Body2>
      </Flex>
      <Message cursor="pointer" onClick={() => handleAuth(messageAction)} />
    </Flex>
  );
};
