import { NotificationPopup, Typography } from '@/shared/components';
import { CONGRATS } from '@/features/modals/Notification/constants';

type TProps = {
  onClose: () => void;
};

const TITLE = 'Питомец добавлен';
const SUCCESS_ADDED = 'Питомец успешно добавлен!';

export const AddAnimalNotificationModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Typography type="14SB">{CONGRATS}</Typography>
    <p>{SUCCESS_ADDED}</p>
  </NotificationPopup>
);
