import { Headline4, Images } from '@/shared/components';
import { theme } from '@/app/theme';
import { Flex } from 'antd';

const popularImages = [
  '/01.jpg',
  '/02.jpg',
  '/03.jpg',
  '/04.jpg',
  '/05.jpg',
  '/06.jpg',
  '/07.jpg',
  '/08.jpg',
  '/09.jpg',
];

export const Popular = () => {
  return (
    <Flex vertical gap={16}>
      <Headline4 styles={{ color: theme.palette.neutral[7] }}>Популярное</Headline4>
      <Images images={popularImages.map((item) => ({ src: item, scale: false }))} />
    </Flex>
  );
};
