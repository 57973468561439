import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TAccountDto } from '@/entities/user-controller';

import { accountNameRule } from '@/shared/validation';
import { z } from 'zod';

export const ADD_ACCOUNT_FORM_FIELDS = {
  name: 'name',
  avatar: 'avatar',
} as const;

const addAccountSchema = z.object({
  [ADD_ACCOUNT_FORM_FIELDS.name]: accountNameRule(),
  [ADD_ACCOUNT_FORM_FIELDS.avatar]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .optional(),
});

export const useAccountForm = (accountData: TAccountDto | null) => {
  return useForm<z.infer<typeof addAccountSchema>>({
    resolver: zodResolver(addAccountSchema),
    defaultValues: {
      name: accountData?.name,
      avatar: accountData?.avatar,
    },
  });
};
