import { z, ZodNumber, ZodObject, ZodOptional, ZodString } from 'zod';
import { SPECIES } from '@/entities/species-controller';
import { useGetColorsTypesQuery } from '@/entities/color-controller';
import { queries, typedObjectEntries } from '@/shared/utils';
import { selectRule } from '@/shared/validation';

export const useColorsSchema = (speciesId: SPECIES) => {
  const speciesQuery = queries.equal('species.id', speciesId);
  const { data: types = [] } = useGetColorsTypesQuery(speciesQuery);

  const fields = types.reduce((res: Record<number, number>, { id }) => {
    res[id] = id;

    return res;
  }, {});

  return z.object(
    Object.keys(fields).reduce(
      (
        res:
          | Record<string, ZodOptional<ZodObject<{ value: ZodNumber; code: ZodString; name: ZodString }>>>
          | Record<string, ZodNumber>,
        key,
      ) => {
        if (key === '1' || key === '7') {
          res[key] = selectRule();
        } else {
          res[key] = z
            .object({
              value: z.number(),
              code: z.string(),
              name: z.string(),
            })
            .optional();
        }

        return res;
      },
      {},
    ),
  );
};

export const prepareColorsPayload = (data: Record<string, number | { value: number } | unknown>): number[] => {
  return typedObjectEntries(data).reduce((arr: number[], [key, value]) => {
    if (Number(key)) {
      if ((key === '1' || key === '7') && typeof value === 'number') {
        arr.push(value);
      } else if (typeof value === 'object' && value !== null && 'value' in value) {
        const objValue = value as { value: number };
        arr.push(objValue.value);
      }
    }
    return arr;
  }, []);
};
