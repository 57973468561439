import { useMedia } from '@/shared/hooks';
import { Flex } from 'antd';
import { Button, Headline4 } from '@/shared/ui';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { HeartEmpty, ShareIcon } from '@/app/icons';
import { ShareButton } from '@/features/buttons';
import { priceConverter } from '@/shared/utils';

type TProps = {
  goodData: TGoodDtoExt;
  className?: string;
};

export const ButtonActions = ({ goodData, className }: TProps) => {
  const { currency, price, canEdit } = goodData;
  const { isDesktop } = useMedia();

  const btnSize = isDesktop ? 'x-small' : 'small';

  return (
    <Flex justify="space-between" align="center" className={className} vertical={!isDesktop} gap={8}>
      <Headline4>
        {priceConverter(price)} {currency.name}
      </Headline4>
      {!canEdit && (
        <Flex gap={8}>
          <Button size={btnSize} icon={<HeartEmpty width={16} height={16} />}>
            В избранное
          </Button>
          <ShareButton
            shareProps={{ text: 'Racy goods card', title: 'Checkout racy goods card' }}
            buttonProps={{ text: 'Поделиться', icon: <ShareIcon />, size: btnSize }}
          />
        </Flex>
      )}
    </Flex>
  );
};
