import bold from './bold';
import button from './button';
import fonts from './fonts';
import medium from './medium';
import regular from './regular';
import semiBold from './semiBold';

export const typography = {
  ...bold,
  ...button,
  ...medium,
  ...regular,
  ...semiBold,
  ...fonts,
};

export { commonFont } from './commonFont';
export type { Typography } from './types';
