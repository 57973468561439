export const EMAIL_REGEX = new RegExp(
  '^(?=.{1,40}$)' + // Maximum length 40 characters
    '[A-Za-z0-9]' + // The first character must be a letter or number
    '(?:[A-Za-z0-9]|(?:[._-][A-Za-z0-9])){2,}' + // Minimum 3 characters up to @, characters (period, hyphen, underscore) not in a row
    '@' +
    '[A-Za-z0-9]+' + // Domain part, starting with a letter or number
    '(?:\\.[A-Za-z0-9-]+)*' + // Domain part can contain hyphens and periods
    '\\.[A-Za-z]{2,}$', // Top-level domain
);

export const NAME_REGEX = /^[a-zA-Zа-яА-Я-ёЁ ]{2,30}$/;
export const ENG_REGEX = /^(?=.*[a-zA-Z]{3})[a-zA-Z-'*,."/& ]+$/;

export const PASSWORD_REGEX = new RegExp(
  '^(?=.*[0-9])' + // At least one digit
    '(?=.*[a-z])' + // At least one lowercase letter
    '(?=.*[A-Z])' + // At least one uppercase letter
    '(?=.*[`!@#$%^&*()_+\\-=№[\\]{};:\'",.<>/?\\\\|°~])' + // At least one special character
    '[a-zA-Z0-9`!@#$%^&*()_+\\-=№[\\]{};:\'",.<>/?\\\\|°~]{8,}$', // At least 8 characters
);

export const LITTER_INDIVIDUAL_NAME_REGEX = /^[a-zA-Zа-яА-Я-ёЁ\-\s]{3,56}$/;
