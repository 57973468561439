import { NotificationPopup } from '@/shared/components';

const TITLE = 'Публикация удалена';
const TEXT = 'Публикация успешно удалена.';

type TProps = {
  onClose: () => void;
};

export const PostDeletedModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    {TEXT}
  </NotificationPopup>
);
