import { Warning } from '@/app/icons';
import { Flex } from 'antd';
import { Caption2 } from '@/shared/ui';
import { ReactNode } from 'react';

type TProps = {
  message: string;
};

export const ErrorMessage = ({ message }: TProps) => (
  <Flex gap={4} className="text-primary3">
    <Flex>
      <Warning />
    </Flex>
    <Caption2>{message}</Caption2>
  </Flex>
);

export const ErrorWrapper = ({ children, message }: { message?: string; children: ReactNode }) => (
  <Flex vertical gap={8} className="w-full">
    {children}
    {message && <ErrorMessage message={message} />}
  </Flex>
);
