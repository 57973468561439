import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, TBreadcrumbProps } from '@/shared/components';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { useGetMainCategoriesQuery } from '@/entities/good-category-controller';

export const MarketplaceCatalog = () => {
  const { catalogId, goodsId } = useParams();
  const { data: goodsData } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });
  const { pathname } = useLocation();
  const { data: catalogs = [] } = useGetMainCategoriesQuery();

  const catalogsBreads: TBreadcrumbProps['items'] = catalogs.map(({ id, name }) => ({
    path: `${MARKETPLACE_PATHS.catalog}/${id}`,
    title: name,
    isHidden: Number(catalogId) !== id,
  }));

  const items: TBreadcrumbProps['items'] = [
    {
      path: MARKETPLACE_PATHS.catalog,
      title: 'Каталог',
    },
    ...catalogsBreads,
    { title: goodsData?.animal.nursery.nameEn, isHidden: !goodsId },
  ];

  return (
    <>
      {pathname !== MARKETPLACE_PATHS.catalog && <Breadcrumb items={items} />}
      <Outlet />
    </>
  );
};
