import { useGetCurrentUserQuery, useGetUserAccountsQuery } from '@/entities/user-controller';
import { useSelector } from 'react-redux';
import { selectCurrentAccountId } from '@/app/store';
import { useSwitchAccountMutation } from '@/entities/account-controller';
import { useCallback } from 'react';
import { useHistory, useTokens } from '@/shared/hooks';

export const useSwitchAccount = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: accounts = [] } = useGetUserAccountsQuery(String(currentUser?.id), { skip: !currentUser });
  const currentAccountId = useSelector(selectCurrentAccountId);
  const [switchAccount] = useSwitchAccountMutation();
  const { updateTokens } = useTokens();
  const navigate = useHistory();

  const isActiveAccount = useCallback((id: string) => currentAccountId === id, [currentAccountId]);

  const handleSwitchAccount = useCallback(
    async (accountId: string) => {
      try {
        if (isActiveAccount(accountId)) {
          return;
        }
        const { token, refreshToken } = await switchAccount(accountId).unwrap();
        updateTokens(token, refreshToken);
        navigate('/');
      } catch (error) {
        console.error(error);
      }
    },
    [navigate, isActiveAccount, switchAccount, updateTokens],
  );

  const canAdd = accounts.length < 5;

  return { handleSwitchAccount, accounts, isActiveAccount, canAdd };
};
