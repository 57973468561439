import { Button } from '@/shared/components';
import { device } from '@/app/theme/device';
import { Modal, ModalProps } from 'antd';
import styled, { css } from 'styled-components';

const StyledPopup = styled(Modal)<{ $withFooterDivider: boolean }>`
  .ant-modal-content {
    border-radius: 16px;
    padding: ${({ theme }) => theme.spacing(8)};
    margin: ${({ theme }) => theme.spacing(8)} 0;

    .ant-modal-close {
      top: 30px;
      right: 25px;
    }
  }

  .ant-modal-header {
    margin-bottom: ${({ theme }) => theme.spacing(8)};
  }

  .ant-modal-title {
    ${({ theme }) => theme.typography['20B']}
  }

  .ant-modal-body {
    p {
      ${({ theme }) => theme.typography['14R']};
    }
  }

  .ant-modal-footer {
    margin-top: ${({ theme }) => theme.spacing(8)};
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)};

    .ant-btn {
      ${({ theme }) => theme.typography.button2};
      padding: ${({ theme }) => theme.spacing(3, 4)};
    }
  }

  @media screen and ${device.tablet} {
    .ant-modal-content {
      padding: ${({ theme }) => theme.spacing(4)};

      .ant-modal-close {
        top: 16px;
        right: 8px;
      }

      .ant-modal-body {
        p {
          ${({ theme }) => theme.typography['12R']};
          line-height: 24px;
        }
      }
    }

    .ant-modal-footer {
      margin-top: ${({ theme }) => theme.spacing(6)};

      .ant-btn {
        ${({ theme }) => theme.typography.button3};
      }
    }

    .ant-modal-title {
      ${({ theme }) => theme.typography['16B']};
      line-height: 34px;
    }
  }

  @media screen and ${device.mobile} {
    .ant-modal-content {
      padding: ${({ theme }) => theme.spacing(4)};

      .ant-modal-close {
        top: 12px;
        right: 8px;
      }
    }

    .ant-modal-title {
      ${({ theme }) => theme.typography['16B']}
      line-height: 24px;
    }

    .ant-modal-body {
      p {
        line-height: 20px;
      }
    }

    .ant-modal-footer {
      margin-top: ${({ theme }) => theme.spacing(5)};
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(4)};

      .ant-btn {
        margin-inline-start: 0 !important;
        width: 100%;
        ${({ theme }) => theme.typography.button2};
      }
    }
  }

  ${({ $withFooterDivider }) =>
    $withFooterDivider &&
    css`
      .ant-modal-footer {
        padding-top: ${({ theme }) => theme.spacing(8)};
        border-top: 1px solid ${({ theme }) => theme.palette.neutral[6]};
      }
    `}
`;

const MODAL_SIZE = {
  LARGE: 1214,
  MEDIUM: 785,
  SMALL: 485,
} as const;

export type TPopupProps = ModalProps & {
  size?: keyof typeof MODAL_SIZE;
  withFooterDivider?: boolean;
};

export const Popup = ({
  centered = true,
  cancelButtonProps,
  okButtonProps,
  maskClosable = false,
  size = 'MEDIUM',
  withFooterDivider = true,
  width,
  footer,
  ...props
}: TPopupProps) => {
  return (
    <StyledPopup
      $withFooterDivider={withFooterDivider}
      destroyOnClose
      centered={centered}
      maskClosable={maskClosable}
      width={width || MODAL_SIZE[size]}
      footer={
        footer || [
          cancelButtonProps && (
            <Button key="cancel" type="default" {...cancelButtonProps}>
              {cancelButtonProps.children}
            </Button>
          ),
          okButtonProps && (
            <Button key="ok" {...okButtonProps}>
              {okButtonProps.children}
            </Button>
          ),
        ]
      }
      {...props}
    />
  );
};
