import { LINK_ACCOUNT, HOME_PATHS } from '@/app/navigation';
import { Flex } from 'antd';
import { CustomAvatar, Body2, Caption2 } from '@/shared/components';
import { theme } from '@/app/theme';
import { getTimeAgo } from '@/shared/utils';
import { useHistory } from '@/shared/hooks';

export type TAccountAvatarProps = {
  size?: string;
  accountInfo: {
    name: string;
    id: string;
    src?: string;
    canEdit: boolean;
  };
  createdAt?: string;
  round?: boolean;
  user?: string;
};

export const AccountAvatar = ({ round = true, size = '36px', user, accountInfo, createdAt }: TAccountAvatarProps) => {
  const navigate = useHistory();

  const { id, name, src, canEdit } = accountInfo;

  const onOpenAccount = () => {
    navigate(
      canEdit
        ? HOME_PATHS.posts
        : {
            pathname: `${LINK_ACCOUNT}/${id}`,
          },
    );
  };

  return (
    <Flex gap={8} align="center">
      <CustomAvatar
        round={round}
        onClick={onOpenAccount}
        style={{ cursor: 'pointer' }}
        src={src}
        size={size}
        name={name}
      />
      <Flex vertical>
        <Body2 weight="SB">{name}</Body2>
        {user && <Caption2 styles={{ color: theme.palette.neutral[4] }}>{user}</Caption2>}
      </Flex>
      {createdAt && (
        <>
          <Body2 styles={{ color: theme.palette.neutral[4] }}>•</Body2>
          <Body2 styles={{ color: theme.palette.neutral[4] }}>{getTimeAgo(createdAt)}</Body2>
        </>
      )}
    </Flex>
  );
};
