import { goodsApi } from '@/entities/goods-controller/api';
import { TGoodDto } from '@/entities/goods-controller/types';
import { queries } from '@/shared/utils';

export const updateShowRoomCache = (response: TGoodDto | string, accountId: string) =>
  goodsApi.util.updateQueryData('getGoods', { query: queries.equal('account.id', accountId) }, (draft) => {
    if (typeof response === 'string') {
      draft.content = draft.content.filter(({ id }) => id !== response);
      draft.totalElements -= 1;
    } else {
      draft.content = [response, ...draft.content];
      draft.totalElements += 1;
    }
  });

export const postMarketplaceList = (response: TGoodDto, catalogId?: string) =>
  goodsApi.util.updateQueryData(
    'getGoods',
    { query: catalogId && queries.equal('category.id', catalogId) },
    (draft) => {
      draft.content = [response, ...draft.content];
      draft.totalElements += 1;
    },
  );
