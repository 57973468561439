import { AddSquare, Loader } from '@/app/icons';
import { useSendFilesMutation } from '@/entities/file-controller';
import { TLitterDto, TLitterRequestDto, usePutLitterMutation } from '@/entities/litters-controller';
import { Upload } from '@/shared/components';
import { Flex } from 'antd';

type TProps = {
  isUploading: boolean;
  canEdit: boolean;
  litterId: number;
  images: { id: string }[];
  refetch: () => void;
  data: TLitterDto;
};

const MEDIA = 'Медиа';

export const LitterImagesHeader = ({ isUploading, canEdit, litterId, images, refetch, data }: TProps) => {
  const [sendFiles] = useSendFilesMutation();
  const [putLitter] = usePutLitterMutation();

  const upload = async (fileList: File[]) => {
    try {
      const newImages = await sendFiles(fileList).unwrap();
      const newImageIds = newImages.map((img) => img.id);
      const updatedImageIds = [...images.map((img) => img.id), ...newImageIds];

      const litterRequest: TLitterRequestDto = {
        id: litterId,
        fatherId: data?.father?.id,
        motherId: data?.mother?.id,
        nurseryId: data.nursery.id,
        description: data.description,
        name: data.name,
        matingDate: data.matingDate,
        birthDate: data.birthDate,
        awaiting: data.awaiting,
        canEdit: data.canEdit,
        images: updatedImageIds,
      };

      await putLitter(litterRequest).unwrap();

      refetch();
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <Flex justify="space-between" align="center">
      {MEDIA}
      {canEdit && (
        <Flex align="center" onClick={(event) => event.stopPropagation()} className="pt-2">
          <Upload multiple onUploadHandler={upload}>
            {isUploading ? <Loader /> : <AddSquare />}
          </Upload>
        </Flex>
      )}
    </Flex>
  );
};
