import { ColumnWrapper } from '@/shared/containers';
import { Headline4 } from '@/shared/components';
import styled from 'styled-components';
import { EmailSignIn } from '@/features/login';
import { Flex } from 'antd';
import { useMedia } from '@/shared/hooks';
import { ForgetPasswordButton, RegistrationButton } from '@/features/buttons';

const LOGIN = 'Войти';

const StyledHeadline4 = styled(Headline4)`
  color: ${({ theme }) => theme.palette.neutral[7]};
`;

export const Login = () => {
  const { isTablet } = useMedia();

  return (
    <ColumnWrapper>
      <StyledHeadline4>{LOGIN}</StyledHeadline4>
      <EmailSignIn />
      <Flex justify={isTablet ? '' : 'space-between'} vertical={isTablet}>
        <RegistrationButton />
        <ForgetPasswordButton />
      </Flex>
    </ColumnWrapper>
  );
};
