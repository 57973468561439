import { NotificationPopup, Typography } from '@/shared/components';
import { CONGRATS } from '@/features/modals/Notification/constants';

const TITLE = 'Публикация отредактирована';
const TEXT = 'Публикация успешно отредактирована.';

type TProps = {
  onClose: () => void;
};

export const PostChangedModal = ({ onClose }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Typography type="14SB">{CONGRATS}</Typography>
    <p>{TEXT}</p>
  </NotificationPopup>
);
