import { TSelectOption } from '@/shared/common';
import { baseApi } from '@/app/core';
import { convertToSelectOption } from '@/shared/utils';

export type TCityDto = {
  id: number;
  name: string;
  nameEn: string;
};

type TResponse = TCityDto & TSelectOption;

const cityApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<TResponse[], number>({
      query: (countryId) => `cities/${countryId}`,
      transformResponse: convertToSelectOption,
    }),
  }),
});

export const { useGetCitiesQuery, useLazyGetCitiesQuery } = cityApi;
