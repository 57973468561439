import { z } from 'zod';
import { nurseryEngRule, selectRule } from '@/shared/validation';
import { useForm } from 'react-hook-form';
import { TAccountDto } from '@/entities/user-controller';
import { zodResolver } from '@hookform/resolvers/zod';
import { SPECIES } from '@/entities/species-controller';

export const ADD_NURSERY_ACCOUNT_FORM_FIELDS = {
  nurseryNameEn: 'nurseryNameEn',
  speciesId: 'speciesId',
  breedId: 'breedId',
  registrationSystemId: 'registrationSystemId',
  avatar: 'avatar',
} as const;

export const addNurseryAccountSchema = z.object({
  [ADD_NURSERY_ACCOUNT_FORM_FIELDS.speciesId]: z.number(),
  [ADD_NURSERY_ACCOUNT_FORM_FIELDS.breedId]: selectRule(),
  [ADD_NURSERY_ACCOUNT_FORM_FIELDS.registrationSystemId]: selectRule(),
  [ADD_NURSERY_ACCOUNT_FORM_FIELDS.nurseryNameEn]: nurseryEngRule(),
  [ADD_NURSERY_ACCOUNT_FORM_FIELDS.avatar]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .optional(),
});

export type TNurseryAccountSchema = z.infer<typeof addNurseryAccountSchema>;

export const useNurseryAccountForm = (accountData: TAccountDto | null) => {
  return useForm<TNurseryAccountSchema>({
    defaultValues: {
      nurseryNameEn: accountData?.name,
      breedId: accountData?.nursery?.breeds[0].id,
      registrationSystemId: accountData?.nursery?.registrationSystem.id,
      avatar: accountData?.avatar,
      speciesId: accountData?.nursery?.species.id || SPECIES.CATS,
    },
    resolver: zodResolver(addNurseryAccountSchema),
  });
};
