import { Body2, Caption2, CustomAvatar } from '@/shared/components';
import { SEARCH_TYPE, TSearchResultDto, useLazySearchQuery } from '@/entities/search-controller';
import { Flex } from 'antd';
import { theme } from '@/app/theme';
import styled from 'styled-components';
import { Search } from '@/features/Search';
import { useCallback } from 'react';
import { useAbortController, useHistory } from '@/shared/hooks';
import { ACCOUNT_TABS, LINK_ACCOUNT, LINK_RACY } from '@/app/navigation';

type TOptionViewProps = {
  title: string;
  subTitle?: string;
  src?: string;
};

const MainSearchWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
  margin-bottom: 32px;

  .search-component {
    max-width: 540px;
    margin: 0 auto;
  }
`;

const OptionView = ({ title, src, subTitle }: TOptionViewProps) => (
  <Flex gap={8} align="center" style={{ width: '100%' }}>
    <CustomAvatar round src={src} size="36px" name={title} />
    <Flex vertical>
      <Body2 weight="SB">{title}</Body2>
      <Caption2 styles={{ color: theme.palette.neutral[4] }}>{subTitle}</Caption2>
    </Flex>
  </Flex>
);

const getRenderedItem = (data: TSearchResultDto) => {
  const { user, animal, nursery } = data;

  const getOptionViewProps = (): TOptionViewProps | null => {
    if (user) {
      const { name, surname, avatar } = user;
      return { title: `${name} ${surname}`, src: avatar?.url };
    }

    if (animal) {
      const { fullName, nursery, avatar } = animal;
      return { title: fullName, subTitle: nursery.nameEn, src: avatar?.url || '/avatar.svg' };
    }

    if (nursery) {
      const { nameEn, account } = nursery;
      return { title: nameEn, subTitle: account?.name, src: './avatar.svg' };
    }

    return null;
  };
  const props = getOptionViewProps();

  return props ? <OptionView {...props} /> : null;
};

export const MainSearch = () => {
  const [getItems, { isFetching }] = useLazySearchQuery();
  const { handleAbort } = useAbortController();
  const navigate = useHistory();

  const fetchData = useCallback(
    ({ search, page, filter = null }: { search: string; page: number; filter?: SEARCH_TYPE | null }) => {
      const signal = handleAbort();

      return getItems({ query: search, page, type: filter, signal }).unwrap();
    },
    [handleAbort, getItems],
  );

  const onSelectHandler = (option: TSearchResultDto) => {
    const { user, animal, nursery } = option;

    user && navigate(`${LINK_ACCOUNT}/${user.accountIds[0]}/${ACCOUNT_TABS.posts}`);
    animal && navigate(`${LINK_RACY}/${animal.id}`);
    nursery && navigate(`${LINK_ACCOUNT}/${nursery.account.id}/${ACCOUNT_TABS.nursery}`);
  };

  return (
    <MainSearchWrapper>
      <Search
        loading={isFetching}
        fetchData={fetchData}
        optionRender={(option: TSearchResultDto) => getRenderedItem(option)}
        onSelect={onSelectHandler}
        filters={[
          { label: 'Пользователи', key: SEARCH_TYPE.USER },
          { label: 'Питомник', key: SEARCH_TYPE.NURSERY },
          { label: 'Питомцы', key: SEARCH_TYPE.ANIMAL },
        ]}
      />
    </MainSearchWrapper>
  );
};
