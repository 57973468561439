import axios from 'axios';

import { setError, store } from '@/app/store';
import { clearStorage, getStorageItem, setStorageItem, StorageKeys } from '@/shared/utils/storage';

type Headers = Record<string, string>;

const AUTH_PATH = 'auth';
type Auth = {
  token: string;
  refreshToken: string;
};

const host = process.env.REACT_APP_API_HOST || window.location.origin;
const baseURL = `${host}/api/v1/`;

const refreshInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-language': 'ru',
  },
});

export function createCustomAxiosInstance(headers: Headers) {
  const instance = axios.create({
    baseURL,
    headers,
  });

  instance.interceptors.request.use(
    (config) => {
      if (typeof window !== 'undefined') {
        const token = getStorageItem(StorageKeys.ACCESS_TOKEN);

        if (token) config.headers.Authorization = token && `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== `${AUTH_PATH}/signin` && err.response) {
        // Access Token was expired
        // eslint-disable-next-line no-underscore-dangle
        if (err.response.status === 401 && !originalConfig._retry) {
          // eslint-disable-next-line no-underscore-dangle
          originalConfig._retry = true;

          try {
            const { data } = await refreshInstance.post<Auth>(`${AUTH_PATH}/refresh`, {
              refreshToken: getStorageItem(StorageKeys.REFRESH_TOKEN),
              token: getStorageItem(StorageKeys.ACCESS_TOKEN),
            });

            const { token, refreshToken } = data;
            setStorageItem([
              { key: StorageKeys.ACCESS_TOKEN, value: token },
              { key: StorageKeys.REFRESH_TOKEN, value: refreshToken },
            ]);
            originalConfig.headers.Authorization = `Bearer ${token}`;

            return instance(originalConfig);
          } catch (_error) {
            clearStorage();
            return Promise.reject(_error);
          }
        }
      }
      if (err.response.status === 400 || err.response.status === 403 || err.response.status === 404) {
        store.dispatch(setError(err.response.data));
      }
      return Promise.reject(err);
    },
  );

  return instance;
}
