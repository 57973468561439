import {
  TAnimalDto,
  TAnimaParentRequestDto,
  TPedigreeConverted,
  useGetAnimalPedigreeQuery,
  usePutAnimalMutation,
} from '@/entities/animal-controller';
import { Headline4, Image, Typography } from '@/shared/components';
import { LogoAvatar } from '@/app/icons/LogoAvatar';
import styled from 'styled-components';
import { EditIcon } from '@/app/icons/EditIcon';
import { ShareIcon } from '@/app/icons/ShareIcon';
import { useModal } from '@/shared/hooks';
import { Loader } from '@/app/icons';

type TProps = {
  animalId: string;
};

type TImageProps = {
  url?: string;
  width: number;
  height: number;
};

type TItemProps = {
  imageProps?: TImageProps;
  title?: string;
  subTitle?: string;
  chipId?: string;
  color?: string;
  onFinishEdit: () => void;
  animalData: TAnimalDto;
};

const Table = styled.div`
  max-width: 540px;
  height: 940px;
  border: 1px solid ${({ theme }) => theme.palette.neutral[3]};
`;

const Flex = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > div {
    height: 50%;
  }
`;

const GridItem = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.palette.neutral[3]};
  width: calc(540px / 3);

  > div:last-child {
    margin-left: auto;
    display: flex;
    column-gap: 16px;
  }
`;

const Color = styled(Typography)`
  text-align: end;
`;

const Avatar = ({ url, width, height }: TImageProps) => {
  return url ? <Image src={url} width={width} height={height} /> : <LogoAvatar width={width} height={height} />;
};

const Item = ({ imageProps, title, subTitle, chipId, color, onFinishEdit, animalData }: TItemProps) => {
  const [updateAnimal, { isLoading }] = usePutAnimalMutation();

  const { id, fullName, nursery } = animalData;

  const { open, isOpen, close } = useModal();
  const onFormSubmit = async (payload: TAnimaParentRequestDto) => {
    try {
      await updateAnimal({ ...payload, id }).unwrap();
      close();
      onFinishEdit();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GridItem>
      <div>
        {imageProps && <Avatar {...imageProps} />}
        {title && <Typography type="18B">{title}</Typography>}
        {subTitle && (
          <Typography styles={{ marginBottom: '24px' }} type="14SB">
            {subTitle}
          </Typography>
        )}
        {/* <Typography type="14SB">{fullName}</Typography> */}
        {/* <Typography type="14SB">{nursery.nameEn}</Typography> */}
        {chipId && <Typography type="12R">ID: {chipId}</Typography>}
        {color && <Color type="12R">{color}</Color>}
      </div>
      <div>
        <EditIcon />
        <ShareIcon />
      </div>
    </GridItem>
  );
};

export const Pedigree = ({ animalId }: TProps) => {
  const { data: pedigree, isLoading, refetch } = useGetAnimalPedigreeQuery(animalId);

  if (isLoading) {
    return <Loader />;
  }

  if (!pedigree) {
    return null;
  }

  const renderMomDad = (data: (TPedigreeConverted | null)[]) => (
    <Column>
      {data.map((item) => {
        if (!item) {
          return null;
        }

        const { parents, ...rest } = item;

        return (
          <Flex key={rest.animalData.id}>
            <Item onFinishEdit={() => refetch()} {...rest} />
            {parents && renderMomDad(parents)}
          </Flex>
        );
      })}
    </Column>
  );

  return (
    <>
      <Headline4>В разработке...</Headline4>
      <Table>{renderMomDad(pedigree)}</Table>
    </>
  );
};
