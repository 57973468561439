import { baseApi } from '@/app/core';
import { TCommonPageResponse } from '@/entities';
import { TBreedDto } from '@/entities/breed-controller';
import { SPECIES, TSpeciesDto } from '@/entities/species-controller';
import { TAccountDto } from '@/entities/user-controller';
import { TRegistrationSystemDto } from '@/entities/registration-system-controller';

export type TNurseryDto = {
  id: number;
  nameRu: string;
  nameEn: string;
  description: string;
  logo_url: string;
  species: TSpeciesDto;
  breeds: TBreedDto[];
  account: TAccountDto;
  registrationSystem: TRegistrationSystemDto;
  canEdit: boolean;
};

export type TNurseryCreateRequestDto = {
  nurseryNameEn: string;
  speciesId: SPECIES;
  breedId: number;
  registrationSystemId: number;
  breederName: string;
};

type TNurseryQuery = {
  size?: number;
  page?: number;
  query?: string;
};

type TPageNurseryDto = TCommonPageResponse & {
  content: TNurseryDto[];
};

const NURSERY_PATH = 'nursery';

export type TNurseryOption = TNurseryDto & { value: number; label: string };

const nurseryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNurseries: builder.query<TNurseryOption[], TNurseryQuery>({
      query: ({ size = 100, page, query }) => ({
        url: NURSERY_PATH,
        method: 'GET',
        params: { size, page, query },
      }),
      transformResponse: (response: TPageNurseryDto) =>
        response.content.map((option) => ({ value: option.id, label: option.nameEn, ...option })),
    }),
    postNursery: builder.mutation<TNurseryDto, TNurseryCreateRequestDto>({
      query: (data) => ({
        url: NURSERY_PATH,
        method: 'POST',
        data,
      }),
    }),
    getNursery: builder.query<TNurseryDto, number>({
      query: (nurseryId) => ({
        url: `${NURSERY_PATH}/${nurseryId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetNurseriesQuery, usePostNurseryMutation, useGetNurseryQuery } = nurseryApi;
