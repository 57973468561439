export const LINK_ACCOUNT = '/account';
export const LINK_RACY = '/racy';

// account params
export enum SEARCH_PARAMS {
  TAB = 'tab',
}

export enum NURSERY_TABS {
  PRODUCERS = 'producers',
  LITTERS = 'litters',
}
