import { NotificationPopup } from '@/shared/components';
import { useDeletePostMutation } from '@/entities/post-controller';
import { useTypedDispatch } from '@/app/store';
import { MODALS, setGlobalModal } from '@/app/store/slices/globalModals';

type TProps = {
  onClose: () => void;
  postId: string;
  accountId: string;
  onDelete: () => void;
};

export const DeleteModal = ({ onClose, postId, accountId, onDelete }: TProps) => {
  const [deletePost, { isLoading }] = useDeletePostMutation();

  const dispatch = useTypedDispatch();

  const handleDelete = async () => {
    try {
      await deletePost({ postId, accountId }).unwrap();
      onDelete();
      dispatch(setGlobalModal(MODALS.POST_DELETED));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <NotificationPopup
      open
      title="Удалить публикацию?"
      onCancel={onClose}
      cancelButtonProps={{ children: 'Отменить', onClick: onClose }}
      okButtonProps={{ children: 'Удалить', onClick: handleDelete, loading: isLoading }}
    >
      Вы уверены, что хотите удалить публикацию?
    </NotificationPopup>
  );
};
