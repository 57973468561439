import { z } from 'zod';
import {
  DATE_VALIDATION_MESSAGE,
  NAME_ANIMAL_VALIDATION_MESSAGE,
  nameRule,
  nativeEnumRule,
  selectRule,
  stringRule,
} from '@/shared/validation';
import { ANIMAL_SEX, ANIMAL_STATUS, SPECIES } from '@/entities/species-controller';

export const MICROCHIP = 'Микрочип ID';
export const STIGMA = 'Клеймо';
export const BREEDER = 'Заводчик';
export const NURSERY = 'Питомник';
export const NAME = 'Имя';
export const BIRTH_DAY = 'Дата рождения';
export const DEATH_DAY = 'Дата смерти';

export const BIRTH_COUNTRY = 'Страна рождения';
export const LIVE_COUNTRY = 'Страна проживания';
export const LOAD_PEDIGREE = 'Загрузить родословную для подтверждения введенных данных';
export const HASH_TAGS = 'Хештеги';

export const ANIMAL_FORM_FIELDS = {
  images: 'images',
  animalRegistrationSystemId: 'animalRegistrationSystemId',
  isShowChipId: 'isShowChipId',
  speciesId: 'speciesId',
  chipId: 'chipId',
  isShowStampId: 'isShowStampId',
  stampId: 'stampId',
  nurseryId: 'nurseryId',
  breederName: 'breederName',
  breedId: 'breedId',
  fullName: 'fullName',
  dateBirth: 'dateBirth',
  animalSex: 'animalSex',
  isShowDeadDate: 'isShowDeadDate',
  dateDead: 'dateDead',
  status: 'status',
  fatherName: 'fatherName',
  fatherNurseryId: 'fatherNurseryId',
  motherName: 'motherName',
  motherNurseryId: 'motherNurseryId',
  birthCountryId: 'birthCountryId',
  liveCountryId: 'liveCountryId',
  cityId: 'cityId',
  pedigreeId: 'pedigreeId',
  hashTags: 'hashTags',
} as const;

export const animalSchema = z.object({
  [ANIMAL_FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array(),
  [ANIMAL_FORM_FIELDS.speciesId]: nativeEnumRule(SPECIES),
  [ANIMAL_FORM_FIELDS.animalRegistrationSystemId]: selectRule(),
  [ANIMAL_FORM_FIELDS.isShowChipId]: z.boolean(),
  [ANIMAL_FORM_FIELDS.chipId]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.isShowStampId]: z.boolean(),
  [ANIMAL_FORM_FIELDS.stampId]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.breederName]: nameRule(),
  [ANIMAL_FORM_FIELDS.nurseryId]: selectRule(),
  [ANIMAL_FORM_FIELDS.fullName]: nameRule(NAME_ANIMAL_VALIDATION_MESSAGE),
  [ANIMAL_FORM_FIELDS.breedId]: selectRule(),
  [ANIMAL_FORM_FIELDS.dateBirth]: stringRule(DATE_VALIDATION_MESSAGE),
  [ANIMAL_FORM_FIELDS.animalSex]: nativeEnumRule(ANIMAL_SEX),
  [ANIMAL_FORM_FIELDS.isShowDeadDate]: z.boolean(),
  [ANIMAL_FORM_FIELDS.dateDead]: z.string().optional(),
  [ANIMAL_FORM_FIELDS.status]: nativeEnumRule(ANIMAL_STATUS),
  [ANIMAL_FORM_FIELDS.birthCountryId]: selectRule(),
  [ANIMAL_FORM_FIELDS.liveCountryId]: selectRule(),
  [ANIMAL_FORM_FIELDS.cityId]: selectRule(),
  [ANIMAL_FORM_FIELDS.pedigreeId]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array()
    .optional(),
  [ANIMAL_FORM_FIELDS.hashTags]: z.string().array().optional(),
});

export type TAnimalSchema = z.infer<typeof animalSchema>;

export const animalDefaultValues = {
  [ANIMAL_FORM_FIELDS.isShowChipId]: false,
  [ANIMAL_FORM_FIELDS.isShowDeadDate]: false,
  [ANIMAL_FORM_FIELDS.isShowStampId]: false,
  [ANIMAL_FORM_FIELDS.hashTags]: [],
  [ANIMAL_FORM_FIELDS.pedigreeId]: [],
  [ANIMAL_FORM_FIELDS.images]: [],
};
