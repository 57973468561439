import { baseApi } from '@/app/core';
import { convertToSelectOption } from '@/shared/utils';
import { TSelectOption } from '@/shared/common';
import { SPECIES } from '@/entities/species-controller';

export type TColorTypeDto = {
  id: number;
  name: string;
  species: SPECIES;
};

export type TColorDto = {
  id: number;
  name: string;
  code: string;
  colorType: TColorTypeDto;
};

const COLORS_PATH = 'colors';

type Response = TColorDto & TSelectOption;

const colorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getColors: builder.query<Response[], string>({
      query: (query) => ({
        url: COLORS_PATH,
        method: 'GET',
        params: { query },
      }),
      transformResponse: convertToSelectOption,
    }),
    getColorsTypes: builder.query<TColorTypeDto[], string>({
      query: (query) => ({
        url: `${COLORS_PATH}/types`,
        method: 'GET',
        params: { query },
      }),
    }),
  }),
});

export const { useGetColorsTypesQuery, useGetColorsQuery, useLazyGetColorsQuery } = colorApi;
