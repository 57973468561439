import { axiosBaseQuery } from '@/app/core';
import { createApi } from '@reduxjs/toolkit/query/react';
import { updatePost, updatePostFeed, updatePostList } from './actions';
import { TPagePostDto, TPostCreateDto, TPostQuery, TPostReadDto } from './types';

const POST_PATH = 'pawgram';

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['POST'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getPostLast: builder.query<TPagePostDto, Partial<TPostQuery>>({
      query: ({ size, page }) => ({
        url: `${POST_PATH}/getLast`,
        method: 'GET',
        params: {
          page,
          size,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: ({ content }, data) => {
        content.push(...data.content);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),
    getPostById: builder.query<TPostReadDto, string>({
      query: (postId) => ({
        url: `${POST_PATH}/${postId}`,
        method: 'GET',
      }),
    }),
    getPosts: builder.query<TPagePostDto, Partial<TPostQuery>>({
      query: (params) => ({
        url: POST_PATH,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}/${queryArgs.query}`;
      },
      merge: ({ content }, data) => {
        content.push(...data.content);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),
    postPost: builder.mutation<TPostReadDto, TPostCreateDto>({
      query: (data) => ({
        url: `${POST_PATH}`,
        method: 'POST',
        data,
      }),
      onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const {
            account: { accountId },
          } = data;
          const createPost = (draft: TPagePostDto) => {
            draft.content = [data, ...draft.content];
            draft.totalElements += 1;
          };
          dispatch(updatePostList(accountId, createPost));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    postLike: builder.mutation<void, string>({
      query: (postId) => ({
        url: `${POST_PATH}/${postId}/likes`,
        method: 'POST',
      }),
      onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
        const onLike = (draft: TPostReadDto) => {
          draft.likedByCurrent = true;
          draft.likeCount += 1;
        };
        const postPatch = dispatch(updatePost(payload, onLike));
        queryFulfilled.catch(() => postPatch.undo);
      },
    }),
    updatePost: builder.mutation<TPostReadDto, TPostCreateDto & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `${POST_PATH}/${id}`,
        method: 'PUT',
        data: body,
      }),
      onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updatePost(payload.id, () => data));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deletePost: builder.mutation<void, { postId: string; accountId: string }>({
      query: ({ postId }) => ({
        url: `${POST_PATH}/${postId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async ({ postId, accountId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const deletePost = (draft: TPagePostDto) => {
            draft.content = draft.content.filter((item) => item.id !== postId);
            draft.totalElements -= 1;
          };
          dispatch(updatePostFeed(deletePost));
          dispatch(updatePostList(accountId, deletePost));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    deleteLike: builder.mutation<void, string>({
      query: (postId) => ({
        url: `${POST_PATH}/${postId}/likes`,
        method: 'DELETE',
      }),
      onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
        const onDisLike = (draft: TPostReadDto) => {
          draft.likedByCurrent = false;
          draft.likeCount -= 1;
        };

        const postPatch = dispatch(updatePost(payload, onDisLike));
        queryFulfilled.catch(() => postPatch.undo());
      },
    }),
  }),
});

export const {
  usePostPostMutation,
  useGetPostLastQuery,
  useLazyGetPostLastQuery,
  useDeleteLikeMutation,
  useDeletePostMutation,
  usePostLikeMutation,
  useUpdatePostMutation,
  useGetPostsQuery,
  useGetPostByIdQuery,
} = postApi;
