import { Headline4 } from '@/shared/components';

import { ColumnWrapper } from '@/shared/containers';
import { DATA_NURSERY } from './constants';
import { NURSERY_FORM_FIELDS, NurseryCreate } from '@/features/nursery';
import { useMedia } from '@/shared/hooks';

export const Nursery = () => {
  const { isMobile } = useMedia();

  return (
    <ColumnWrapper>
      <Headline4>{DATA_NURSERY}</Headline4>
      <NurseryCreate width={isMobile ? '100%' : '48%'} hideFields={[NURSERY_FORM_FIELDS.breederName]} />
    </ColumnWrapper>
  );
};
