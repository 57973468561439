import { LINK_ACCOUNT, LINK_RACY } from './links';

export const ROUTE = {
  NURSERY: '/nursery',
  REGISTRATION: '/registration',
  CONFIRM_MAIL: '/confirmEmail',
  VK_AUTH: '/auth/vk',
  NOT_FOUND: '/not-found',
  OTHER: '*',
  PETS: `${LINK_RACY}/:id`,
  TG: 'https://t.me/breedshow',
  ACCOUNT: `${LINK_ACCOUNT}/:accountId`,
  LITTERS: '/litters/:id',
} as const;
