import { commonFont, notoSansFont } from './commonFont';

export default {
  '26B': {
    ...commonFont,
    fontSize: 26,
    fontWeight: 700,
    lineHeight: '38px',
    letterSpacing: '-0.5px',
  },
  '24B': {
    ...commonFont,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '36px',
  },
  '20B': {
    ...commonFont,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '36px',
  },
  '18B': {
    ...commonFont,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '34px',
  },
  '16B': {
    ...commonFont,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
  },
  noto16B: {
    ...notoSansFont,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
  },
  '13B': {
    ...commonFont,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '20px',
  },
  '12B': {
    ...commonFont,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '12px',
  },
  noto12B: {
    ...notoSansFont,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '12px',
  },
  noto10B: {
    ...notoSansFont,
    fontSize: 10,
    fontWeight: 700,
    lineHeight: '12px',
  },
};
