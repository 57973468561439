import { Flex } from 'antd';
import { Body3, Button, Caption4, Headline5, Headline6 } from '@/shared/ui';
import { useGetForeignUserQuery } from '@/entities/user-controller';
import { Loader, NurseryIcon, PhoneIcon } from '@/app/icons';
import { CustomAvatar } from '@/shared/components';
import { countryCityConverter } from '@/shared/utils';
import { EmailDog } from '@/app/icons/EmailDog';
import { TGoodDto } from '@/entities/goods-controller';

type TProps = {
  accountId: string;
  good: TGoodDto;
};

export const GoodsSeller = ({ accountId, good }: TProps) => {
  const { data } = useGetForeignUserQuery(accountId);

  if (!data) {
    return <Loader />;
  }

  const {
    country,
    city,
    animal: {
      nursery: { nameEn },
    },
    email,
  } = good;
  const { avatar, name, surname } = data;

  return (
    <Flex gap={16} vertical>
      <Headline5 className="lg:text-headline3">Продавец</Headline5>
      <Flex gap={16}>
        <CustomAvatar size="132px" src={avatar?.url} name={`${name} ${surname}`} />
        <Flex vertical>
          <Headline6 className="lg:text-headline4">{`${name} ${surname}`}</Headline6>
          <Flex gap={4} align="center">
            <NurseryIcon className="text-neutral4 w-xs h-xs lg:w-md lg:h-md" />
            <Body3 className="font-semibold lg:text-headline6">{nameEn}</Body3>
          </Flex>
          {country && city && (
            <Caption4 className="lg:text-body2">{countryCityConverter(country.name, city.name)}</Caption4>
          )}
          <Flex gap={4} align="center">
            <EmailDog className="text-neutral4 w-[16px] h-[16px]" />
            <Body3 className="font-semibold lg:text-headline6">{email}</Body3>
          </Flex>
        </Flex>
      </Flex>
      <Button icon={<PhoneIcon className="w-xs h-xs text-neutral2" />} size="x-small" className="w-full" type="default">
        Позвонить
      </Button>
    </Flex>
  );
};
