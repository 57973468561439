import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { message } from 'antd';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const typedObjectEntries = <T extends object>(object: T): Entries<T> => Object.entries(object) as Entries<T>;

export const convertToSelectOption = <R extends { id: number; name: string }>(data: R[]) =>
  data.map((item) => ({ value: item.id, label: item.name, ...item }));

export const convertDateToString = (date: string) => {
  dayjs.locale('ru');

  return dayjs(date).format('DD MMMM YYYY');
};

export const typedIncludes = <R>(field: R, arr?: R[]): boolean => arr?.includes(field) || false;

export const getTimeAgo = (createdAt: string) => {
  const date = new Date(createdAt);
  const now = new Date();

  const elapsedMilliseconds = now.getTime() - date.getTime();
  const seconds = Math.floor(elapsedMilliseconds / 1000);

  let output = '';

  switch (true) {
    case seconds < 60:
      output = `${Math.abs(seconds)} сек`;
      break;
    case seconds < 3600: {
      const minutes = Math.floor(seconds / 60);
      output = `${minutes} мин`;
      break;
    }
    case seconds < 86400: {
      const hours = Math.floor(seconds / 3600);
      output = `${hours} ч`;
      break;
    }
    case seconds < 2592000: {
      const days = Math.floor(seconds / 86400);
      output = `${days} д`;
      break;
    }
    case seconds < 31536000: {
      const months = Math.floor(seconds / 2592000);
      output = `${months} мес`;
      break;
    }
    default: {
      const years = Math.floor(seconds / 31536000);
      output = `${years} г`;
      break;
    }
  }
  return output;
};

export const copyClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    message.success({
      content: 'Ссылка успешно скопирована',
    });
  } catch (e) {
    console.error(e);
  }
};

export const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};

export const priceConverter = (value: number) => String(value).replace('.', ',');

export const cityConverter = (city: string) => `г. ${city}`;
export const countryCityConverter = (country: string, city: string) => `${country}, г. ${city}`;
