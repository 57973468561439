import { Divider as AntDivider, DividerProps } from 'antd';
import styled from 'styled-components';

type TProps = DividerProps;

const StyledDivider = styled(AntDivider)`
  background-color: ${({ theme }) => theme.palette.neutral[6]};
  margin: 0;
  min-width: unset;

  &.ant-divider-with-text-center {
    background-color: unset;

    &:before,
    &:after {
      background-color: ${({ theme }) => theme.palette.neutral[6]};
    }
  }
`;

export const Divider = ({ ...props }: TProps) => <StyledDivider {...props} />;
