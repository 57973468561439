import { InputProps, InputRef, Input as AntInput } from 'antd';
import { Controller, FieldError, FieldValues } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { ForwardedRef, forwardRef } from 'react';

type TInput = InputProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
  showErrorMsg?: boolean;
};

export type TInputProps<R extends FieldValues> = TInput & {
  controllerProps: TControllerProps<R>;
};

export const InputComponent = forwardRef(
  ({ labelProps, error, showErrorMsg = true, ...props }: TInput, ref: ForwardedRef<InputRef | null>) => (
    <LabelWrapper {...labelProps}>
      <ErrorWrapper message={showErrorMsg ? error?.message : ''}>
        <AntInput size="large" ref={ref} status={error && 'error'} {...props} />
      </ErrorWrapper>
    </LabelWrapper>
  ),
);

export const Input = <R extends FieldValues>({ controllerProps, ...props }: TInputProps<R>) => (
  <Controller
    render={({ field: { ref, ...rest }, fieldState: { error } }) => (
      <InputComponent error={error} {...rest} {...props} />
    )}
    {...controllerProps}
  />
);
