import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { useAddAccountMutation, useUpdateAccountMutation } from '@/entities/account-controller';
import { TAccountDto, userApi } from '@/entities/user-controller';
import { AccountUploadPhoto } from '@/features/account';
import { Input, Popup } from '@/shared/ui';
import { Flex } from 'antd';
import { FormProvider } from 'react-hook-form';
import { ADD_ACCOUNT_FORM_FIELDS, useAccountForm } from './hooks';

type TProps = {
  onCancel: () => void;
  accountData: TAccountDto | null;
};

export const AccountModal = ({ onCancel, accountData }: TProps) => {
  const dispatch = useTypedDispatch();
  const formMethods = useAccountForm(accountData);
  const { handleSubmit, setValue } = formMethods;

  const [addAccount, { isLoading: isAdding }] = useAddAccountMutation();
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();

  const isLoading = isAdding || isUpdating;

  const onFormSubmit = handleSubmit(async (data) => {
    const { name, avatar } = data;
    const payload = { name, avatar: avatar?.id };

    try {
      accountData
        ? await updateAccount({ id: accountData.id, data: payload }).unwrap()
        : await addAccount(payload).unwrap();

      dispatch(
        setGlobalModal(
          accountData ? MODALS.EDIT_ACCOUNT_NOTIFICATION : { modal: MODALS.ADD_ACCOUNT_NOTIFICATION, text: name },
        ),
      );
      dispatch(userApi.util.invalidateTags(['USER_ACCOUNTS']));
      onCancel();
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Popup
      open
      size="MEDIUM"
      title={accountData ? 'Редактировать аккаунт' : 'Добавить аккаунт'}
      okText={accountData ? 'Сохранить' : 'Добавить'}
      onOk={onFormSubmit}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      cancelText="Отменить"
    >
      <FormProvider {...formMethods}>
        <Flex vertical gap={14}>
          <Input
            placeholder="Введите название аккаунта"
            controllerProps={{ name: ADD_ACCOUNT_FORM_FIELDS.name }}
            labelProps={{ label: 'Название' }}
          />
          <AccountUploadPhoto />
        </Flex>
      </FormProvider>
    </Popup>
  );
};
