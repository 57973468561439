import { emit } from '@rsql/emitter';
import builder from '@rsql/builder';
import { ComparisonNode } from '@rsql/ast';

export const comparisonNode = {
  equal: (param: string, value: string | number) => builder.eq(param, value),
  partialEqual: (param: string, value: string | number) => builder.eq(param, `^*${value}*`),
};

export const queries = {
  equal: (param: string, value: string | number) => emit(comparisonNode.equal(param, value)),
  partialEqual: (param: string, value: string | number) => emit(comparisonNode.partialEqual(param, value)),
  and: (expressions: ComparisonNode[]) => emit(builder.and(...expressions)),
  or: (expressions: ComparisonNode[]) => emit(builder.or(...expressions)),
};
