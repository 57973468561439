import { Controller, FieldValues } from 'react-hook-form';
import { ErrorWrapper, Input, LabelWrapper, TInputProps } from '@/shared/ui';
import { useState } from 'react';
import { Flex } from 'antd';
import { Badge, MultiBadge } from '@/shared/components';

type TProps<R extends FieldValues> = TInputProps<R>;

export const BadgeInput = <R extends FieldValues>({ controllerProps, labelProps, ...props }: TProps<R>) => {
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Flex gap={8} vertical>
          <LabelWrapper {...labelProps}>
            <ErrorWrapper message={error?.message}>
              <Input
                onPressEnter={() => {
                  const options = value || [];
                  const isExist = options.some((item: string) => item === inputValue);
                  if (!isExist) {
                    onChange([...options, inputValue]);
                    setInputValue('');
                  }
                }}
                value={inputValue}
                status={error && 'error'}
                onChange={(e) => setInputValue(e.target.value)}
                controllerProps={{ name: '' }}
                {...props}
              />
            </ErrorWrapper>
          </LabelWrapper>
          <MultiBadge>
            {!!value.length &&
              value.map((option: string) => (
                <Badge
                  label={option}
                  key={option}
                  onClose={() => onChange(value.filter((item: string) => item !== option))}
                />
              ))}
          </MultiBadge>
        </Flex>
      )}
      {...controllerProps}
    />
  );
};
