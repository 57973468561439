import { Badge, MultiBadge, Select, TSelectProps } from '@/shared/components';
import styled from 'styled-components';
import { DefaultOptionType } from 'rc-select/lib/Select';

type TProps<ValueType, OptionType extends DefaultOptionType> = TSelectProps<ValueType, OptionType> & {
  valueIds: (number | string)[];
  onChange: (value: (number | string)[]) => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const BadgeSelect = <ValueType extends number | string, OptionType extends DefaultOptionType>({
  options = [],
  valueIds,
  onChange,
  ...props
}: TProps<ValueType, OptionType>) => {
  const handleChange = (val: ValueType) => {
    const newValue = options.find((option) => option.value === val)?.value;
    if (!newValue) {
      return;
    }

    onChange([...new Set(valueIds ? [...valueIds, newValue] : [newValue])]);
  };

  const handleRemove = (value: OptionType['value']) => onChange(valueIds.filter((item) => item !== value));

  const badgeOptions = options.filter(({ value }) => !!valueIds.find((item) => item === value));

  return (
    <Wrapper>
      <Select onChange={handleChange} options={options} {...props} />
      <MultiBadge>
        {!!badgeOptions.length &&
          badgeOptions.map((option) => (
            <Badge key={option.value} onClose={() => handleRemove(option.value)} label={option.label as string} />
          ))}
      </MultiBadge>
    </Wrapper>
  );
};
