import { SVGProps } from 'react';

export const PasswordEyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <path d="m15.58 12c0 1.98-1.6 3.58-3.58 3.58s-3.57996-1.6-3.57996-3.58 1.59996-3.57996 3.57996-3.57996 3.58 1.59996 3.58 3.57996z" />
        <path d="m12 20.27c3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19003-2.29-3.6-5.58-5.68-9.11-5.68-3.53003 0-6.82003 2.08-9.11003 5.68-.9 1.41003-.9 3.78003 0 5.19003 2.29 3.6 5.58 5.68 9.11003 5.68z" />
      </g>
    </svg>
  );
};
