import { baseApi } from '@/app/core';

type TFileInfoDto = {
  id: string;
  url: string;
  fileName: string;
};

export enum GOODS_CATEGORY {
  DOGS = 1,
  CATS,
  PRODUCTS,
  SERVICES,
  CLOTH,
  ANIMALS,
}

export type TGoodMainCategoryDto = {
  id: GOODS_CATEGORY;
  name: string;
  fileImageId: TFileInfoDto;
};

export const goodCategoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainCategories: builder.query<TGoodMainCategoryDto[], void>({
      query: () => 'goods/categories/main',
    }),
  }),
});

export const { useGetMainCategoriesQuery } = goodCategoryApi;
