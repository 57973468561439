import { Checkbox as AntCheckbox, CheckboxProps, Flex } from 'antd';
import styled from 'styled-components';
import { Label } from '@/shared/components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const StyledCheckbox = styled(AntCheckbox)`
  .ant-checkbox > .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &:after {
      inset-inline-start: 25%;
      width: 7px;
      height: 12px;
      top: 45%;
    }
  }
`;

type TProps = CheckboxProps & {
  label?: string;
  onChange: (value: boolean) => void;
};

export const Checkbox = ({ label, onChange, ...props }: TProps) => {
  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  };

  return (
    <Flex gap={8} align="center">
      <StyledCheckbox onChange={handleChange} {...props} />
      <Label $isHorizontal>{label}</Label>
    </Flex>
  );
};
