import { baseApi } from '@/app/core';
import { TSelectOption } from '@/shared/common';
import { convertToSelectOption } from '@/shared/utils';

const BREEDS_PATH = 'breeds';

export type TBreedDto = {
  id: number;
  name: string;
  nameEn: string;
};

type TResponse = TBreedDto & TSelectOption;

const breedApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBreeds: builder.query<TResponse[], { query: string }>({
      query: (params) => ({
        url: BREEDS_PATH,
        method: 'GET',
        params,
      }),
      transformResponse: convertToSelectOption,
    }),
  }),
});

export const { useGetBreedsQuery } = breedApi;
